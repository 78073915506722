import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const ExpenseAddModal = ({ props,onClose,remarks,data ='',id='',distributorId,fetchData='',selectedTab, fetchOrder}) => {
  console.log("DATA",data);
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log("DATAAUTH",auth.user.plant_master__c );
  const [loading,setLoading]=useState(false)
// const [product,setProduct]=useState()

 const [form,setForm]=useState({})
 const [form1,setForm1]=useState({})

 const [errors, setErrors] = useState({});

 const [employee,setEmployee]=useState([])

const [product,setProduct]=useState([])
const [product1,setProduct1]=useState([])

const [selectProduct,setSelectProduct]=useState(null)
const [selectProduct1,setSelectProduct1]=useState()
const [board,setBoard]=useState()
const [medium,setMedium]=useState()
const [className,setClassName]=useState()
const [selectDataFilter,setSelectDataFilter]=useState({
    board:'',class:'',medium:'',subject:'',series:''
})
const [getSubjects,setGetSubject]=useState()

const [getSeriesCategory,setgetSeriesCategory]=useState()


const [employeeLabel, setEmployeeLabel] = useState([])


const createExpense = async() => {
  try {

    const errors = validateForm(details);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      let results = await window.Platform.database.createExpense(details)
      window.NotificationUtils.showSuccess("Expense created successfully")
      onClose();
    } else {
      window.NotificationUtils.showError("Please fill all required fields.")
    }

  } catch (error) {
    
  }
}

  const getUsersFieldRole= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.getUsersForWork();
      console.log("results", results);
     setEmployee(results.data);
     setEmployeeLabel(results.data.map((data)=> data.id))




      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }
  const formatDate=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
// setDate(data)


detailUpdate("date",moment.unix(unixTimestamp).format('YYYY-MM-DD'))

console.log("UNIX",unixTimestamp);
}
  const [expenseTypes, setExpenseTypes] = useState()
  const [expenseTypesLabel, setExpenseTypesLabel] = useState()

  const getExpenseType= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.getExpenseType();
      console.log("results", results);
     setExpenseTypes(results.data);
     setExpenseTypesLabel(results.data.map((data)=> data.expenseTypeId))




      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }
  const [expensePurpose, setExpensePurpose] = useState()
  const [expensePurposeLabel, setExpensePurposeLabel] = useState()

  const getExpensePurpose= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.getExpensePurpose();
      console.log("results", results);
     setExpensePurpose(results.data);
     setExpensePurposeLabel(results.data.map((data)=> data.expensePurposeId))




      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }
const [allParty, setAllParty] = useState()
const [allPartyLabel, setAllPartyLabel] = useState()

  const getallparty= async (data) => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.getSchool(data);
      console.log("results", results);
     setAllParty(results.data);
     setAllPartyLabel(results.data.map((data)=> data.schoolId))




      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }



  useEffect(()=> {
    getUsersFieldRole()
    getExpensePurpose()
    getExpenseType()
    // getallparty()
  },[])
console.log("SELECTEDFILTER",selectProduct)

const getSubject=async(data)=>{
  let results = await window.Platform.database.getSubject();
  setGetSubject(results.data)


}


  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  console.log("FORMM",form)
  
 

 
const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const updateDataGrid = (selectedContainer) => {
    if (!selectDataFilter.board&&!selectDataFilter.medium&&!selectDataFilter.class && !selectDataFilter.subject && !selectDataFilter.series) {
      // If no container is selected, display all data
      setProduct(product1);
    } else {
      // Filter the data based on the selected container
    //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
    const filteredData = product1.filter(item => {
        const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
        const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
        const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;
        const subjectMatch = !selectDataFilter.subject || item.subjectId === selectDataFilter.subject;
        const seriesMatch = !selectDataFilter.series || item.seriesCategory === selectDataFilter.series;


  
        // Return true only if the filter conditions are met based on filter presence
        return (
          (!selectDataFilter.board || boardMatch) &&
          (!selectDataFilter.medium || mediumMatch) &&
          (!selectDataFilter.class || classMatch) && (!selectDataFilter.subject||subjectMatch)&&
        (!selectDataFilter.series || seriesMatch)

        );
      });
    setProduct(filteredData);
    }
  };

  const getColumns = () => {
    let result = [
      {
        field: "skuId",
        headerName: "SKU Id",
        sortable: false,
        width: 200,
        
        editable: false,
        renderCell: (params) => {
  
          let name = params.row?.skuId||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "nameSku",
        headerName: "SKU Name",
        width: 170,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.nameSku||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
          field: "Price",
          headerName: "Unit Price",
          width: 150,
          // height: 10,
          // headerAlign: 'center',
          sortable: false,
          // editable: true,
          renderCell: (params) => {
    
            let name =params.row.unitPrice||"N/A"
    
            return (
    
              <Tooltip title={name || "N/A"}>
    
                <Typography variant="inherit">{name}</Typography>
    
              </Tooltip>
    
            )
    
          },
        },
  
       
  
   
    ];
  
  
    return result
  }

  const uploadBill = async(val) => {
    try {
      console.log(val)
      let upload = await window.Platform.database.uploadImage(val)
      detailUpdate("billLink", upload.link)
    } catch (error) {
      console.error(error)
    }
  }
  const [hasChange, setHasChange] = useState(false);
  const [details, setDetails] = useState(true);
  const detailsRef = useRef();

  const detailUpdate = (key, value) => {
    if (!hasChange) {
      setHasChange(!hasChange);
    }
    var newDetails = { ...details };
    // if (key === "email") {
    //   newDetails.email = window.trimEmail(value);
    // } else { 
      newDetails[key] = value;
    // }
  
    if (key === 'employeeId') {
      const payload = {
        ownerId: value
       }
        getallparty(payload)
    }
    setDetails(newDetails);

    
  };

  
  
 useEffect(()=>{
  console.log("ddetails", details)
 },[details])

const renderContent1=()=>{
  return (
    <div style={{ overflowY: "auto" }}>
    <Stack direction={"row"} spacing={2} padding={1}>
    <Autocomplete
  // options={allusers}

  options={employeeLabel}
  disabled={data.status === 0} 
  // value={selectedContainer}

  // value={details.reportingManager || data.reportingManager}
  getOptionLabel={(option) => {
    let manager = employee.find(
      (member) => member.id === option
    );
    console.log("manager:",option);
    return `${manager?.name} (${manager?.role})`; 
  }}
  // getOptionValue={(option) => {
  //   console.log("valuesOption",option)
  //   return option?.id || ''}}
  style={{ width: "100%" }}
  onChange={(event, selectedOption) => {
    console.log("selectedOption", selectedOption);
    detailUpdate("employeeId", selectedOption);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Employee"
      required
      variant="outlined"
      error={errors.employeeId ? true : false}
    />
  )}
/>
  </Stack>
  <Stack direction={"row"} spacing={2} padding={1}>
  <Autocomplete
  // options={allusers}

  options={expenseTypesLabel}
  disabled={data.status === 0} 
  // value={selectedContainer}
  // value={details.expenseT || data.reportingManager}
  getOptionLabel={(option) => {
    let manager = expenseTypes.find(
      (member) => member.expenseTypeId === option
    );
    console.log("manager:",option);
    // return `${manager?.expenseTypeName} (${manager?.expenseTypeId})`; 
    return `${manager?.expenseTypeName}`; 
  }}
  // getOptionValue={(option) => {
  //   console.log("valuesOption",option)
  //   return option?.id || ''}}
  style={{ width: "100%" }}
  onChange={(event, selectedOption) =>{
    console.log("selectedOption", selectedOption);
    let manager = expenseTypes.find(
      (member) => member.expenseTypeId === selectedOption
    );
    console.log("mmanager", manager);
    detailUpdate("expenseType", manager.expenseTypeId);
    // console.log("manager:",option);   
    // return `${manager?.expensePurposeName} (${manager?.expensePurposeId})`; 
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Expense Type"
      required
      variant="outlined"
      error={errors.expenseType ? true : false}
    />
  )}
/>  </Stack>
  <Stack direction={"row"} spacing={2} padding={1}>
  <Autocomplete
  // options={allusers}

  options={expensePurposeLabel}
  disabled={data.status === 0} 
  // value={selectedContainer}
  // value={details.reportingManager || data.reportingManager}
  getOptionLabel={(option) => {
    let manager = expensePurpose.find(
      (member) => member.expensePurposeId === option
    );
    console.log("manager:",option);
    // return `${manager?.expensePurposeName} (${manager?.expensePurposeId})`; 
    return `${manager?.expensePurposeName}`; 
  }}
  // getOptionValue={(option) => {
  //   console.log("valuesOption",option)
  //   return option?.id || ''}}
  style={{ width: "100%" }}
  onChange={(event, selectedOption) => {
    console.log("selectedOption", selectedOption);
    let manager = expensePurpose.find(
      (member) => member.expensePurposeId === selectedOption
    );
    detailUpdate("expensePurpose", manager?.expensePurposeId);
    // console.log("manager:",option);
    // return `${manager?.expensePurposeName} (${manager?.expensePurposeId})`; 
  }
   
}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Expense Purpose"
      required
      variant="outlined"
      error={errors.expensePurpose ? true : false}
    />
  )}
/>   </Stack>

  <Stack direction={"row"} spacing={2} padding={1}>
    <TextField label="Amount" required value={details.amount || ""} type="text" fullWidth onChange={(e)=>detailUpdate("amount",e.target.value)} error={errors.amount ? true : false}/>
  </Stack>
  <Stack direction={"row"} spacing={2} padding={1}>
  <Autocomplete
  // options={allusers}
  required
  options={allPartyLabel}
  disabled={data.status === 0} 
  // value={selectedContainer}
  // value={details.reportingManager || data.reportingManager}
  getOptionLabel={(option) => {
    let manager = allParty.find(
      (member) => member.schoolId === option
    );
    console.log("manager:",option);
    // return `${manager?.schoolName} (${manager?.schoolId})`; 
    return `${manager?.schoolName}`; 
  }}
  // getOptionValue={(option) => {
  //   console.log("valuesOption",option)
  //   return option?.id || ''}}
  style={{ width: "100%" }}
  onChange={(event, selectedOption) => {
    console.log("selectedOption", selectedOption);
    detailUpdate("partyId", selectedOption);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Party"
      required
      variant="outlined"
      error={errors.partyId ? true : false}
    />
  )}
/>   </Stack>
  <Stack direction={"row"} spacing={2} padding={1}>
    <TextField label="Enter Remark"  type="text"fullWidth onChange={(e)=>detailUpdate("remark", e.target.value)}/>
  </Stack>
  {/* <Stack direction="row" spacing={2} padding={1}>
      <input
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        style={{ display: 'none ' }}
        id="upload-bill"
        onChange={(e) => {
          console.log("ddd",e.target.files[0]);
          uploadBill({file:e.target.files[0], filename: "image"});
        }}
      />
      <label htmlFor="upload-bill" style={{marginLeft: '0px'}}>
        <Button
          label="Choose File"
          value={form?.bill?.name || ''}
          fullWidth
          variant="outlined"
          onClick={() => document.getElementById('upload-bill').click()}
        >
          Bill Upload
        </Button>
      </label>
    </Stack> */}
    <Stack direction="row" spacing={2} padding={1}>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Choose Date" 
            disableFuture={true}
        sx={{width:"40%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=> formatDate(data)} />
            </LocalizationProvider>
      {/* <DatePicker
        label="Date"
        value={form?.date || null}
        onChange={(date) => changeHandler('date', date)}
        renderInput={(params) => <TextField {...params} fullWidth />}
      /> */}
    </Stack>
  {/* <Stack direction={"row"} spacing={2} padding={1}>
    <TextField label="Approved By" value={form?.name || ""} type="text"fullWidth onChange={(e)=>changeHandler("name",e.target.value)}/>
  </Stack> */}
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>

 <Button variant="contained" onClick={()=>onsubmit(form)} >
    Create
 </Button>
 <br/>
 </div>
      
</div>
)}

  const validateForm = (data) => {
    const errors = {};

    if (!data.employeeId?.trim()) {
      errors.employeeId = "Employee is required";
    }

    if (!data.expenseType?.trim()) {
      errors.expenseType = "Expense type is required";
    }

    if (!data.expensePurpose?.trim()) {
      errors.expensePurpose = "Expense purpose is required";
    }
    if (!data.amount?.trim()) {
      errors.amount = "Amount is required";
    }
    if (!data.partyId?.trim()) {
      errors.partyId = "Party is required";
    }

    return errors;
  };

 
  const onsubmit=async(data)=>{
    try{
      await createExpense()
   console.log("details", details)
      await fetchOrder();
  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }



  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         Add Expense
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
  
            {tabValue==0&&renderContent1()}
 

   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default ExpenseAddModal;