import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack, Tab, Tabs } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ProductModal from "../../components/modals/productModal";
import BulkProductAdd from "../../components/modals/bulkProductAdd";
import SeriesCategory from "../../components/modals/addSeriesCategory";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import SchoolDetailsUpdate from "../../components/modals/schoolDetailsUpdate";
import DistributorDetailsUpdate from "../../components/modals/distributorDetailsUpdate";

const isMobile = window.innerWidth < 900;

const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;
const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  // );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  // height: 548px;
  width: 100%;
  // border: solid 1px lightGrey;
  display: ${isMobile ? 'flex' : 'grid'};
  border-radius: 4px;


  ${isMobile &&
    `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PartyUpdateApproval() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [visitModal,setVisitModal]=useState(false);
const [editModal,setEditModal]=useState(false);
const [dataModal,setDataModal]=useState();
const [dataModal1,setDataModal1]=useState();

const [bulk,setBulk]=useState(false);
const [series,setSeries]=useState(false);
const [selectedTab, setSelectedTab] = useState(0);

const handleTabChange = async (value, keepSelected = false) => {

  if (!keepSelected) {
  }

  setSelectedTab(value);


}



  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    // fetchTest()
    fetchProduct()
  }, [selectedTab]);
  useEffect(() => {
    console.log("checking...", accessChanges);
  }, [accessChanges]);

  
  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

  const fetchProduct= async () => {
    try {
      setLoading(true);
      // let results1 = await window.Platform.database.testApi();
      let results
      if (selectedTab === 0) {
         results = await window.Platform.database.getUpdatePartyMobileSchool();
    }

    else {
        results = await window.Platform.database.getUpdatePartyMobileDistributor();
    }
      // console.log("TESTINGJSON",JSON.parse( results.data[1].newData));
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK",error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
  let result = [
    // {
    //   field: "nameSku",
    //   headerName: "ID",
    //   sortable: false,
    //   width: 150,
      
    //   editable: false,
    //   valueGetter: (params) => {
    //     return (params.row?.partyApprovalId)
    //   },
    //   renderCell: (params) => {

    //     let name = params.row?.partyApprovalId
        

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    {
      field: "unitPrice",
      headerName: "School Name",
      width: 200,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      valueGetter: (params) => {
        return (params.row?.schoolName || "N/A")
      },
      renderCell: (params) => {

        let name =params.row?.schoolName

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "mediumName",
        headerName: "Created At",
        width: 250,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.createrAt ? formatTimeStamp(params.row?.createrAt) : "N/A")
        },
        renderCell: (params) => {
  
          let name =formatTimeStamp(params.row?.createrAt)
  
          return (
  
            <Tooltip title={name || "undefined"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //   field: "boardName",
      //   headerName: "Updated At",
      //   width: 250,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   valueGetter: (params) => {
      //     return (params.row?.schoolName || "N/A")
      //   },
      //   renderCell: (params) => {
  
      //     let name =formatTimeStamp(params.row?.updatedAt)
  
      //     return (
  
      //       <Tooltip title={name || "undefined"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },

      {

        field: "Actions",

        headerName: "Actions",

        editable: false,

        hide: true,

        minWidth: 150,
        filterable: false,
        disableExport: true,

        renderCell: (params) => {


            return (

                <Tooltip title={"Click to view details"}>

                    <IconButton onClick={()=>handleEdit(params.row)}>
                    <WysiwygIcon/>
                    </IconButton>

                </Tooltip>

            )

        },

    }
 
  ];


  return result
}

const getEmployeeColumns1 = () => {
  let result = [
    // {
    //   field: "nameSku",
    //   headerName: "ID",
    //   sortable: false,
    //   width: 150,
      
    //   editable: false,
    //   renderCell: (params) => {

    //     let name = params.row?.partyApprovalId
        

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    {
      field: "unitPrice",
      headerName: "Distributor Name",
      width: 200,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      valueGetter: (params) => {
        return (params.row?.DistributorName || "N/A")
      },
      renderCell: (params) => {

        let name =params.row?.DistributorName

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "mediumName",
        headerName: "Created At",
        width: 250,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.createrAt ? formatTimeStamp(params.row?.createrAt) : "N/A")
        },
        renderCell: (params) => {
  
          let name =formatTimeStamp(params.row?.createrAt)
  
          return (
  
            <Tooltip title={name || "undefined"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //   field: "boardName",
      //   headerName: "Updated At",
      //   width: 250,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {
  
      //     let name =formatTimeStamp(params.row?.updatedAt)
  
      //     return (
  
      //       <Tooltip title={name || "undefined"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },

      {

        field: "Actions",

        headerName: "Actions",

        editable: false,

        hide: true,

        minWidth: 150,
        filterable: false,
        disableExport: true,

        renderCell: (params) => {


            return (

                <Tooltip title={"Click to view details"}>

                    <IconButton onClick={()=>handleEdit1(params.row)}>
                    <WysiwygIcon/>
                    </IconButton>

                </Tooltip>

            )

        },

    }
 
  ];


  return result
}

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-approvals",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={selectedTab===0?getEmployeeColumns():getEmployeeColumns1()}
          rowHeight={60}
          disableDensitySelector
          disableSelectionOnClick
          disableRowSelectionOnClick
          // autoPageSize

          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        </DataGridContainer>
      </Box>
    );
  };



const addVisit=()=>{
  setVisitModal(true)
}
const handleEdit=(data)=>{
setEditModal(true)
setDataModal(data)


}
const handleEdit1=(data)=>{
  setSeries(true)
  setDataModal1(data)
  
  
  }

  return (
    <>
      {isMobile && (
        <Drawer
        // setShowNotifications={props.setShowNotifications}
        // onAuthUserChanged={props.onAuthUserChanged}
        // user={props.user}
        />
      )}
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading/>}
        <HeaderContainer>
        <div>
          
            {/* <FabStyle> */}
            {/* <FabStyle variant="extended" color="primary" 
            onClick={()=> setShowWarehouseFormModal(' ')}
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add New
</FabStyle>  */}

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
          </div>
          {/* <Typography variant="h5">Visits Management</Typography> */}

         
        </HeaderContainer>
        <TableContainer>

<TabsContainer>

    <StyledTabs

        variant="fullWidth"

        value={selectedTab}

        onChange={(event, value) => handleTabChange(value)}

        indicatorColor="primary"

        textColor="primary"

    >

        <Tab label="School" />


        {<Tab label="Distributor" />}

    </StyledTabs>
{loading && <OpaqueLoading />}


    {renderContent()}

</TabsContainer>


</TableContainer>
      </StaffEditorPageContainer>
      
     {
      editModal &&
      <SchoolDetailsUpdate
      onClose={() => setEditModal(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>fetchProduct()}
      status={false}
      propData={dataModal}
   />
    }

{
      series &&
      <DistributorDetailsUpdate
      onClose={() => setSeries(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>fetchProduct()}
      status={false}
      propData={dataModal1}
   />
    }
    
    </>
  );
}
