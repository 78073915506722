import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details, Work } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const AttendanceModal = ({ props,onClose,remarks,data,id,distributorId,fetchData,status}) => {
  console.log("DATA",data);
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log("DATAAUTH",auth.user.plant_master__c );
 
  const [details, setDetails] = useState(data);
  const [startKm, setStartKm] = useState(data);

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState()
  const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [form,setForm]=useState({})
 const [errors, setErrors] = useState({});
 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState()
 const [workType,setWorkType]=useState([])
 const [vehicleType,setVehicleType]=useState([])
 const [daType,setDaType]=useState([])
 const [vehicleTypeCheck,setVehicleTypeCheck]=useState(true)
 const [workTypeCheck,setWorkTypeCheck]=useState(true)


console.log("TABVALUE",status)

  // const handleChangeTab = (event, newValue) => {
  //   console.log("newValue: " , newValue);
  //   setTabValue(newValue);
  // };
   

  console.log("FORMM",form)
 
useEffect(()=>{
// if(tabValue===1){
//   fetchProduct(plantCode,distributorId)
// }
if(status===false){
  // setTabValue(1)
  getDaType()
}
else{
  fetchWorkType()
  fetchVehicleType()
}
fetchEmployees()
console.log("currentTab", tabValue)
},[])
 
const changeHandler = (fieldName, value) => {
  if(fieldName==="startKm" ||fieldName==="taExpense"||fieldName==="daExpense"||fieldName==="endKm" ){
    console.log("FIELDNAME",fieldName)
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  }
  else{
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  }
  };

 const vehicleTypeHandler=(data)=>{
  changeHandler("vehicleType",data)
  if(data==="2 Wheeler"||data==="4 Wheeler"){
    setVehicleTypeCheck(true)
  }
  else{
    setVehicleTypeCheck(false)

  }
  console.log("vehicleType",data)
 }
 const workTypeHandler=(data)=>{
  changeHandler("workType",data)
  if(data==="Full Day Working"||data==="Half Day Working"){
    setWorkTypeCheck(true)
  }
  else{
    setWorkTypeCheck(false)

  }
  console.log("vehicleType",data)
 }

const fetchAttendanceById=async(data)=>{
  try{
    setLoading(true)
    const response = await window.Platform.database.fetchAttendanceById({ownerId:data})
  console.log("fetchAttendanceById",response)
  const checkData=response?.data?.workType
  const checkData1=response?.data?.vehicleType
  console.log("checkData",checkData)
  console.log("checkData",checkData1)
  setStartKm(response?.data?.startKm);

if(checkData||checkData1){
  if(checkData==="Full Day Working"||checkData==="Half Day Working"||checkData1==="2 Wheeler"||checkData1==="4 Wheeler"){
    setWorkTypeCheck(true)
  }
  else{
    setWorkTypeCheck(false)

  }
}
else{
  setWorkTypeCheck(true)

}
  setLoading(false)

}
  catch(err){
    console.log(err)
  }
}


const renderContent1=()=>{
  return (
    <div style={{overflowY:"auto"}}>
    <Stack direction={"row"} spacing={2} padding={2}>
    <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value.id)
                setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                if (!matchingOption ) {
                  // If there's no matching option, set the otherValue to null
                //   setDeliveryCity(null);
                setAssignedEmp();
                return;
                }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Attendance Marked For"}
                required
                variant="outlined"
                error={errors.attendanceMarkedFor ? true : false}
              />
            )}/>
  
          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl fullWidth required>
      <InputLabel id="select-label" required error={errors.workType ? true : false}>Work Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.workType}
        onChange={(e)=>workTypeHandler(e.target.value)}
        label="Select Option"
        required
        error={errors.workType ? true : false}
      >
        {workType.map((data) => (
    <MenuItem key={data.type} value={data.type}>
      {data.type}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
    <FormControl fullWidth required>
      <InputLabel id="select-label" required error={errors.vehicleType ? true : false}>Vehicle Type </InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.vehicleType}
        onChange={(e)=>vehicleTypeHandler(e.target.value)}
        label="Select Option"
        required
        error={errors.vehicleType ? true : false}
        disabled={form.workType === "Work From Home"}
      >
        {vehicleType?.map((option, optionIndex) => (
    <MenuItem key={option.vehicleTypeId} value={option.vehicleTypeName}>
      {option.vehicleTypeName}
    </MenuItem>
  ))}


      </Select>
    </FormControl>

          </Stack>
        
          <Stack direction={"row"} spacing={2} padding={2}>
     
   <TextField label="Start KMs" disabled={!vehicleTypeCheck||!workTypeCheck||!form.vehicleType||!form.workType} required value={form?.startKm || ""} type="text"fullWidth onChange={(e)=>changeHandler("startKm",e.target.value)} error={errors.startKm ? true : false}/>
   <TextField label="Remarks" value={form?.startDetails || ""} type="text"fullWidth onChange={(e)=>changeHandler("startDetails",e.target.value)}/>

          </Stack>
       
             
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding:"20px" }}>
        <Button variant="contained" onClick={()=>onsubmit(form)} >

    Start Day
 </Button>
 </div>
     
      
</div>
)}

console.log("FORMMMM",form)
  const renderContent = (params) => {
    console.log("CheckTeabRenderContent",tabValue)
    return (
      <>
       
          <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value)
                fetchAttendanceById(value?.id)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                if (!matchingOption ) {
                  // If there's no matching option, set the otherValue to null
                //   setDeliveryCity(null);
                setAssignedEmp();
                return;
                }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Attendance Marked For"}
                required
                variant="outlined"
                error={errors.attendanceMarkedFor ? true : false}
              />
            )}/>
          
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="End Kms"  disabled={!workTypeCheck||!form.ownerId} value={form?.endKm || ""} type="text" fullWidth 
           error={errors.endKm ? true : false}
           helperText={errors.endKm || ""}
           onChange={async(e) => {
            const inputValue = e.target.value;
            
          
       changeHandler("endKm", inputValue);
    }
  
          }
          
           />

<FormControl fullWidth required>
      <InputLabel id="select-label" required error={errors?.daType ? true : false}>DA Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form?.workType}
        onChange={(e)=>changeHandler("daType",e.target.value)}
        label="Select Option"
        required
        error={errors?.daType ? true : false}
      >
        {daType.map((data) => (
    <MenuItem key={data?.daTypeId} value={data?.daType}>
      {data?.daType}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
</Stack>
          
          
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Mention Town Name" value={form?.townName || ""} required type="text" fullWidth onChange={(e)=>changeHandler("townName",e.target.value)} error={errors?.townName ? true : false}/>

          <TextField label="Remarks"  value={form?.endDetails} type="text" fullWidth onChange={(e)=>changeHandler("endDetails",e.target.value)} />
          </Stack>
         

          {/* </Box> */}
        {/* </DataGridContainer> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
        <Button variant="contained" onClick={()=>onsubmit(form)} >

    End Day
 </Button>
 </div>
      </>
    );
  };

  const fetchEmployees=async(data)=>{
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
   }

   const fetchVehicleType=async(data)=>{
    const results = await window.Platform.database.getVehicleType(data);
    setVehicleType(results.data)
   }

   const getDaType=async(data)=>{
    const results = await window.Platform.database.getDaType(data);
    setDaType(results.data)
   }

  const validateForm = (data, day) => {
    const errors = {};

    if (day === "end") {

      // if (!data.ownerId?.trim()) {
      //   errors.attendanceMarkedFor = "Attendance marking is required";
      // }

      // if (!workTypeCheck || !form.ownerId) {
      //   return
      // } else {
      //   if (!data.endKm?.trim()) {
      //     errors.endKm = "End KM is required";
      //   }
      // }
  
      // if (!data.daType?.trim()) {
      //   errors.daType = "DA type is required";
      // }
  
      // if (!data.townName?.trim()) {
      //   errors.townName = "Town name type is required";
      // }

      if (data.endKm) { 
        if (Number(data.endKm?.trim()) < startKm) {
          errors.endKm = "End km should be greater than start km";
        }
      }
    } else {
      if (!data.ownerId?.trim()) {
        errors.attendanceMarkedFor = "Attendance marking is required";
      }
   
      if (!data.workType?.trim()) {
        errors.workType = "Work type is required";
      }
   
      if (!data.workType?.trim() === 'Work From Home') {
        if (!data.vehicleType?.trim()) {
          errors.vehicleType = "Vehicle type is required";
        }
      }
  
      if (
        (data.workType === "Full Day Working" ||
          data.workType === "Half Day Working") &&
          (data.vehicleType === "2 Wheeler" || data.vehicleType === "4 Wheeler")
        ) {
        if (!data.startKm?.trim()) {
          errors.startKm = "Start KM is required";
        }
      }
    }
      
    return errors;
  };
    
 
  const onsubmit=async(data)=>{
    try{
    setLoading(true)
    console.log(form)
    if(status){
      const errors = validateForm(data, "start");
      setErrors(errors);
      if(Object.keys(errors).length === 0) {
        const results = await window.Platform.database.startAttendance(data);
   console.log("RESULTSAPI: " , results)
   if(results.status===true){
     setLoading(false)
   window.NotificationUtils.showError(results.message)

return
   }
   else{
   window.NotificationUtils.showSuccess("Attendance marked successfully!")
   setLoading(false)
   onClose()
   fetchData()
   }
      } else {
        setLoading(false);
        window.NotificationUtils.showError("Please fill the form correctly.");
      }
   
    }
    else{
      const errors = validateForm(data, "end");
      setErrors(errors);

      if(Object.keys(errors).length === 0) {
        const results = await window.Platform.database.endAttendance(data);
        if(results.status === true){
          setLoading(false)
          window.NotificationUtils.showError(results.message);
          return;
        } else {
          window.NotificationUtils.showSuccess("Attendance marked successfully!");
          setLoading(false);
          onClose();
          fetchData();
        }
      } else {
        setLoading(false);
        window.NotificationUtils.showError("Please fill the form correctly.");
      }
   
    }
   

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }

      const fetchPinCode=async(details)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.fetchPinCode(details);
      console.log("pincode: " ,results.data[0].StateName)
       setForm({...form,state:results.data[0].StateName,district:results.data[0].District})
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }

      const fetchWorkType=async(details)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.getWorkType(details);
          setWorkType(results.data)
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }


  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         Attendance
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              
              // onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
             {status? <Tab label="Start Day" />:
              <Tab label="End Day" />}
           
            </StyledTabs>
            {!status&& renderContent() }
            {status&& renderContent1() }

 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default AttendanceModal;