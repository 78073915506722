import { Avatar, Button, CircularProgress, Divider, Fab, Tab, Tabs, Tooltip, Typography, IconButton } from '@mui/material';

import React, { useState, useEffect, useContext } from 'react'

import styled from "styled-components";

import MemberDetails from "./memberDetails";

import Box from '@mui/material/Box';

import {

  DataGrid,

  GridToolbarContainer,

  GridToolbarColumnsButton,

  GridToolbarFilterButton,

  GridToolbarDensitySelector,

  GridToolbarExport,

  GridDensitySelector,

} from "@mui/x-data-grid";



import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json"

import Drawer from "../../components/common/drawer/drawer";

import { TABS, ROLES, DATE_FORMATS } from '../../constants';

// import ReplaceManagerModal from "../../components/modals/replaceManagerModal";

// import BulkUploadRoleSelector from "../../components/modals/bulkUploadRoleSelector";

// import BulkUserDelete from '../../components/modals/bulkUserDelete';

// import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import moment from 'moment';

import { AuthContext } from '../../components/contextAPI/ContextAPI';

import { Add, AddAPhoto, AddTask, SaveAlt, SaveAltOutlined, SaveAs, ManageSearch, MobileFriendly, MobileOff, Edit, LocationCity, MonetizationOn } from '@mui/icons-material';

// import { LottiePlayer } from 'lottie-react';

import Lottie from 'lottie-react';

import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import { useNavigate } from 'react-router-dom';
import UserModal from '../../components/modals/userModal';
import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';
import BulkUploadTargetUser from '../../components/modals/bulkUploadTargetUser';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';



const isMobile = window.innerWidth < 900;



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;



const HeaderContainer = styled.div`

    display:flex;

    align-items:center;

    justify-content:space-between;

    margin-bottom:10px;

`



const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  // );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;



const DetailsPreviewContainer = styled.div`

    height:100%;

    width:100%;

    display:flex;

    justify-content:center;

    align-items:center;

    ${isMobile && `

    height: calc(100vh - 56px - 20px);

  `}

`



const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;



const AvatarContainer = styled.div`

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

`;



const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  // height: 548px;
  width: 100%;
  // border: solid 1px lightGrey;
  display: ${isMobile ? 'flex' : 'grid'};
  border-radius: 8px;

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;



const LoadingContainer = styled.div`

height: calc(100% - 49px);

display:flex;

justify-content:center;

align-items:center;

`



const TabsContainer = styled.div`

  ${isMobile && `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}

`



const BulkUploadButton = styled(Button)`

  margin-right:10px !important;

`

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;





const UserManagement = (props) => {



  const navigation = useNavigate()

  const [userModal, setUserModal] = useState(null)

  const [staffMembers, setStaffMembers] = useState([]);

  const [visibleEmployees, setVisibleEmployees] = useState([]);

  const [reasonModalObj, setReasonModalObj] = useState({});

  const [selectedMember, setSelectedMember] = useState(null);

  const [selectedTab, setSelectedTab] = useState(TABS.REGISTERED);

  const [showReplacementPicker, setShowReplacementPicker] = useState(false);

  const [showDeletionReasonPicker, setShowDeletionReasonPicker] = useState(false);

  const [showBulkUploadPicker, setShowBulkUploadPicker] = useState(false);

  const [showBulkUserDeleteModal, setShowBulkUserDeleteModal] = useState(false);

  const [bulkTargetUpload, setBulkTargetUpload] = useState(false);

  const [loading, setLoading] = useState(false);

  const { auth, setAuth } = useContext(AuthContext)


  const onClose = () => {
    setUserModal(null)
    handleTabChange(TABS.REGISTERED);
        // fetchEmployees(); 
    
  }

  const discardModal = () => {
    setUserModal(null);
  }

  let contextState = { ...auth }



  // const [contextState, setContextState] = [...a]

  useEffect(() => {

    // if(contextStat)
    if (auth.user) {
      console.log("aauth", props)
      // fetchEmployees();  
    }
    // window?.NotificationUtils.showSuccess("hello this is userManagement")

  }, [contextState])



  var disableScroll = false;



  const isManager = [ROLES.SERVICE_MANAGER

    // , ROLES.SALES_MANAGER

    , ROLES.SALES_MANAGER_ENGAGEMENT].includes(auth?.user.role);



  const getEmployeeName = (employeeCode) => {

    let result = "";

    let member = staffMembers.find(member => member.employeeCode === employeeCode);

    if (member) {

      result = `${member.firstName} ${member.lastName ? member.lastName : ""}`

    }

    else {

      member = visibleEmployees.find(member => member.employeeCode === employeeCode);

      if (member) {

        result = `${member.firstName} ${member.lastName ? member.lastName : ""}`

      }

    }

    return result;

  }
  // const onClickResetDevice = (data) => {

  //   window.confirmationModal(
  //     `Are you sure you want to reset this device ID?`,
  //     resetDeviceId
  //   );
  // };
  
  const resetDeviceId = async (val) => {
    try {

      setLoading(true);

      let results = await window.Platform.database.resetDeviceId(val);

      // console.log(results)

      // results.forEach((value,i)=>{



      //   value.terminationDate = moment.unix(value.terminationDate).format("MM/DD/YYYY");

      // })



window.NotificationUtils.showSuccess("Device ID has been reset")
fetchEmployees()
      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }

  const employeeColumns = () => {
    var results = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 200,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          let name = params.row.name;
          return name;
        },
        renderCell: (params) => {
          let name = params.row.name
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return (params.row.email || "N/A");
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.email || "N/A"}>
              <Typography variant="inherit">{params.row.email}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "username",
        headerName: "Username",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return (params.row.username || "N/A");
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.username || "N/A"}>
              <Typography variant="inherit">{params.row.username}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "mobile",
        headerName: "Mobile",
        minWidth: 200,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return (params.row.mobno || "N/A");
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.mobno || "N/A"}>
              <Typography variant="inherit">{params.row.mobno}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 150,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          return (params.row.role || "N/A");
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.role || "N/A"} >
              <Typography variant="inherit">{params.row.role}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "target",
        headerName: "Target",
        minWidth: 200,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          return (params.row.incentive || "N/A");
        },
        renderCell: (params) => {
          let name =params.row?.incentive?+params.row?.incentive:null
          let name1=name&&name?.toFixed(2)
          return (
            <Tooltip title={name1 || "N/A"} >
              <Typography variant="inherit">{name1||"N/A"}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "deviceId",
        headerName: "Reset Device ID",
        width: 150,
        headerAlign: "center",
        align: "center",
        sortable: false,
        filterable: false,
        disableExport: true,
        renderCell: params => {
          if (['zsm', 'se', 'asm', 'rsm'].includes(params.row.role))
            return (
              <Tooltip title="Click to Reset">
                <IconButton
                  size="large"
                  variant="extended"
                  color="primary"
                  disabled={params.row.device_id == null}
                  onClick={
                    () => {
                      resetDeviceId(params.row)
                    }
                  }
                >
                  <MobileOff fontSize="inherit" />
                </IconButton></Tooltip>
            )
          else {
            return 'Not Applicable'
          }
        }
      },
      {
        field: `${!selectedTab ? 'editUser' : 'viewUser'}`,
        headerName: `${!selectedTab ? 'Edit User' : 'View User'}`,
        width: 150,
        headerAlign: "center",
        align: "center",
        sortable: false,
        filterable: false,
        disableExport: true,
        renderCell: params => {
          return (
            <Tooltip title={!selectedTab ? "Click to edit details" : "Click to view details"}>
              <IconButton
                size="large"
                variant="extended"
                color="primary"
                onClick={() => {
                  setUserModal(params.row)
                }}
              >
                {
                  !selectedTab ? <Edit fontSize="inherit" /> : <RemoveRedEyeIcon fontSize="inherit" />
                }
              </IconButton>
            </Tooltip>
          )
        }
      }
    ];
    return results;
  }

  

  var deletedColumns = [

    {

      field: "firstName",

      headerName: "Name",

      minWidth: 200,

      valueGetter: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return name;

      },

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "email",

      headerName: "Email",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        return params.row.previousEmail || ""

      },

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.previousEmail || "undefined"}>

            <Typography variant="inherit">{params.row.previousEmail}</Typography>

          </Tooltip>

        )

      },

    },



    {

      field: "role",

      headerName: "Role",

      minWidth: 150,

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.role || "undefined"}>

            <Typography variant="inherit">{params.row.role}</Typography>

          </Tooltip>

        )

      },

    },



    {

      field: "deletionReason",

      headerName: "De-Activation reason",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        let name = params.row.deletionReason

        if (name == null || (name.length == 0)) name = "No reason provided"

        return name;

      },

      renderCell: (params) => {

        let name = params.row.deletionReason

        if (name == null || (name.length == 0)) name = "No reason provided"

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "terminationDate",

      headerName: "Date of leaving",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        // console.log(moment.unix(params.row.terminationDate).format("MM/DD/YYYY"))

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")



        if (name == 'Invalid date')

          name = 'Termination date not provided'

        return name;

      },

      renderCell: (params) => {

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")

        if (name == 'Invalid date')

          name = 'Termination date not provided'

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

      renderCell: (params) => {

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

  ];

  useEffect(() => {

    if (auth && auth.user) {
      console.log("auth", auth);
      fetchEmployees(); // Assuming fetchEmployees is a function that makes the API call
    } else {
      // Handle the case when auth is not available
      console.log("auth is not available");
    }

  }, [auth])



  const handleTabChange = async (value, keepSelected = false) => {
  
    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }

    setSelectedTab(value);

    if (value === TABS.REGISTERED) {

      // await fetchAllEmployeeBasics();

      await fetchEmployees();

      // console.log("hello")

    }


    else {
      console.log("hello1")

      await fetchEmployees(1);

    }

  }



  const addNewMember = () => {

    // if (selectedTab !== TABS.PENDING) {

    //   handleTabChange(TABS.PENDING);

    // }

    setUserModal(true);
    setSelectedMember({});

    if (isMobile) {

      scrollToPreview();

    }

  }



  const onClickBulkUpload = () => {

    try {

      if (selectedTab !== TABS.PENDING) {

        handleTabChange(TABS.PENDING);

      }

      setShowBulkUploadPicker(true);

    } catch (error) {

      console.error(error);

    }

  }



  const onClickDeleteEmployee = async (reportingManager = "") => {
    try {
      let data = { ...selectedMember };
      data = { ...data, warehouse: data.warehouse?.id }
      let { reason, terminationDate } = reasonModalObj;
      console.log(data)
      await window.Platform.database.deleteEmployee(data, { reason, terminationDate });
      if (selectedTab === TABS.PENDING) {
        let index = visibleEmployees.findIndex(
          (member) => member.id === data.id
        );
        let newMembers = [...visibleEmployees];
        newMembers.splice(index, 1);
        setVisibleEmployees(newMembers);
        setSelectedMember(null);
      }
      else {
        if (showDeletionReasonPicker) {
          setShowDeletionReasonPicker(false);
        }
        await handleTabChange(TABS.REGISTERED);
      }
      window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const setReasonModalData = (name, value) => {

    try {

      setReasonModalObj({ ...reasonModalObj, [name]: value });

    } catch (error) {

      console.error(error);

      throw error;

    }

  }

  const deletedUsers = async () => {

    try {

      setLoading(true);

      let results = await window.Platform.database.deletedUsers();

      // console.log(results)

      // results.forEach((value,i)=>{



      //   value.terminationDate = moment.unix(value.terminationDate).format("MM/DD/YYYY");

      // })



      setVisibleEmployees(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }



  const fetchPendingEmployees = async () => {

    try {

      setLoading(true);

      let results = await window.Platform.database.fetchPendingEmployees();

      setVisibleEmployees(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }



  const fetchAllEmployeeBasics = async () => {

    try {

      if (auth?.user?.role === ROLES.ADMIN) {

        return

      }

      setLoading(true);

      let results = await window.Platform.database.fetchAllEmployeeBasics();

      setStaffMembers(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }
 

  const uploadTarget = async() => {
   setBulkTargetUpload(true)
  }


  const fetchEmployees = async (data) => {

    try {

      setLoading(true);
      console.log("authhhh", contextState)
      let results = await window.Platform.database.fetchEmployees(data);

      // console.log("results", results);

      // if (auth?.user?.role === ROLES.ADMIN) {
      console.log("response", results.data);

      setStaffMembers(results.data);

      // }

      setVisibleEmployees(results.data);

      setLoading(false);
      // window.NotificationUtils.showError(results.message)
      if(results.status){
        window.NotificationUtils.showError(results.message)
      }
      else{
        // window.NotificationUtils.showSuccess(results.message)
      }

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }





  const scrollToPreview = () => {

    window.scrollTo(0, document.getElementById("detailsPreviewContainer").getBoundingClientRect().top);

  }



  const renderLoading = () => {

    return (

    <OpaqueLoading/>

    )

  }



  function CustomToolbar() {

    return (

      <GridToolbarContainer>

        <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        {/* <GridToolbarDensitySelector /> */}

        <GridToolbarExport

          csvOptions={{

            fileName: "users-report",

          }}

        />

      </GridToolbarContainer>

    );

  }








  const renderTabContent = () => {

    if (loading) {

      return renderLoading();

    }



    if (selectedTab === TABS.DELETED) {

      return (

        <DataGridContainer>

          <DataGrid

            className="payrollGrid"
            density='compact'

            checkboxSelection={true}

            rows={visibleEmployees || []}

            columns={deletedColumns || []}

            disableSelectionOnClick

            // disableSelectionOnClick

            disableRowSelectionOnClick

            onRowClick={(rowParams, event) => {

              if (event.target.tagName !== "INPUT") {

                setSelectedMember(rowParams.row);

                if (isMobile) {

                  scrollToPreview();

                }

              }

            }}
            rowHeight={60}

            // autoPageSize

            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}

            components={{

              Toolbar: CustomToolbar,

            }}

          />

        </DataGridContainer>

      );

    }

    else

      return (

        <DataGridContainer style={{ height: !visibleEmployees.length ? '200px' : 'auto' }}>

          <DataGrid

            className="payrollGrid"

            // checkboxSelection={true}
            density='compact'

            rows={visibleEmployees || []}

            columns={employeeColumns()}

            // disableSelectionOnClick

            disableSelectionOnClick

            disableRowSelectionOnClick

            // onRowClick={(rowParams, event) => {

            //   console.log("chgecj",rowParams.row)
            //  setUserModal(rowParams.row)
            // }}
            rowHeight={60}

            // autoPageSize

            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}

            components={{

              Toolbar: CustomToolbar,

            }}

          />

        </DataGridContainer>

      );

  }



  const deleteConfirmationModal = () => {

    setShowBulkUserDeleteModal(true);

  }
  console.log()

  return (

    <>

      {/* {isMobile && (

        <Drawer

          setShowNotifications={auth?.setShowNotifications}

          onAuthUserChanged={auth?.onAuthUserChanged} user={auth?.user}
          props={props}
        />

      )} */}

      <StaffEditorPageContainer>

        <HeaderContainer>
          {userModal &&
<UserModal user={auth.user} onClose={onClose} discardModal = {discardModal} data={userModal} allusers={visibleEmployees}/>}
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>

            {/* <Typography variant="h5">User Management</Typography>

            <Tooltip title='Access Management'>

              <IconButton color='primary' onClick={() => navigation('/accessmanagement')} ><ManageSearch /></IconButton>

            </Tooltip> */}

          </div>

          <div>

            {/* {!isMobile && !isManager && ![TABS.DELETED, TABS.PENDING].includes(selectedTab)  && (

              <BulkUploadButton

                onClick={deleteConfirmationModal}

                color="primary"

                variant="contained"

                disabled = {loading}

              >

                Bulk FOS Deactivate

              </BulkUploadButton>

            )} */}

            {/* {!isMobile && !isManager && (

              <BulkUploadButton

                onClick={onClickBulkUpload}

                color="primary"

                variant="contained"

                disabled = {loading}

              >

                Bulk Upload

              </BulkUploadButton>

            )} */}
 {/* <FabStyle variant="extended" color="primary" size='small' sx={{ mr: 2 }}

onClick={uploadTarget}

>

<Add sx={{ mr: 1 }} />

Upload Target

</FabStyle> */}

<Button variant="contained" color="primary" 
             onClick={uploadTarget} sx={{ mr: 1, borderRadius: '10px' }} >
  <Add sx={{ mr: 1 }}  />
  Upload Target
</Button>

<Button variant="contained" color="primary" 
             onClick={addNewMember} sx={{ borderRadius: '10px' }} >
  <Add sx={{ mr: 1 }}  />
  Add New
</Button>
            {/* {(

              // <Button

              //   onClick={addNewMember}

              //   color="primary"

              //   variant="contained"

              //   disabled = {loading}

              // >

              //   Add New

              

              <FabStyle variant="extended" color="primary" size='small'

                onClick={addNewMember}

              >

                <Add sx={{ mr: 1 }} />

                Add New

              </FabStyle>



              // </Button>



            )} */}

          </div>

        </HeaderContainer>

        <TableContainer>

          <TabsContainer>

            <StyledTabs

              variant="fullWidth"

              value={selectedTab}

              onChange={(event, value) => handleTabChange(value)}

              indicatorColor="primary"

              textColor="primary"

            >

              <Tab label="Active" />

              {/* { <Tab label="Pending" />} */}

              {<Tab label="Inactive" />}

            </StyledTabs>

            {renderTabContent()}

          </TabsContainer>

          {/* <Divider orientation={isMobile ? "horizontal" : "vertical"} />

          <DetailsPreviewContainer id="detailsPreviewContainer">

            {!selectedMember && renderEmptyPlaceHolder()}

            {selectedMember && (

              <MemberDetails
                selectedTab={selectedTab}
                isDeleted={Boolean(selectedTab === TABS.DELETED)}
                setSelectedMember={setSelectedMember}
                isInvite={Boolean(selectedTab === TABS.PENDING)}
                staffMembers={staffMembers}
                visibleEmployees={visibleEmployees}
                setStaffMembers={setStaffMembers}
                setVisibleEmployees={setVisibleEmployees}
                data={selectedMember}
                history={auth?.history}
                handleTabChange={handleTabChange}
                onClickDeleteEmployee={onClickDeleteEmployee}
                setShowReplacementPicker={setShowReplacementPicker}
                setShowDeletionReasonPicker={setShowDeletionReasonPicker}
                // getManagers={getManagers}
                getUserCreationRoles={getUserCreationRoles}
                refreshData = {fetchPendingEmployees}
                isManager={isManager}
                fetchEmployees={fetchEmployees}
              />

            )}

          </DetailsPreviewContainer> */}

        </TableContainer>

      </StaffEditorPageContainer>



      {/* {showReplacementPicker && (

        <ReplaceManagerModal

          selectedMember={selectedMember}

          staffMembers={staffMembers}

          closeModal={() => setShowReplacementPicker(false)}

          onClickDeleteEmployee={onClickDeleteEmployee}

          openDeletionReasonModal = {() => setShowDeletionReasonPicker(true)}

          />

          )} */}

      {showDeletionReasonPicker && (

        <DeletionReasonModal

          selectedMember={selectedMember}

          data={reasonModalObj}

          setData={setReasonModalData}

          staffMembers={staffMembers}

          closeModal={() => setShowDeletionReasonPicker(false)}

          onClickDeleteEmployee={onClickDeleteEmployee}

        />

      )}
{bulkTargetUpload && <BulkUploadTargetUser onClose={()=>setBulkTargetUpload(false)} fetchData={()=>fetchEmployees()} allData={visibleEmployees}/>

}
      {/*

      {showBulkUploadPicker && (\


        <BulkUploadRoleSelector

          closeModal={() => setShowBulkUploadPicker(false)}

          setVisibleEmployees={setVisibleEmployees}

          visibleEmployees={visibleEmployees}

          // getManagers={getManagers}

          getUserCreationRoles={getUserCreationRoles}

          handleTabChange={handleTabChange}

        />

      )}

      {showBulkUserDeleteModal && (

        <BulkUserDelete

          closeModal={() => setShowBulkUserDeleteModal(false)}

          // getManagers={getManagers}

          // getUserCreationRoles={getUserCreationRoles}

          staffMembers={staffMembers}

          getEmployeeName={getEmployeeName}

          setVisibleEmployees={setVisibleEmployees}

          visibleEmployees={visibleEmployees}

        />

      )} */}

    </>

  );

}



export default UserManagement