import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import { AddCircle, ChargingStationRounded, Email, ManageAccountsOutlined, ManageHistory, ManageSearch, ManageSearchTwoTone, Sell, TrackChanges, Warehouse } from "@mui/icons-material";
import { Autocomplete, Avatar, Button, Grid, Box, Paper, Stack, TextField } from "@mui/material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import PartyManagement from "../partyManagement";
const isMobile = window.innerWidth < 900;



const ShortcutCard = styled(Card)`
  width: 250px;
  height: 200px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 0px;
  &:hover {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`

const StyledCardContent = styled(CardContent)`
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardHeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`

const CardIcon = styled(ManageAccounts)`
  font-size: 112px;
  color: #21225F;
`;

const ContentContainer = styled.div`
padding: 20px;
// position: ${isMobile ? "relative" : "fixed"};
top: 64px;
left: ${isMobile ? `0px` : `240px`};
width: ${isMobile ? `100%` : `calc(100% - 24px)`};
${!isMobile && `max-height: calc(100% - 64px);`}
overflow-y: auto;
display:flex;
flex-direction: ${isMobile ? 'column' : 'row'};
gap:20px;
${isMobile && `align-items:center;`}
flex-wrap:wrap;
`

const DurationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  gap: 5px;

  & > button {
    font-size: 12px;
    height: 30px;
    margin-bottom: 3px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background-color: ${(props) => (props.isselected ? 'black' : 'white')};
    color: ${(props) => (props.isselected ? 'white' : 'rgb(70, 70, 255)')};
    padding: 2px 7px;
    border-radius: 3px;
    border: 1px solid rgb(70, 70, 255);
    cursor: pointer;
  }
`;


const Dashboard = () => {

const navigate = useNavigate();
  
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalOrder, setTotalOrder] = useState(0)
  const [totalOrderAmount, setTotalOrderAmount] = useState(0)
  const [totalPartyOnboarded, setTotalPartyOnboarded] = useState(0)
  const [dashboardData, setDashboardData] = useState({})
  const [loading, setLoading] = useState(false)
  const [originalDashboardData, setOriginalDashboardData] = useState({});

  const cardData = [
    {
      title: 'Role Management',
      icon: ManageSearch,
      link: '/rolemanagement',
      role: ['admin', 'Super Admin'],
    },
    {
      title: 'User Management',
      icon: ManageAccounts,
      link: '/userManagement',
      role: ['admin', 'Super Admin'],
    },
    // {
    //   title: 'Tracking',
    //   icon: TrackChanges,
    //   link: '/tracking',
    //   role: ['admin'],
    // },
    // {
    //   title: 'Quotation Generation',
    //   icon: ManageSearchTwoTone,
    //   link: '/quotationgeneration',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
    // {
    //   title: 'SO Generation',
    //   icon: ChargingStationRounded,
    //   link: '/sogeneration',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
    // {
    //   title: 'Billing',
    //   icon: Sell,
    //   link: '/billing',
    //   role: ['user', 'admin'],
    // },
    // {
    //   title: 'WH Management',
    //   icon: Warehouse,
    //   link: '/warehousemanagement',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
  ];

  const MyCard = styled(Card)({
    marginBottom: 15,
    height: "80px"
  });

  const MyCardContent = styled(CardContent)({
    // Add your styles for CardContent if needed
    display: 'flex',
    gap: 80
  });

  const AvatarContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    // marginTop: 'px', // Adjust as needed
  });

  const NumberContainer = styled('div')({
    height: '25px',
    marginRight: '8px', // Adjust as needed
    textAlign: 'right', // Align to the right

  });

  const NumberTypography = styled(Typography)({
    height: '25px',
    marginRight: '8px', // Adjust as needed
    textAlign: 'right', // Align to the right

  });

  useEffect(()=>{
    handleFindClick()
  },[])

  const handleFindClick = async () => {
    setLoading(true)
    // if (startDate > endDate) {
    //   alert("From date must be smaller than To date.");
    //   setLoading(false)
    //   return;
    // }
    try {
      // let data = {
      //   fromDate : startDate,
      //   toDate: endDate
      // }
      // let results = await window.Platform.database.fetchDataForDashbord(data);
      // if(results?.status == false){
      //   setDashboardData(results.data)
      //   if (!startDate && !endDate) {
      //     setOriginalDashboardData(results.data);
      //     setLoading(false)
      //   }
      //   setLoading(false)
      // }
      let data = {
        fromDate : startDate,
        toDate: endDate
      }
      let results = await window.Platform.database.fetchDataForDashbord(data);
        setDashboardData(results.data)
          setOriginalDashboardData(results.data);
          setLoading(false)
          if(results.status){
            window.NotificationUtils.showError(results.message)
          }
          else{
            // window.NotificationUtils.showSuccess(results.message)
          }
    }
     catch (error) {
      console.error(error);
      window.NotificationUtils.showError("No Data Found");
      setLoading(false)
    }
  };

 console.log(dashboardData, "data aa gya hai ");
 const handleClearFilters = () => {
  setStartDate('');
  setEndDate('');
  setDashboardData(originalDashboardData); 
};

const goToSchool_DataPage = async () => {
  let selectedTabProp = 0
  navigate('/partyManagement',{ state: { selectedTabProp } })
}

const goToDistributor_DataPage = async () => {
  let selectedTabProp = 1
  navigate('/partyManagement',{ state: { selectedTabProp } })
}

const goToStockist_DataPage = async () => {
  navigate('/stockistManagement')
}


return (
    <>
  {loading && <OpaqueLoading/>}
  <Box sx={{ mt: 5, p:3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* From Date */}
          <Grid item xs={6} sm={3}>
            <TextField
              label="From Date"
              type="date"
              fullWidth
              value={startDate}
              onChange={(event) => {setStartDate(event.target.value);}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              label="To Date"
              type="date"
              fullWidth
              value={endDate}
              onChange={(event) => {setEndDate(event.target.value);}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={()=> {handleFindClick()}} disabled = {!startDate && !endDate}>
              Find
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClearFilters()}>
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Box>

    <Grid container spacing={2} style={{ marginTop: 15, padding:3 }}>
      <Grid item xs={3} width={"100%"}>
              <MyCard >
                <MyCardContent style={{gap: "30%"}}>
                  <AvatarContainer>
                      <img src="https://cdn-icons-png.flaticon.com/512/12465/12465866.png" height={"50px"} alt="icon" />
                  </AvatarContainer>


                  <NumberContainer>
                    <NumberTypography variant="h6" >{(Number(dashboardData?.distributor_data) +  Number(dashboardData?.school_data) + Number(dashboardData?.stockist_data)) || 0}</NumberTypography>
                    <Typography variant="body2">Total Party Onboarded</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
            </Grid>
            <Grid item xs={3}>
              <MyCard>
                <MyCardContent style={{gap: '57%'}}>
                  <AvatarContainer>
                      <img src='https://cdn-icons-png.flaticon.com/512/10608/10608863.png' height={"50px"} alt="icon" />
                  </AvatarContainer>

                  <NumberContainer>
                    <NumberTypography variant="h6" >{dashboardData?.Order_count || 0}</NumberTypography>
                    <Typography variant="body2">Total Order</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
             
            </Grid>
            <Grid item xs={3}>
              <MyCard>
                <MyCardContent style={{gap: "40%"}}>
                  <AvatarContainer>
                      {/* <img src="https://upload.wikimedia.org/wikipedia/en/thumb/9/95/Icon_Blue.svg/2048px-Icon_Blue.svg.png" height={"40px"} alt="icon" /> */}
                      <img src="https://cdn-icons-png.flaticon.com/512/2489/2489650.png" height={"40px"} alt="icon" />
                  </AvatarContainer>

                  <NumberContainer>
                    <NumberTypography variant="h6" >{dashboardData?.Total_order_amount || 0}</NumberTypography>
                    <Typography variant="body2">Total Order Amount</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
              
            </Grid>

    </Grid>

      {/* <Grid border={'2px'}>
      <AvatarContainer>
<h3>
  Total Party Data
  </h3>              
    </AvatarContainer>
        <Grid container spacing={2} style={{ marginTop: 15, padding: 3 }}>
          <Grid item xs={3} width={"100%"}>
            <MyCard >
              <MyCardContent style={{ gap: "45%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.freepik.com/512/2262/2262106.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6" >{(Number(dashboardData?.school_data)) || 0}</NumberTypography>
                  <Typography variant="body2">School Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 15, padding: 3 }}>
          <Grid item xs={3} width={"100%"}>
            <MyCard >
              <MyCardContent style={{ gap: "36%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.flaticon.com/512/5001/5001650.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6" >{(Number(dashboardData?.distributor_data)) || 0}</NumberTypography>
                  <Typography variant="body2">Distributor Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 15, padding: 3 }}>
          <Grid item xs={3} width={"100%"}>
            <MyCard >
              <MyCardContent style={{ gap: "40%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.freepik.com/512/7988/7988608.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6" >{(Number(dashboardData?.stockist_data)) || 0}</NumberTypography>
                  <Typography variant="body2">Stockist Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
          </Grid>
        </Grid>
      </Grid> */}
      
      <Grid container spacing={0} style={{  padding: '20px', borderRadius: '8px' }}>
        <Grid item xs={3}>
          <h3 style={{fontWeight:"500"}}>Party Data</h3>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 0 }}>
          <Grid item xs={3}>
            <MyCard onClick={()=>{goToSchool_DataPage()}} style={{cursor:"pointer"}}>
              <MyCardContent style={{ gap: "45%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.freepik.com/512/2262/2262106.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6">{(Number(dashboardData?.school_data)) || 0}</NumberTypography>
                  <Typography variant="body2">School Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
            <MyCard onClick={()=>{goToDistributor_DataPage()}} style={{cursor:"pointer"}}>
              <MyCardContent style={{ gap: "36%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.flaticon.com/512/5001/5001650.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6">{(Number(dashboardData?.distributor_data)) || 0}</NumberTypography>
                  <Typography variant="body2">Distributor Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
            <MyCard onClick={()=>{goToStockist_DataPage()}} style={{cursor:"pointer"}}>
              <MyCardContent style={{ gap: "40%" }}>
                <AvatarContainer>
                  <img src="https://cdn-icons-png.freepik.com/512/7988/7988608.png" height={"50px"} alt="icon" />
                </AvatarContainer>
                <NumberContainer>
                  <NumberTypography variant="h6">{(Number(dashboardData?.stockist_data)) || 0}</NumberTypography>
                  <Typography variant="body2">Stockist Data</Typography>
                </NumberContainer>
              </MyCardContent>
            </MyCard>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};

export default Dashboard;