import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Typography, Button, Stack, IconButton, FormControl, Autocomplete, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
    position: relative;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

export default function ReturnReasonReport() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);

  const [selectedTab, setSelectedTab] = useState('Series');
  const [filter, setFilter] = useState(false);
  const [filters, setFilters] = useState(['Series', 'State', 'Reason']);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange1, setDateRange1] = useState(false);

  const [startDate1, setStartDate1] = useState();
  const [endDate1, setEndDate1] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    setSelectedTab('Series');
  }, []);

  useEffect(() => {
    if (selectedTab) {
      fetchOrderReport();
    } else {
      setAccessChanges([]);
    }
  }, [selectedTab])

  const finalDateRangeFilter = async (data) => {
    try {
      let endpoint;
      switch (selectedTab) {
        case 'Series':
          endpoint = "fetchReturnReasonBySeries";
          break;
        case 'State':
          endpoint = "fetchReturnReasonByState";
          break;
        case 'Reason':
          endpoint = "fetchReturnByReturnReason";
          break;
        default:
          endpoint = "fetchReturnReasonBySeries";
      }

      setLoading(true);
      setFilter(true);

      const datePickerResponse = new Date(data);

      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
      const day = String(datePickerResponse.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate);
        setEndDate1(formattedDate);
      }

      const response = await window.Platform.database.getOrderReport(
        endpoint,
        {
          startDate: startDate,
          endDate: data ? formattedDate : endDate,
        }
      );
      const jsonArrayWithId = response?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );

      fetchOrderReport();
    }
  };

  const formatDate = async (data) => {
    setMinDate(data);
    setDateRange1(true);
    setEndDate1(null);
    setEndDate(null);
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
    const day = String(datePickerResponse.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate);
    setStartDate(formattedDate);
  };

  const clearDateFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setFilter(false);
    setSelectedTab('Series');
    fetchOrderReport();
  };

  const handleTabChange = async (value) => {
    setSelectedTab(value);
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setFilter(false);
  };

  const fetchReturnOrderLineItemByReturnReasonId = async (id, returnReason) => {
    // console.log("ffff", id, returnReason)
    navigation('/returnOrderLineItemReport', { state: { id, returnReason } });
  }

  const downloadCSV = () => {
    const csvRows = [];
    let headers = [];
    let reportName;

    switch (selectedTab) {
      case 0:
        headers = [
          "Series Name",
          "Total Sold",
          "Total Returned",
          "Total Returned Amount",
          "Return Rate",
          "Top Return Reasons",
        ];
        break;
      case 1:
        headers = [
          "State Name",
          "Total Sold",
          "Total Returned",
          "Total Returned Amount",
          "Return Rate",
          "Top Return Reasons",
        ];
        break;
      case 2:
        headers = ["Reason", "Return Count", "Percentage"];
        break;
      default:
        headers = [
          "Series Name",
          "Total Sold",
          "Total Returned",
          "Total Returned Amount",
          "Return Rate",
          "Top Return Reasons",
        ];
    }

    csvRows.push(headers.join(","));

    switch (selectedTab) {
      case 0:
        accessChanges.forEach((item) => {
          const row = [
            item.seriesName || "",
            item.total_sold || "",
            item.total_returned || "",
            item.total_return_amount || "",
            item.return_rate || "",
            item.top_return_reasons || "",
          ];
          csvRows.push(row.join(","));
        });
        reportName = "export-returns-series-wise";
        break;
      case 1:
        accessChanges.forEach((item) => {
          const row = [
            item.state || "",
            item.total_sold || "",
            item.total_returned || "",
            item.total_return_amount || "",
            item.return_rate || "",
            item.top_return_reasons || "",
          ];
          csvRows.push(row.join(","));
        });
        reportName = "export-returns-state-wise";
        break;
      case 2:
        accessChanges.forEach((item) => {
          const row = [
            item.reason || "",
            item.return_count || "",
            item.percentage || "",
          ];
          csvRows.push(row.join(","));
        });
        reportName = "export-returns-reason-wise";
        break;
      default:
        accessChanges.forEach((item) => {
          const row = [
            item.seriesName || "",
            item.total_sold || "",
            item.total_returned || "",
            item.total_return_amount || "",
            item.return_rate || "",
            item.top_return_reasons || "",
          ];
          csvRows.push(row.join(","));
        });
        reportName = "export-returns-series-wise";
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${reportName}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const fetchOrderReport = async () => {
    try {
      setLoading(true);
      let results;

      switch (selectedTab) {
        case 'Series':
          results = await window.Platform.database.getOrderReport(
            "fetchReturnReasonBySeries"
          );
          break;
        case 'State':
          results = await window.Platform.database.getOrderReport(
            "fetchReturnReasonByState"
          );
          break;
        case 'Reason':
          results = await window.Platform.database.getOrderReport(
            "fetchReturnByReturnReason"
          );
          break;
        default:
          results = await window.Platform.database.getOrderReport(
            "fetchReturnReasonBySeries"
          );
      }

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const getColumns = () => {
    switch (selectedTab) {
      case 'Series':
        return getSeriesColumns();
      case 'State':
        return getStateColumns();
      case 'Reason':
        return getReturnReasonColumns();
      default:
        return getSeriesColumns();
    }
  }
// console.log(selectedTab,"tab ")
  const getSeriesColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "seriesName",
        headerName: "Series Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.seriesName || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.seriesName || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalSold",
        headerName: "Total Sold",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.total_sold || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.total_sold || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalReturned",
        headerName: "Total Returned",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.total_returned || "N/A");
        },
        renderCell: (params) => {
          let name = +params.row?.total_returned || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "totalReturnedAmount",
      //   headerName: "Total Returned Amount",
      //   sortable: false,
      //   width: 250,
      //   editable: false,
      //   filterOperators: stringOperators,
      //   valueGetter: (params) => {
      //     return (params.row?.total_return_amount || "N/A");
      //   },
      //   renderCell: (params) => {
      //     let name = +params.row?.total_return_amount || "N/A";
      //     let name1=name.toFixed(2)

      //     return (
      //       <Tooltip title={name1 || "N/A"}>
      //         <Typography variant="inherit">{name1}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "totalReturnedAmount",
        headerName: "Total Returned Amount",
        sortable: false,
        width: 250,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return params.row?.total_return_amount ?? "N/A"; // Use nullish coalescing for better clarity
        },
        renderCell: (params) => {
          // Ensure 'total_return_amount' is a number before calling toFixed
          let totalReturnAmount = params.row?.total_return_amount;
          let name = totalReturnAmount && !isNaN(totalReturnAmount) ? (+totalReturnAmount).toFixed(2) : "N/A";
      
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },      
      // {
      //   field: "returnRate",
      //   headerName: "Return Rate",
      //   width: 250,
      //   sortable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.return_rate || "N/A");
      //   },
      //   renderCell: (params) => {
      //     let name = +params.row.return_rate || "N/A";
      //     let name1=name.toFixed(2)

      //     return (
      //       <Tooltip title={name1 || "N/A"}>
      //         <Typography variant="inherit">{name1}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "returnRate",
        headerName: "Return Rate",
        width: 250,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return params.row?.return_rate ?? "N/A"; // Use nullish coalescing for better clarity
        },
        renderCell: (params) => {
          // Ensure 'return_rate' is a number before calling toFixed
          let returnRate = params.row?.return_rate;
          let name = returnRate && !isNaN(returnRate) ? (+returnRate).toFixed(2) : "N/A";
      
          return (
            <Tooltip title={name !== "N/A" ? `${name}%` : name}>
              <Typography variant="inherit">{name !== "N/A" ? `${name}%` : name}</Typography>
            </Tooltip>
          );
        },
      },      
      {
        field: "topReturnReasons",
        headerName: "Top Return Reasons",
        width: 250,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return (params.row?.top_return_reasons || "N/A");
        },
        renderCell: (params) => {
          let name = params.row.top_return_reasons || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
    ];
    return result;
  };

  const getStateColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "stateName",
        headerName: "State Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.state || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.state || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalSold",
        headerName: "Total Sold",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.total_sold || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.total_sold || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalReturned",
        headerName: "Total Returned",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.total_returned || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.total_returned || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "totalReturnAmount",
      //   headerName: "Total Return Amount",
      //   sortable: false,
      //   width: 150,
      //   editable: false,
      //   filterOperators: stringOperators,
      //   valueGetter: (params) => {
      //     return (params.row?.total_return_amount || "N/A");
      //   },
      //   renderCell: (params) => {
      //     let name = +params.row?.total_return_amount || "N/A";
      //     let name1=name.toFixed(2)

      //     return (
      //       <Tooltip title={name1 || "N/A"}>
      //         <Typography variant="inherit">{name1}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "totalReturnAmount",
        headerName: "Total Return Amount",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return params.row?.total_return_amount ?? "N/A"; // Use nullish coalescing for better clarity
        },
        renderCell: (params) => {
          // Ensure that 'name' is a number before calling toFixed
          let totalReturnAmount = params.row?.total_return_amount;
          let name = totalReturnAmount && !isNaN(totalReturnAmount) ? (+totalReturnAmount).toFixed(2) : "N/A";
      
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "returnRate",
      //   headerName: "Return Rate",
      //   width: 250,
      //   sortable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.return_rate || "N/A");
      //   },
      //   renderCell: (params) => {
      //     let name = +params.row.return_rate || "N/A";
      //     let name1=name.toFixed(2)

      //     return (
      //       <Tooltip title={name1 || "N/A"}>
      //         <Typography variant="inherit">{name1}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "returnRate",
        headerName: "Return Rate",
        width: 250,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return params.row?.return_rate ?? "N/A"; // Use nullish coalescing for better clarity
        },
        renderCell: (params) => {
          // Ensure that 'name' is a number before calling toFixed
          let returnRate = params.row?.return_rate;
          let name = returnRate && !isNaN(returnRate) ? (+returnRate).toFixed(2) : "N/A";
      
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },      
      {
        field: "topReturnReasons",
        headerName: "Top Return Reasons",
        width: 250,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return (params.row?.top_return_reasons || "N/A");
        },
        renderCell: (params) => {
          let name = params.row.top_return_reasons || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
    ];
    return result;
  };

  const getReturnReasonColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "reason",
        headerName: "Reason",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.reason || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.reason || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "returnCount",
        headerName: "Return Count",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return (params.row?.return_count || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.return_count || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "percentage",
      //   headerName: "Percentage",
      //   sortable: false,
      //   width: 150,
      //   editable: false,
      //   filterOperators: stringOperators,
      //   valueGetter: (params) => {
      //     return (params.row?.percentage || "N/A");
      //   },
      //   renderCell: (params) => {
      //     let name = +params.row?.percentage || "N/A";
      //     let name1=name.toFixed(2)

      //     return (
      //       <Tooltip title={name1 || "N/A"}>
      //         <Typography variant="inherit">{name1}%</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "percentage",
        headerName: "Percentage",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          return params.row?.percentage ?? "N/A"; // Use nullish coalescing for better clarity
        },
        renderCell: (params) => {
          // Ensure 'percentage' is a number before calling toFixed
          let percentage = params.row?.percentage;
          let name = percentage && !isNaN(percentage) ? (+percentage).toFixed(2) : "N/A";
      
          return (
            <Tooltip title={name !== "N/A" ? `${name}%` : name}>
              <Typography variant="inherit">{name !== "N/A" ? `${name}%` : name}</Typography>
            </Tooltip>
          );
        },
      },      
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        disableExport: true,
        renderCell: (params) => {
          const value = params.row?.reasonId;
          const returnReason = params.row?.reason;
          return (
            <Tooltip title="Click to view detailed report">
              <IconButton
                variant="extended"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  fetchReturnOrderLineItemByReturnReasonId(value, returnReason);
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ];
    return result;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          {loading && <OpaqueLoading />}
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <FormControl style={{ minWidth: 150 }}>
              <Autocomplete
                id="filter"
                disablePortal
                value={selectedTab}
                onChange={(event, newValue) => handleTabChange(newValue)}
                options={filters || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Filter"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate1}
                disabled={loading}
                format="YYYY/MM/DD"
                onChange={(data) => formatDate(data)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                minDate={minDate}
                value={endDate1}
                disabled={!dateRange1 || loading}
                format="YYYY/MM/DD"
                onChange={(data) => finalDateRangeFilter(data.$d)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <Button
              variant="text"
              onClick={() => clearDateFilter()}
              disabled={!dateRange1 || loading}
            >
              Clear
            </Button>
          </div>
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadCSV()}
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              Export
            </Button>
          </Stack>
        </HeaderContainer>
        <TableContainer>
          <DataGridContainer
            style={{ height: !accessChanges?.length ? "200px" : "auto" }}
          >
            <DataGrid
              className="payrollGrid"
              density="compact"
              rows={accessChanges}
              columns={getColumns()}
              disableSelectionOnClick
              disableRowSelectionOnClick
              rowHeight={60}
              disableDensitySelector
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </DataGridContainer>
        </TableContainer>
      </StaffEditorPageContainer>
    </>
  );
}
