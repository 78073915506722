import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Add } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisitTypeCheckModal from "../../components/modals/PicklistModals/VisitTypeCheckModal";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `position: relative;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: calc(100% );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
    `
    flex-direction: column;
  `}
`;

export default function VisitTypeCheck() {

    const [loading, setLoading] = useState(false);
    const [accessChanges, setAccessChanges] = useState("");
    const [visitModal, setVisitModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [dataModal, setDataModal] = useState();

    useEffect(() => {
        getVisitTypeChecks()
    }, []);

    const getVisitTypeChecks = async () => {
        try {
            setLoading(true);

            let results = await window.Platform.database.getVisitTypeCheck();
            const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

            setAccessChanges(jsonArrayWithId);
            setLoading(false)
        } catch (error) {
            window.NotificationUtils.showError("Error Fetching Data");
            setLoading(false)
        }
    }

    const getEmployeeColumns = () => {
        let result = [
            {
                field: "visitTime",
                headerName: "Visit Time",
                width: 200,
                sortable: false,
                valueGetter: (params) => {
                    return (params.row?.visitTime || "N/A");
                },
                renderCell: (params) => {
                    let name = params.row.visitTime;
                    return (
                        <Tooltip title={name || "N/A"}>
                            <Typography variant="inherit">{name}</Typography>
                        </Tooltip>
                    )
                },
            },
            {
                field: "Actions",
                headerName: "Actions",
                editable: false,
                hide: true,
                minWidth: 150,
                renderCell: (params) => {
                    return (
                        <Tooltip title={"Update"}>
                            <IconButton onClick={() => handleEdit(params.row)}>
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    )
                },
            }
        ];
        return result;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const renderContent = () => {
        return (
            <Box sx={{ height: "100%", width: "100%" }}>
                <DataGridContainer>
                    <DataGrid
                        className="payrollGrid"
                        density="compact"
                        rows={accessChanges || []}
                        columns={getEmployeeColumns()}
                        rowHeight={60}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        autoPageSize
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSizeOptions={[10]}
                    />
                </DataGridContainer>
            </Box>
        );
    };

    const addVisitTypeCheck = () => {
        setVisitModal(true);
    }

    const handleEdit = (data) => {
        setEditModal(true);
        setDataModal(data);
    }

    return (
        <>
            {isMobile && (
                <Drawer />
            )}
            <StaffEditorPageContainer>
                {loading && <OpaqueLoading />}
                <HeaderContainer>
                    <div></div>
                    <Stack direction={"row"} spacing={2} >
                        {/* <Button variant="contained" color="primary"
                            onClick={() => addVisitTypeCheck()} sx={{ mr: 1, borderRadius: '10px' }}>
                            <Add sx={{ mr: 1 }} />
                            Add New
                        </Button> */}
                    </Stack>
                </HeaderContainer>
                <TableContainer>{renderContent()}</TableContainer>
            </StaffEditorPageContainer>
            {
                visitModal &&
                <VisitTypeCheckModal
                    onClose={() => setVisitModal(false)}
                    loading={loading}
                    fetchData={() => getVisitTypeChecks()}
                    status={true}
                />
            }
            {
                editModal &&
                <VisitTypeCheckModal
                    onClose={() => setEditModal(false)}
                    loading={loading}
                    fetchData={() => getVisitTypeChecks()}
                    status={false}
                    propData={dataModal}
                />
            }

        </>
    );
}