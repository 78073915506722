import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkAddTransporter = ({onClose,fetchData}) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const hiddenFileInput = useRef(null);
  const [data, setData] = useState([]);
  const [assignedToData, setAssignTodata] = useState([])
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);




  const getRowClassName = (params) => {
    console.log("==================>>>>>>>>>>>>>  params",params);
    
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{
            fileName: 'bulk-upload-Supplier',
          }}
        />
      </GridToolbarContainer>
    );
  }

  // const getEmployeeName = (
  //   employeeCode,
  //   reportingManagerStringNeeded = false
  // ) => {
  //   let result = "Admin";
  //   let employee = props.staffMembers.find(
  //     (member) => member.employeeCode === employeeCode
  //   );
  //   if (reportingManagerStringNeeded && employee) {
  //     employee = props.staffMembers.find(
  //       (member) => member.employeeCode === employee.reportingManager
  //     );
  //   }
  //   if (employee) {
  //     let name = `${employee.firstName} ${
  //       employee.lastName ? employee.lastName : ""
  //     }`;
  //     if (!reportingManagerStringNeeded) {
  //       result = name;
  //     } else {
  //       result = `${name}-${employee.employeeCode} (${employee.role})`;
  //     }
  //   }
  //   return result;
  // };
    const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // setView(true);
    // setFailedData([]);
    // setSearchTerm("");
    // setData([]);
    // setDisableButtons(true);
    setUploaded(false);
    handleImport(file);
    setFileKey((prevKey) => prevKey + 1);
  };
    const handleImport = (file) => {
      try {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        // const workbook = XLSX.read(data, { type: "array" });
        // const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // if (headers[0] !== "lead" || headers[1] !== "leadData") {
        //     throw new Error("Invalid headers in the Excel file");
        //   }
        // const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // const formattedData = jsonData.slice(1).map((row,index) => ({
        //   name: row[0],
        //   ownerId:row[1],
        //   id:index+1
          
        // }));
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        // if (headers[0] !== "Lead" || headers[1] !== "UserId") {
        //   window.NotificationUtils.showError(
        //     "Please select a valid file for upload with exact header in template"
        //   );
        //   setLoading(false)
        //   return 
        // }

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = jsonData.slice(1).map((row, index) => ({
            transporterName: row[0],
         
            completeAddress: row[1],

            pincode: row[2]?.toString(),
            phone:row[3],
            email: row[4],
            contactPersonName: row[5],
            contactPersonContactNo:row[6],
            state:row[7],
          id: index + 1,
        }));
          console.log("formattedData",formattedData)
        setTargetRows(formattedData)
        setLoading(false);
        // setDisableButtons(false);
      };
      reader.readAsArrayBuffer(file);
    }}
    catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

  const handleDownload = async ( ) => {
    try
    {
     const headers =['transporterName', "completeAddress", "pincode", "phone", "email", "contactPersonName", "contactPersonContactNo", "state"]
     
     const workbook = new ExcelJS.Workbook();
     const worksheet = workbook.addWorksheet('Sheet1');
     // const decisionMakerRole = [newContactPersonRole]
     
     worksheet.addRow(headers);
     const numRows = 101;
     const getColumnLetter = (col) => {
       let letter = '';
       while (col > 0) {
         let temp = (col - 1) % 26;
         letter = String.fromCharCode(temp + 65) + letter;
         col = (col - temp - 1) / 26;
       }
       return letter;
     };
     const numberOfOptions = 13; 
     for (let e = 2; e <= numRows; e++) {
       for (let f = 1; f <= numberOfOptions; f++) {
        const textBoxCol = getColumnLetter(6 + (f - 1) * 2); 
            const textBoxCell = worksheet.getCell(`${textBoxCol}${e}`);
 
           
       }
     }
     workbook.xlsx.writeBuffer().then((buffer) => {
       const blob = new Blob([buffer], { type: 'application/octet-stream' });
       const url = URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = url;
       a.download = 'transporterAdd.xlsx';
       a.click();
       URL.revokeObjectURL(url);
       setLoading(false)
     });
   }
   catch (error) {
     console.error(error);
     setLoading(false)
   }

//     try
//    {
//     const allData = [
//       {
//         "StockistName": "XYZ Stockist",
//         "subStockistName": "XYZ Sub-Stockist",
//         "gstNo": "29XYZ1234XYZ1A1",
//         "panNumber": "ABCD123456",
//         "kyc": "Completed",
//         "decisionMakerName": "John Doe",
//         "decisionMakerContact": "7217126512",
//         "makerRole": "Manager",
//         "pincode": "221002",
//         "addressLine1": "123, Green Valley Road, Bangalore",
//         "bankName": "State Bank of India",
//         "accountHolderName": "XYZ Stockist",
//         "branchName": "Main Branch",
//         "accountNo": "9876543210",
//         "ifsc": "SBIN0001234",
//         "billingPincode": "221001",
//         "billingAddressLine1": "456, Sunshine Lane, New Delhi",
//         "shippingAddressLine1": "789, River Side, Pune",
//         "email": "john.doe@Stockist.com",
//         "UserId": "N5o9L1HPsF"
//       }
//     ]    
     
//     setLoading(true)
//     // const worksheet = XLSX.utils.json_to_sheet(jsonData);
//     // const workbook = XLSX.utils.book_new();
//     // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
//     // XLSX.writeFile(workbook, `${fileName}.xlsx`);
//     const selectedColumns = [
//         "StockistName",
//         "subStockistName",
//         "gstNo",
//         "panNumber",
//         "kyc",
//         "decisionMakerName",
//         "decisionMakerContact",
//         "makerRole",
//         "pincode",
//         "addressLine1",
//         "bankName",
//         "accountHolderName",
//         "branchName",
//         "accountNo",
//         "ifsc",
//         "billingPincode",
//         "billingAddressLine1",
//         "shippingAddressLine1",
//         "email",
//         "UserId"
//     ];    
    
// // console.log("hello",allData)
// const filteredData = allData.map(item =>
//   Object.fromEntries(Object.entries(item).filter(([key]) => selectedColumns.includes(key)))
// );
//    const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
//     XLSX.writeFile(workbook, `StockistAdd.xlsx`);
//     setLoading(false)
//     }

    
//     catch (error) {
//       console.error(error);
//       setLoading(false)
//     }

  };




  const getGridColumns = () => {
      let columns = [
        {
            field: "id",
            headerName: "S.no",
            maxWidth: 100,
            valueGetter: (params) => {
              let name = params.row.id || "";
              return name;
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              let name = params.row.id || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "name",
            headerName: "Transporter Name",
            minWidth: 200,
            valueGetter: (params) => {
            //  let name=params.row.distributorName
             let name=params.row.transporterName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              // let name=params.row.distributorName
              let name=params.row.transporterName

              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "completeAddress",
            headerName: "Complete Address",
            minWidth: 200,
            valueGetter: (params) => {
            //  let name=params.row.subDistributorName
            let name=params.row.completeAddress

             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              // let name=params.row.subDistributorName
              let name=params.row.completeAddress
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "pincode",
            headerName: "Pincode",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.pincode
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.pincode
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "phone",
            headerName: "Phone",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.phone
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.phone
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.email
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.email
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "contactPersonName",
            headerName: "Contact Person Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.contactPersonName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.contactPersonName
              return (
                <Tooltip title={name}>{console.log(params.row,"llllllllllllllllllllllllllllllllllllllllll")}
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "contactPersonContactNo",
            headerName: "Contact Person Number",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.contactPersonContactNo
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.contactPersonContactNo
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "state",
            headerName: "state",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.state
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.state
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },         
         
      ];
      if (uploaded) {
        columns.splice(
          0,
          0,
          {
            field: "status",
            headerName: "Upload Status",
            width: 100,
            renderCell: (params) => {
                // console.log("PARAMSINDATA",params)
              let name = "";
              if (params.row.status) {
                name =
                  params.row.status === "Success" ? "Success" : "Failure";
              }
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
            editable: false,
          },
          {
            field: "reason",
            headerName: "Reason",
            width: 200,
            renderCell: (params) => {
              let name = params.row.reason || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
            editable: false,
          }
        );
      }
      return columns;
  }

  const getFileRows = async (file) => {
    try {
      let data = await file.arrayBuffer();
      const wb = XLSX.read(data, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let dataParse = XLSX.utils.sheet_to_json(ws);
      if(dataParse.length > 0){
        dataParse = dataParse.map((entry, index) => {
          return {
            ...entry,
            id: index,
          };
        });
      }
      let element = document.getElementById("fileUploadPicker");
      if (element) {
        element.value = "";
      }
      return dataParse;
    } catch (error) {
      throw error;
    }
  };

  const handleUpload = async () => {
    try {
      let uploadInput = document.getElementById("filePickerButton");
      if (uploadInput) {
        if (uploadInput.files.length > 0) {
          let file = uploadInput.files[0];
          setLoading(true);
          let response = await getFileRows(file);
          // console.log("RESPONSE",response)
          if (response.length > 0) {
            setTargetRows(response);
            setUploaded(false);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

  const hasErrors = () => {
    try {
      if (!targetRows) {
        window.NotificationUtils.showError(
          "Please select a valid file for upload"
        );
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const dataRowCheck = (rowDetails) => {
    try {
      let result = false;
      if (!rowDetails.employeeCode) {
        // result = BULK_UPLOAD_FAILURE_CODES.MISSING_EMPLOYEE_CODE;
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

    const handleRowSelection = (selection) => {
    // console.log("SELECT",selection)
    // console.log("selection",rows)
    let array=[]
    let data=targetRows.filter((row) => selection.includes(row.id));
    let finalData=data.map((row) => {
      let sent={
        transporterName:row.transporterName, id:row.id, completeAddress:row.completeAddress,pincode:row.pincode,
        phone:row.phone,email:row.email,contactPersonName:row.contactPersonName,contactPersonContactNo:row.contactPersonContactNo,
        state:row.state}
      array.push(sent)

    })
  
    // console.log("selection1",finalData)
    // setSelectedRows(finalData);
    return array;
  };

  const onClickUpload = async () => {
    try {
   const data=handleRowSelection(selectedRowIds)
   console.log("SELECTED ROWS",data)
    let finalData =[...targetRows]
    console.log("SELECTED ROWS",selectedRowIds);
    console.log("SELECTED ROWS FINALLY",finalData)
      setLoading(true);
      let response = await window.Platform.database.addBulkTransporter(data);
      console.log("SUCCESS",response)
      setTargetRows(response.data)
       
    //   let newTargetRows = [...newRows];
    console.log("FINAL",finalData)
    setSelectedRowIds([])
      for(let responseEntry of response.data){
        let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
        // console.log("ROWINDEX",rowIndex)
        if(rowIndex !== -1){
            finalData[rowIndex].status = responseEntry.status;
            finalData[rowIndex].reason = 'N/A';
            // console.log("CHECK!")
          if(responseEntry.status === 'Failure'){
            finalData[rowIndex].reason = responseEntry.reason;
          }
        }
      }
      setTargetRows(finalData);
      if (!uploaded) {
        setUploaded(true);
      }
      setLoading(false);
      if (response.status) {
        window.NotificationUtils.showError(response.message);
      } else {
        window.NotificationUtils.showSuccess('Submition Complete')
      }
    //   window.NotificationUtils.showSuccess('Submition Complete')

      // window.NotificationUtils.showSuccess("Details Uploaded Please verify your upload");
      // fetchdata();
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(error?.message);
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        {targetRows.length > 0 && (
          <>
            <DataGridContainer>
              <DataGrid
                disableSelectionOnClick={true}
                checkboxSelection={true}
                rows={targetRows}
                columns={getGridColumns()}
                // onRowSelectionModelChange={handleRowSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowIds(newRowSelectionModel);
                }}
        
                rowSelectionModel={selectedRowIds}
                density="compact"
                autoPageSize
                getRowClassName={getRowClassName}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSizeOptions={[10]}
              />
            </DataGridContainer>
            <NoteText variant="body2">
              Note: If selected, only the selected rows will be uploaded
            </NoteText>
          </>
        )}
        <InputFieldContainer>
    
          {/* <a target="_blank" href="/assets/bulkDistributor.xlsx" download><Button variant="contained" color="primary">Download Template</Button></a> */}
          <Button variant="contained" color="primary" onClick={handleDownload}>Download Template</Button>
          {/* <label htmlFor="filePickerButton">
            <Input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="filePickerButton"
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
            />
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              component="span"
            > */}
               <input
                    key={fileKey}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Button
                    // style={{ color: '#003974', borderColor: '#003974' }}
                    variant="contained"
                    sx={{
                      background: "primary",
                      borderColor: "#003974",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }}
                    // startIcon={<Iconify icon="eva:attach-outline" />}
                    onClick={handleButtonClick}
                    // disabled={disableButtons}
                  >
                    Select File
                  </Button>
              {/* Select File
            </Button>
          </label> */}
        </InputFieldContainer>
        <ModalActionsContainer>
          {/* <FirstButton
            disabled={loading}
            onClick={()=>handleClose()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton> */}
          <div>

          </div>
          <Button
            disabled={loading || selectedRowIds.length === 0}
            onClick={onClickUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  const handleClose=()=>{
    onClose()
    fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Transporter Bulk</Typography>
          <IconButton disabled={loading} onClick={()=>handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkAddTransporter;

