import React, { useState , useEffect} from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;

const TableContainer = styled.div`
  width: ${isMobile ? "100%" : "98%"};
  border-radius: 8px;
  margin-left: ${isMobile ? "0px" : "10px"};
  margin-bottom: ${isMobile ? "0px" : "10px"};
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  margin-top: 10px;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AddTransporterModal = ({
  props,
  onClose,
  remarks,
  data,
  id,
  distributorId,
  fetchData,
  selectedTab,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [isFetchingStates, setIsFetchingStates] = useState(false);
  const [form, setForm] = useState({
    transporterName: props?.transporter_name,
    completeAddress: props?.complete_address,
    pincode: props?.pin_code,
    state: props?.state,
    phone: props?.phone,
    email: props?.email,
    contactPersonName: props?.contact_person_name,
    contactPersonContactNo: props?.contact_person_number,
    gst: props?.gst,
    // servingStates: props?.serving_states,
    // servingStates: Array.isArray(props?.serving_states) ? props?.serving_states : [],
    servingStates: Array.isArray(props?.serving_states)
    ? props?.serving_states
    : props?.serving_states
    ? JSON.parse(props.serving_states)  
    : [],
  
    id: props?.transporterId,
  });
  const [errors, setErrors] = useState({});

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
// console.log(props,">>>>>>>>>>>>>>>>>>>>>>>>>>prop");

  const changeHandler = (fieldName, value) => {
    let val = value;
    if (fieldName === "contactPersonContactNo" || fieldName === "phone") {
      val = val.replace(/\D/g, "");
    }
    setForm((prevForm) => ({ ...prevForm, [fieldName]: val }));
  };

  const fetchPinCode = async (details) => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchPinCode(details);
      setForm({ ...form, state: results.data[0].StateName });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchStates = async () => {
      setIsFetchingStates(true);
      try {
        const result = await window.Platform.database.getStates(); 
        setStates(result.data);
        setIsFetchingStates(false);
      } catch (error) {
        console.log(error);
        setIsFetchingStates(false);
      }
    };
    
    fetchStates();
  }, []);

  const handleStateChange = (event) => {
    const { value } = event.target;
    changeHandler("servingStates", value); 
    setForm((prevForm) => ({ ...prevForm, servingStates: value }));
  };

  const renderContent = () => {
    return (
      <div style={{ maxHeight: "525px", height: "520px", overflowY: "auto" }}>
        <Stack direction={"row"} spacing={2} padding={2}></Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Transporter Name"
            value={form?.transporterName || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("transporterName", e.target.value)}
            required
            error={errors.transporterName ? true : false}
          />

          <TextField
            label="Complete Address"
            value={form?.completeAddress || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("completeAddress", e.target.value)}
            required
            error={errors.completeAddress ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="PIN code"
            value={form?.pincode || ""}
            type="text"
            fullWidth
            onChange={async (e) => {
              const inputValue = e.target.value;
              const sanitizedValue = inputValue
                .replace(/[^0-9]/g, "")
                .slice(0, 6);
              if (sanitizedValue.length <= 7) {
                if (sanitizedValue.length === 6) {
                  await fetchPinCode(sanitizedValue);
                }
                changeHandler("pincode", sanitizedValue);
              }
            }}
            required
            error={errors.pincode ? true : false}
          />

          <TextField
            label="State"
            value={form?.state || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("state", e.target.value)}
            disabled
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Phone"
            value={form?.phone || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("phone", e.target.value)}
            required
            error={errors.phone ? true : false}
            inputProps={{ maxLength: 10 }}
          />

          <TextField
            label="Email"
            value={form?.email || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("email", e.target.value)}
            required
            error={errors.email ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Contact Person Name"
            value={form?.contactPersonName || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("contactPersonName", e.target.value)}
          />

          <TextField
            label="Contact Person Number"
            value={form?.contactPersonContactNo || ""}
            type="text"
            fullWidth
            onChange={(e) =>
              changeHandler("contactPersonContactNo", e.target.value)
            }
            inputProps={{ maxLength: 10 }}
            required
            error={errors.contactPersonContactNo ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="GST"
            value={form?.gst || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("gst", e.target.value)}
            inputProps={{ maxLength: 15, style: { textTransform: "uppercase" } }}
            // required
            // error={errors.gst ? true : false}
          />

          {/* <TextField
            label="Serving States"
            value={form?.servingStates || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandler("servingStates", e.target.value)}
            required
            error={errors.servingStates ? true : false}
          /> */}
          
          <FormControl fullWidth required>
            <InputLabel>Serving States</InputLabel>
            {/* <Select
              label="Serving States"
              multiple
              value={form.servingStates}
              onChange={handleStateChange}
              renderValue={(selected) => selected.join(", ")}
              required
              error={errors.servingStates ? true : false}
            >
              {isFetchingStates ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : (
                states.map((state) => (
                  <MenuItem key={state.id} value={state.name}>
                    <Checkbox checked={form.servingStates.indexOf(state.name) > -1} />
                    <ListItemText primary={state.name} />
                  </MenuItem>
                ))
              )}
            </Select> */}
            <Select
  label="Serving States"
  multiple
  value={form.servingStates || []}  // Ensure it defaults to an array
  onChange={handleStateChange}
  renderValue={(selected) => selected.join(", ")}  // This works as 'selected' will be an array
  required
  error={errors.servingStates ? true : false}
>
  {isFetchingStates ? (
    <MenuItem disabled>
      <CircularProgress size={24} />
    </MenuItem>
  ) : (
    states.map((state) => (
      <MenuItem key={state.id} value={state.name}>
        <Checkbox checked={form.servingStates.indexOf(state.name) > -1} />
        <ListItemText primary={state.name} />
      </MenuItem>
    ))
  )}
</Select>
          </FormControl>
        </Stack>

        <ButtonContainer>
          <Button
            variant="contained"
            onClick={
              !props?.transporterId
                ? () => onsubmit(form)
                : () => onupdate(form)
            }
          >
            {!props?.transporterId ? "Create" : "Update"}
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.transporterName?.trim()) {
      errors.transporterName = "Transporter name to is required";
    }

    if (!data.completeAddress?.trim()) {
      errors.completeAddress = "Complete address to is required";
    }

    // if (!data.servingStates?.trim()) {
    //   errors.servingStates = "Serving states is required";
    // }
    if (!data.servingStates) {
      errors.servingStates = "Serving states is required";
    }

    if (!data.pincode?.trim()) {
      errors.pincode = "Pin is required";
    } else if (data.pincode?.length < 6) {
      errors.pincode = "Pin code must be exactly 6 digits long.";
    }

    if (!data.phone?.trim()) {
      errors.phone = "Phone is required";
    } else if (data.phone?.length < 10) {
      errors.phone = "Phone must be exactly 10 digits long.";
    }

    // if (!data.gst?.trim()) {
    //   errors.gst = "GST is required";
    // } else if (data.gst?.length < 15) {
    //   errors.gst = "GST must be exactly 15 digits long.";
    // }

    if (!data.email?.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data.contactPersonContactNo?.trim()) {
      errors.contactPersonContactNo = "Contact person number is required";
    }

    return errors;
  };

  const onsubmit = async (data) => {
    try {
      const errors = validateForm(data);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true);

        const result = await window.Platform.database.addTransporter(data);

        if (result.status) {
          window.NotificationUtils.showError(result.message);
          setLoading(false);
        } else {
          window.NotificationUtils.showSuccess("Transporter added successfully!");
          setLoading(false);
          onClose();
          fetchData();
        }
      } else {
        window.NotificationUtils.showError("Please fill all required fields.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError(error?.message);
      setLoading(false);
    }
  };
// console.log(props,"??????????????????")
  const onupdate = async (data) => {
    try {
      const errors = validateForm(data);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true);

        let result = await window.Platform.database.updateTransporter(data);

        // window.NotificationUtils.showSuccess(
        //   "Transporter updated successfully!"
        // );
        if(result.status){
          window.NotificationUtils.showError(result.message);
        }
        else{
          window.NotificationUtils.showSuccess(result.message)
        }
        setLoading(false);
        onClose();
        fetchData();
      } else {
        window.NotificationUtils.showError("Please fill all required fields.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}>
      {loading && <OpaqueLoading />}
      <ModalHeaderContainer style={{ marginBottom: 2, paddingBottom: 3 }}>
        <Typography variant={isMobile ? "h6" : "h5"}>
          Add Transporter
          <Tooltip title="Order Id">{id}</Tooltip>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </ModalHeaderContainer>
      <TableContainer>
        <TabsContainer>
          <StyledTabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Transporter Details " />
          </StyledTabs>
          {tabValue == 0 && renderContent()}
        </TabsContainer>
      </TableContainer>
    </Dialog>
  );
};

export default AddTransporterModal;
