import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Typography, Button, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};
  ${isMobile && `flex-direction: column`}
`;

const TabsContainer = styled.div`
  ${isMobile && `height: calc(100vh - 56px - 20px);`}
`;

export default function ReturnOrderLineItemReport() {
  const location = useLocation();
  const { id, returnReason } = location.state;

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);

  const [filter, setFilter] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange1, setDateRange1] = useState(false);

  const [startDate1, setStartDate1] = useState();
  const [endDate1, setEndDate1] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    fetchReturnOrderLineItemByReturnReasonId();
  }, []);

  const finalDateRangeFilter = async (data) => {
    try {
      setLoading(true);
      setFilter(true);

      const datePickerResponse = new Date(data);

      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
      const day = String(datePickerResponse.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate);
        setEndDate1(formattedDate);
      }

      const response =
        await window.Platform.database.getReturnOrderLineItemByReturnReasonId({
          reasonId: id,
          startDate: startDate,
          endDate: data ? formattedDate : endDate,
        });
      const jsonArrayWithId = response?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );

      fetchReturnOrderLineItemByReturnReasonId();
    }
  };

  const formatDate = async (data) => {
    setMinDate(data);
    setDateRange1(true);
    setEndDate1(null);
    setEndDate(null);
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
    const day = String(datePickerResponse.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate);
    setStartDate(formattedDate);
  };

  const clearDateFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setFilter(false);
    fetchReturnOrderLineItemByReturnReasonId();
  };

  const downloadCSV = () => {
    const accessData = [...accessChanges];

    const rows = [];
    const headers = ["Reason", "Name", "Quantity"];

    rows.push(headers);

    accessData.sort((a, b) => {
      const nameA = a.nameSku || a.product_name || "";
      const nameB = b.nameSku || b.product_name || "";
      return nameA.localeCompare(nameB);
    });

    accessData.forEach((item) => {
      const name = item.nameSku || item.product_name || "No Name";
      const { itemId, qty } = item;

      const reason = returnReason;

      rows.push([reason, name, qty]);
    });

    const merges = [
      {
        s: { r: 1, c: 0 },
        e: { r: rows.length - 1, c: 0 },
      },
    ];

    const ws = XLSX.utils.aoa_to_sheet(rows);

    if (merges.length > 0) {
      ws["!merges"] = merges;
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "return_order_line_items_report.xlsx");
  };

  const fetchReturnOrderLineItemByReturnReasonId = async () => {
    try {
      setLoading(true);

      const payload = {
        reasonId: id,
      };

      const results =
        await window.Platform.database.getReturnOrderLineItemByReturnReasonId(
          payload
        );
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const getReturnOrderLineItemColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "returnReason",
        headerName: "Return Reason",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Tooltip title={returnReason || "N/A"}>
              <Typography variant="inherit">{returnReason}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "itemName",
        headerName: "Name",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          let name = params.row.nameSku || params.row.product_name || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "qty",
        headerName: "Quatity",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.qty || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
    ];
    return result;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          {loading && <OpaqueLoading />}
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate1}
                disabled={loading}
                format="YYYY/MM/DD"
                onChange={(data) => formatDate(data)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                minDate={minDate}
                value={endDate1}
                disabled={!dateRange1 || loading}
                format="YYYY/MM/DD"
                onChange={(data) => finalDateRangeFilter(data.$d)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <Button
              variant="text"
              onClick={() => clearDateFilter()}
              disabled={!dateRange1 || loading}
            >
              Clear
            </Button>
          </div>
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadCSV()}
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              Export
            </Button>
          </Stack>
        </HeaderContainer>
        <TableContainer>
          <TabsContainer>
            <DataGridContainer
              style={{ height: !accessChanges?.length ? "200px" : "auto" }}
            >
              <DataGrid
                className="payrollGrid"
                density="compact"
                rows={accessChanges}
                columns={getReturnOrderLineItemColumns()}
                disableSelectionOnClick
                disableRowSelectionOnClick
                rowHeight={60}
                disableDensitySelector
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                filterMode="server"
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </DataGridContainer>
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
    </>
  );
}
