import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const isMobile = window.innerWidth < 900;


const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding:10px 10px 0 10px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom: 0px;
  }
`;
// const StyledContainer = styled.div`
//   /* Default styles */
//   width: 100%;
//   max-width: 960px;
//   margin: 0 auto;

//   /* Mobile styles */
//   @media (max-width: 600px) {
//     padding: 0 10px;
//   }
// `;
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const PackagingListModal = ({ onClose, list }) => {

  const getItemColumns = () => {
    let result = [
      {
        field: "shippingMark",
        headerName: "Shipping Mark",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "chineseDescription",
        headerName: "Chinese Description",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "englishDescription",
        headerName: "English Description",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "brandLogo",
        headerName: "Brand Logo",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "pcs/ctn",
        headerName: "Pieces/Cartons",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "ctns",
        headerName: "Cartons",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "gw/ctns",
        headerName: "GW Cartons",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "gw",
        headerName: "GW",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "ctnSize",
        headerName: "Carton Size",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "cbm",
        headerName: "Cubic Meter",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "photo",
        headerName: "Photo",
        sortable: false,
        width: 150,
        editable: true,
      },

    ];


    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "items-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

  // const renderContent = () => {
  //   return (
  //     <DataGridContainer>
  //     <Box sx={{ height: "100%", width: "100%" }}>
  //       <DataGrid
  //         className="payrollGrid"
  //         checkboxSelection={true}
  //         rows={items}
  //         columns={getItemColumns()}
  //         rowHeight={70}
  //         disableSelectionOnClick
  //         disableRowSelectionOnClick
  //         autoPageSize
  //         components={{
  //           Toolbar: CustomToolbar,
  //         }}
  //       />
  //     </Box>
  //     </DataGridContainer>
  //   );
  // };



  const renderContent = () => {
    return (
      <>
        {getItemColumns().map(item => {
          return (
            <div style={{ width: '100%', padding: '5px', display: 'flex' }}>
              <div style={{ width: '50%', font: 'bold' }}>
                <Typography fontWeight='bold'>{item.headerName} :</Typography>
              </div>
              <div style={{ width: '50%' }}>
                <Typography>{list[item.field]}</Typography>
              </div>
            </div>
          )
        })}
        {/* <div style={{ width: '100%', padding: '5px', display: 'flex' }}>
          <div style={{ width: '50%', font: 'bold' }}>
            <Typography fontWeight='bold'>Shipping Mark :</Typography>
          </div>
          <div style={{ width: '50%' }}>
            <Typography>{list['shippingMark']}</Typography>
          </div>
        </div>
        <div style={{ width: '100%', padding: '5px', display: 'flex' }}>
          <div style={{ width: '50%', font: 'bold' }}>
            <Typography fontWeight='bold'>Chinese Description :</Typography>
          </div>
          <div style={{ width: '50%' }}>
            <Typography>{list['chineseDescription']}</Typography>
          </div>
        </div>
        <div style={{ width: '100%', padding: '5px', display: 'flex' }}>
          <div style={{ width: '50%', font: 'bold' }}>
            <Typography fontWeight='bold'>Englis Description :</Typography>
          </div>
          <div style={{ width: '50%' }}>
            <Typography>{list['englishDescription']}</Typography>
          </div>
        </div>
        <div style={{ width: '100%', padding: '5px', display: 'flex' }}>
          <div style={{ width: '50%', font: 'bold' }}>
            <Typography fontWeight='bold'>Brand Logo :</Typography>
          </div>
          <div style={{ width: '50%' }}>
            <Typography>{list['brandLogo']}</Typography>
          </div>
        </div> */}
      </>
    );
  };


  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg">
      <ModalHeaderContainer>
        <Typography variant="h5">Packaging List</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </ModalHeaderContainer>
      <DialogContent style={{ padding: "10px", minWidth: '400px' }}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default PackagingListModal;
