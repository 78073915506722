import Login from "../views/Login/index.js";
import Dashboard from "../views/Dashboard/index.js";
import { createBrowserRouter } from "react-router-dom";
import UserManagement from "../views/UserManagement/index.js";
// import Invite from "../views/invite/";
// import Tracking from "../views/Tracking/index.js";
import RoleManagement from "../views/RoleManagement/index.js";
import { createRef } from "react";
import Invite from "../views/invite/index.js";
import MasterManager from "../views/MasterManager/index.js";
import Visitmanagement from "../views/VisitManagement/index.js";
import AttendanceManagement from "../views/attendanceManagement/index.js";
import OrderManagement from "../views/orderManagement/index.js";
import PartyManagement from "../views/partyManagement/index.js";
import SeriesManagement from "../views/SeriesManagement/index.js";
import ProductManagement from "../views/productManagement.js/index.js";
import RoutePlanManagement from "../views/routePlanManagement/index.js";
import CollectionManagement from "../views/collectionManagement/index.js";
import SpecimenManagement from "../views/specimenManagement/index.js";
import LeadManagement from "../views/leadManagement/index.js";
import TransporterManagement from "../views/transporterManagement/index.js";
import AttendanceReport from "../views/report/attendanceReport.js";
import AuditLogs from "../views/AuditLogs/index.js";
import TotalTimeWorkedReport from "../views/report/totalTimeWorkedReport.js";
import WorkTimeSchoolDistributor from "../views/report/calculateTimeForSchoolAndDistributor.js";
import WorkTypePicklist from "../views/Picklist/workType.js";
import PartyTypePicklist from "../views/Picklist/partyType.js";
import BoardMaster from "../views/Picklist/boardMaster.js";
import BookType from "../views/Picklist/bookType.js";
import ClassMaster from "../views/Picklist/classMaster.js";
import CollectionType from "../views/Picklist/collectionType.js";
import ContactPersonRole from "../views/Picklist/contactPersonRole.js";
import DaType from "../views/Picklist/daType.js";
import FeedbackMaster from "../views/Picklist/feedbackMaster.js";
import GivenType from "../views/Picklist/givenType.js";
import GradeMaster from "../views/Picklist/gradeMaster.js";
import MediumMaster from "../views/Picklist/mediumMaster.js";
import NextType from "../views/Picklist/nextStep.js";
import PaymentType from "../views/Picklist/paymentType.js";
import RecieveCollection from "../views/Picklist/recieveCollection.js";
import RouteVisitType from "../views/Picklist/routeVisitType.js";
import StatusType from "../views/Picklist/statusType.js";
import SubjectMaster from "../views/Picklist/subjectMaster.js";
import VehicleType from "../views/Picklist/vehicleType.js";
import VisitType from "../views/Picklist/visitType.js";
import WorkDone from "../views/Picklist/workDone.js";
import PartyUpdateApproval from "../views/partyUpdateApproval/index.js";
import TrackingManagement from "../views/trackingManagement/index.js";
import ExpenseManagement from "../views/expenseManagement/index.js";
import Incentives from "../views/Incentives/incentives.js";
import SpecimenAllotment from "../views/specimenAllotmentManagement/index.js";
import ReturnOrder from "../views/orderManagement/returnOrder.js";
import OrderReport from "../views/report/orderReport.js";
import VisitDetailReport from "../views/report/visitDetailReport.js";
import VisitSummarizedReport from "../views/report/visitSummarisedReport.js";
import ForecastReport from "../views/forecastReport/index.js";
import ReturnReasonReport from "../views/returnReasonReport/index.js";
import ReturnOrderLineItemReport from "../views/returnReasonReport/fetchReturnOrderLineItem.js";
import FinancialReport from "../views/financialReport/index.js";
import PipelineReport from "../views/pipelineReport/index.js";
import AllPicklist from "../views/allPicklist/index.js";
import ExpensePurpose from "../views/Picklist/expensePurpose.js";
import Author from "../views/Picklist/Author.js";
import Language from "../views/Picklist/Language.js";
import DocumentListSchool from "../views/Picklist/documentListSchool.js";
import ExpenseType from "../views/Picklist/expenseType.js";
import DeliveryStatus from "../views/Picklist/deliveryStatus.js";
import LeadStatus from "../views/Picklist/leadStatus.js";
import LeadSource from "../views/Picklist/leadSource.js";
import LeadRating from "../views/Picklist/leadRating.js";
import SchoolCategory from "../views/Picklist/schoolCategory.js";
import VisitOutcome from "../views/Picklist/visitOutcome.js";
import ReturnReason from "../views/Picklist/returnReason.js";
import ProductGroup from "../views/Picklist/productGroup.js";
import MittplusProductGroup from "../views/Picklist/mittplusProductGroup.js";
import SeriesMaster from "../views/Picklist/seriesMaster.js";
import VisitTypeCheck from "../views/Picklist/VisitTypeCheck.js";
import StockistManagement from "../views/Stockist/StockistManagement.js";
import IncentiveManagement from "../views/Incentives/IncentiveManagement.js";

// const router = createBrowserRouter([
//     {
//       path: "/",
//       element: 
//           <Dashboard/>
    
    
//     },
//     {
//         path: "/login",
//         element: <Login />,
//         redirect: AuthContext,
//         pathTo: "/",
//     }
//     ,{
//         path: "/usermanagement",
//         element: <PrivateRoute><UserManagement/></PrivateRoute>
//     }

//   ]);

// export default router;

function routes(loggedIn, user){
    return[
        {
            path: "/login",
            name: "Login",
            component: Login,
            redirect: user,
            pathTo: "/dashboard",
            hideNavBar:true,
            // nodeRef: createRef(),
        },
        {
            redirect: loggedIn === false,
            path: "*",
            pathTo: "/login",
        },

        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
             hideNavBar:true,
            //  nodeRef: createRef(),
        },
        {
            redirect: loggedIn,
            path: "*",
            pathTo: "/dashboard",
         },
         {
            path: "/usermanagement",
            name: "User Management",
            component: UserManagement,
            nodeRef: createRef(),
            access : 'User Management'
        },
        // {
        //     path: "/tracking",
        //     name: "Tracking",
        //     component: Tracking,
        //     nodeRef: createRef(),

            
        // },
        {
            path: "/rolemanagement",
            name: "Role Management",
            component: RoleManagement,
            access: 'Role Management'

        },
        {
            path: "/mastermanager",
            name: "MasterManager",
            component: MasterManager,
            access: 'Master Manager'

        },
        {
            path: "/expenseManagement",
            name: "Expense Management",
            component: ExpenseManagement,
            access: 'Expense Management'

        },
      
        {
            path:"/platformInvite",
            name:"Invite",
            component:Invite,
            hideNavBar:true
        },
        {
            path:"/visitmanagement",
            name:"Visit",
            component:Visitmanagement,
            access: 'Visit Management'
            // hideNavBar:true
        },
        {
            path:"/attendancemanagement",
            name:"Attendance",
            component:AttendanceManagement,
            access: 'Attendance Management'

            // hideNavBar:true
        },
        {
            path:"/stockistmanagement",
            name:"Stockist",
            component:StockistManagement,
            access: 'Stockist Management'
        },
        {
            path:"/incentivemanagement",
            name:"Incentive",
            component:IncentiveManagement,
            access: 'Incentive Management'
        },
        {
            path:"/ordermanagement",
            name:"Order",
            component:OrderManagement,
            access: 'Order Management'

            // hideNavBar:true
        },
        {
            path:"/partymanagement",
            name:"Party",
            component:PartyManagement,
            access: 'Party Management'

            // hideNavBar:true
        },
        {
            path:"/productManagement",
            name:"Product ",
            component:ProductManagement,
            access: 'Product Management'

            // hideNavBar:true
        },
        {
            path:"/setManagement",
            name:"Set",
            component:SeriesManagement,
            access: 'Set Management'

            // hideNavBar:true
        },
        {
            path:"/routeplanManagement",
            name:"Route-Plan ",
            component:RoutePlanManagement,
            // hideNavBar:true
            access: 'Route Plan Management'

        },
        {
            path:"/collectionManagement",
            name:"Collection",
            component:CollectionManagement,
            // hideNavBar:true
            access: 'Collection Management'

        },
        {
            path:"/specimenManagement",
            name:"Specimen",
            component:SpecimenManagement,
            // hideNavBar:true
            access: 'Specimen Management'

        },
        {
            path:"/incentives",
            name:"Incentives",
            component:Incentives,
            access: 'Incentives'

            // hideNavBar:true
        },
        {
            path:"/leadManagement",
            name:"Lead Management",
            component:LeadManagement,
            // hideNavBar:true
            access: 'Lead Management'

        },
        {
            path: "/transporterManagement",
            name: "Transporter Management",
            component: TransporterManagement,
            access: 'Transporter Management'
        },
        {
            path: "/forecastReport",
            name: "Forecast Report",
            component: ForecastReport,
            access: 'Forecast Report'
        },
        {
            path: "/financialReport",
            name: "Financial Report",
            component: FinancialReport,
            access: 'Financial Report'
        },
        {
            path: "/pipelineReport",
            name: "Pipeline Report",
            component: PipelineReport,
            access: 'Pipeline Report'
        },
        {
            path: "/returnReasonReport",
            name: "Return Reason Report",
            component: ReturnReasonReport,
            access: "Return Reason Report"
        },
        {
            path: "/returnOrderLineItemReport",
            name: "Return Order Line Item Report",
            component: ReturnOrderLineItemReport,
            access: "Return Order Line Item Report"
        },
        {
            path:"/auditlogs",
            name:"Audit Logs",
            component:AuditLogs,
            // hideNavBar:true
            access: 'Audit Logs'

        },
        {
            path:"/attendanceReport",
            name:"Attendance Report",
            component:AttendanceReport,
            access: 'Attendance Report'

            // hideNavBar:true
        },
        {
            path:"/totalTimeWorkedReport",
            name:"Work Time Report",
            component:TotalTimeWorkedReport,
            access: 'Work Time Report'

            // hideNavBar:true
        },
        {
            path:"/workTimeSchoolDistributor",
            name:"Work Time Report ",
            component:WorkTimeSchoolDistributor,
            access: 'Attendance Report'

            // hideNavBar:true
        },
        {
            path:"/additionalData/workTypePicklist",
            name:"Work Type Picklist",
            component:WorkTypePicklist,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/product/seriesCategory",
            name:"Series",
            component:SeriesMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/partyType",
            name:"Party Type Picklist",
            component:PartyTypePicklist,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/boardMaster",
            name:"School Board Master",
            component:BoardMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/bookType",
            name:"Book Type Picklist",
            component:BookType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/classMaster",
            name:"Class Master",
            component:ClassMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/collectionType",
            name:"Collection Type",
            component:CollectionType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/contactPersonRoleType",
            name:"Contact Person Role Type",
            component:ContactPersonRole,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/datType",
            name:"DA Type",
            component:DaType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/feedbackMaster",
            name:"Feedback Master",
            component:FeedbackMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
       
        {
            path:"/additionalData/givenType",
            name:"Given Type",
            component:GivenType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/gradeMaster",
            name:"Grade Master",
            component:GradeMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/mediumMaster",
            name:"Medium Master",
            component:MediumMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/nextStep",
            name:"Next Step",
            component:NextType,
            access: 'Picklist'

            // hideNavBar:true
        },
       
        {
            path:"/additionalData/paymentType",
            name:"Payment Type",
            component:PaymentType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/recieveCollectionType",
            name:"Recieve Collection Type",
            component:RecieveCollection,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/routeVisitType",
            name:"Route Visit Type",
            component:RouteVisitType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/statusType",
            name:"Status Type",
            component:StatusType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/subjectMaster",
            name:"Subject Master",
            component:SubjectMaster,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/vehicleType",
            name:"Vehicle Type",
            component:VehicleType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/visitType",
            name:"Visit Type",
            component:VisitType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path: "/additionalData/visitTypeCheck",
            name: "Visit Type Check",
            component: VisitTypeCheck,
            access: 'Picklist'
        },
        {
            path:"/additionalData/workDone",
            name:"Work Done",
            component:WorkDone,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/expensePurpose",
            name:"Expense Purpose",
            component:ExpensePurpose,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/author",
            name:"Author",
            component:Author,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/language",
            name:"language",
            component:Language,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/documentListSchool",
            name:"documentListSchool",
            component:DocumentListSchool,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/expenseType",
            name:"expenseType",
            component:ExpenseType,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/deliveryStatus",
            name:"deliveryStatus",
            component:DeliveryStatus,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/leadStatus",
            name:"leadStatus",
            component:LeadStatus,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/leadSource",
            name:"leadSource",
            component:LeadSource,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/leadRating",
            name:"leadRating",
            component:LeadRating,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/schoolCategory",
            name:"schoolCategory",
            component:SchoolCategory,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/visitOutcomes",
            name:"visitOutcomes",
            component:VisitOutcome,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/returnReason",
            name:"returnReason",
            component:ReturnReason,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/productGroups",
            name:"productGroups",
            component:ProductGroup,
            access: 'Picklist'

            // hideNavBar:true
        },
        {
            path:"/additionalData/mittplusProductGroups",
            name:"mittplusProductGroups",
            component:MittplusProductGroup,
            access: 'Picklist'

            // hideNavBar:true
        },

        {
            path:"/partyUpdateApproval",
            name:"Party Update Approval",
            component:PartyUpdateApproval,
            access: 'Attendance Report'

            // hideNavBar:true
        },
        {
            path:"/orderTracking",
            name:"Order Tracking",
            component:TrackingManagement,
            access: 'Attendance Report'

            // hideNavBar:true
        },
        {
            path:"/specimenAllotment",
            name:"Specimen Allotment",
            component:SpecimenAllotment,
            access: 'Specimen Allotment'

            // hideNavBar:true
        },
        {
            path:"/returnOrder",
            name:"Return Order",
            component:ReturnOrder,
            access: 'Return Order'

            // hideNavBar:true
        },

        {
            path:"/orderReport",
            name:"Order Report",
            component:OrderReport,
            access: 'Order Report'

            // hideNavBar:true
        },
        {
            path:"/visitDetailReport",
            name:"Visit Detail Report",
            component:VisitDetailReport,
            access: 'Visit Detail Report'

            // hideNavBar:true
        },
        {
            path:"/visitSummarisedReport",
            name:"Visit Summarised Report",
            component:VisitSummarizedReport,
            access: 'Visit Summarised Report'

            // hideNavBar:true
        },
        {
            path: "/picklist",
            name: "Picklist",
            component: AllPicklist,
            access: "Picklist"
        }

    ]
}

export default routes;


// import React, { useState } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "../views/Login/index.js";
// import Dashboard from "../views/Dashboard/index.js";
// import UserManagement from "../views/UserManagement/index.js";
// import PrivateRoute from "../components/PrivateRoutes/index.js";

// const RouterComponent = () => {
// //   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route
//           path="/"
//           element={
//             <PrivateRoute>
//               <Dashboard /> 
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/usermanagement"
//           element={<PrivateRoute><UserManagement/></PrivateRoute>}
//         />
//       </Routes>
//     </Router>
//   );
// };

// export default RouterComponent;




// import Login from "../views/Login/index.js";
// import Dashboard from "../views/Dashboard/index.js";
// import { createBrowserRouter, useRoutes } from "react-router-dom";
// import UserManagement from "../views/UserManagement/index.js";
// import PrivateRoute from "../components/PrivateRoutes/index.js";
// // import Invite from "../views/invite/";


// // export default function Router() {
// //     const routes = useRoutes([
// //     {
// //       path: "/",
// //       element: <PrivateRoute>
// //           <Dashboard/>
// //         </PrivateRoute>
// //     },
// //     {
// //         path: "/login",
// //         element: <Login />,
// //         // redirect: user,
// //         pathTo: "/dashboard",
// //     }
// //     ,{
// //         path: "/usermanagement",
// //         element: <PrivateRoute><UserManagement/></PrivateRoute>
// //     }
//     // {
//     //   path: "/Login",
//     //   elem
//     // }
// // ]);

// // return routes;
// // }

// // import { Navigate, useRoutes } from 'react-router-dom';
// // // layouts
// // import DashboardLayout from './layouts/dashboard';
// // import SimpleLayout from './layouts/simple';
// // //
// // import BlogPage from './pages/BlogPage';
// // import ModuleManagement from './pages/DistributorOnboarding.js';
// // import LoginPage from './pages/LoginPage';
// // import Page404 from './pages/Page404';
// // import ProductsPage from './pages/ProductsPage';
// // import DashboardAppPage from './pages/DashboardAppPage';
// // import PrivateRoute from './components/private-routing/PrivateRoute';
// // import DepartmentRole from './pages/DepartmentRolePage';
// // import EmployeeOnboard from './pages/EmpoyeeOnboard';
// // import RetailorOnboard from './pages/RetailerOnboarding.';
// // import DistributorOnboard from './pages/DistributorOnboarding.js';

// // ----------------------------------------------------------------------

// export default function Router() {
//   const routes = useRoutes([
//     {
//       path: '/',
//       element: <Login />,
//     },
//     // {
//     //   path: '/dashboard',
//     //   element:<PrivateRoute> <DashboardLayout /> </PrivateRoute>,
//     //   children: [
//     //     { element: <Navigate to="/dashboard/app" />, index: true },
//     //     { path: 'app', element: <DashboardAppPage /> },
//     //     { path: 'onboard/distributor', element: <DistributorOnboard /> },
//     //     { path: 'onboard/retailor', element: <RetailorOnboard /> },
//     //     { path: 'module-management', element: <ModuleManagement /> },
//     //     { path: 'Empolyee-onboard', element: <EmployeeOnboard /> },
//     //     { path: 'products', element: <ProductsPage /> },
//     //     { path: 'blog', element: <BlogPage /> },
//     //     { path: '/dashboard/department-Role', element: <DepartmentRole/> },
//     //   ],
//     // },
//     // {
//     //   element: <SimpleLayout />,
//     //   children: [
//     //     { element: <Navigate to="/login" />, index: true },
//     //     { path: '404', element: <Page404 /> },
//     //     { path: '*', element: <Navigate to="/404" /> },
//     //   ],
//     // },
//     // {
//     //   path: '*',
//     //   element: <Navigate to="/404" replace />,
//     // },
//   ]);

//   return routes;
// }


// import React, { useState, useContext, useEffect } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "../views/Login/index.js";
// import Dashboard from "../views/Dashboard/index.js";
// import UserManagement from "../views/UserManagement/index.js";
// import PrivateRoute from "../components/PrivateRoutes/index.js";
// import { AuthContext } from "../components/contextAPI/ContextAPI.js";


// const RouterComponent = () => {
// //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// const { auth, setAuth} = useContext(AuthContext)

// useEffect(() => {
// console.log("authValue",auth)
// }, [auth])

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route
//           path="/"
//           element={
//             <PrivateRoute>
//               <Dashboard /> 
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/usermanagement"
//           element={<PrivateRoute><UserManagement/></PrivateRoute>}
//         />
//       </Routes>
//     </Router>
//   );
// };

// export default RouterComponent;
