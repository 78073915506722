import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import * as XLSX from "xlsx";

import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AttendanceModal from "../../components/modals/attendanceModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BulkAddLead from "../../components/modals/bulkAddLead";
import AddLeadModal from "../../components/modals/addLeadModal";
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from "dayjs";
import GetAppIcon from '@mui/icons-material/GetApp';


const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
height: calc(
  100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
);
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 512px;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function OrderReport() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [attendanceModal, setAttendanceModal] = useState(false)
const [attendanceModal1, setAttendanceModal1] = useState(false)
const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const newDate=new Date()
const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()
const yesterday = dayjs().subtract(1, 'day');

useEffect(() => {
  // setAccessChanges(rows);
if(!filter){
    getPartyLeadAdmin()

   
}
else if(filter){
  onFilterChange(searchTerm)
}
  }, [paginationModel.page]);
  // useEffect(()=>{

  //   formatDateForFilter(dayjs(newDate))

  // },[])


  const formatDateForFilter=(data)=>{
    console.log("DATAAAA",data.$d    )
    const timestamp = new Date(data.$d);
  const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}/${month}/${day}`;

const unixTimestamp = Math.floor(timestamp.getTime() / 1000);

setStartDate1(data)
}
  

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

  const getRowClassName = (params) => {
    // console.log("PARAMSSS",params)
    const deliveryDate = new Date(params.row.startTime); // Assuming deliveryDate is in Unix timestamp format
    const deliveryHour = deliveryDate.getHours();
    console.log("PARAMSSS",deliveryHour)
    // Add or remove classes based on time conditions
    if (deliveryHour < 10) {
      return "successRow"; // Before 10 AM
    } else {
      return "failureRow"; // After 10 AM
    }
  };



  const getPartyLeadAdmin= async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.orderReport({pageNumber:paginationModel.page});
      console.log("results", results);
      setRowCount(results.data1);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
  let result = [ {
    field: "ID",
    headerName: "Order ID",
    sortable: false,
    width: 150,
    filterable: false,

    filterOperators: stringOperators 
,
    editable: false,
    renderCell: (params) => {

      let name = params.row?.orderId||"N/A"

      return (

        <Tooltip title={name || "N/A"}>

          <Typography variant="inherit">{name}</Typography>

        </Tooltip>

      )

    },
  },
    {
      field: "name",
      headerName: "Employee Name",
      sortable: false,
      width: 150,
      filterOperators: stringOperators 
,
      editable: false,
      renderCell: (params) => {

        let name = params.row?.name||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "State",
        headerName: "State",
        sortable: false,
        filterable: false,

        width: 150,
        filterOperators: stringOperators 
  ,
        editable: false,
        renderCell: (params) => {
  
          let name = params.row?.State||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "District",
        headerName: "District",
        sortable: false,
        width: 150,
        filterOperators: stringOperators 
        ,
        filterable: false,
        editable: false,
        renderCell: (params) => {
  
          let name = params.row?.District||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    {
      field: "startTime",
      headerName: "Created At",
      width: 200,    
              filterable: false,

      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.createdAt?formatTimeStamp( params.row.createdAt):"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "PartyName",
        headerName: "Party Name",
        width: 220,
        // filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.schoolName?params.row.schoolName:params.row.DistributorName||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "productName",
        headerName: "Product Name",
        width: 220,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.nameSku||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "productCode",
        headerName: "Product Code",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.skuId||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "QTY",
        headerName: "QTY",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.QTY||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
     
      {
        field: "Amount",
        headerName: "Amount",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
            const name1 = +params.row?.Total||"N/A"
  
          let name=name1.toLocaleString('en-IN');
  
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Discount",
        headerName: "Discount",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Discount||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
    }
     
  ];


  return result
}

const exportData=async()=>{
  try{
   
    setLoading(true)
  
let results = await window.Platform.database.orderReport({filter:true,filterField:searchTerm?.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDay:startDate});
console.log("RESPONSE",results)
 
    let data = results?.data?.map((item,index) => {
  return{
S_No:index+1,
Order_ID:item.orderId,
Employee_Name:item.name,
State:item.State,
District:item.District,
Created_At:formatTimeStamp( item.createdAt),
Party_Name:item.schoolName?item.schoolName:item.DistributorName,
Product_Name:item.nameSku,
Product_Code:item.skuId,
QTY:item.QTY,
Amount:item.Total,
Discount:item.Discount


  }
    })
console.log(data)
               const ws = XLSX.utils.json_to_sheet(data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
              const filename = `exported-orderReport.xlsx`;
              XLSX.writeFile(wb, filename);
setLoading(false)
  
  }
  catch(err){
setLoading(false)
  console.log("error",err)
window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  

  }
  
  
    }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        
      </GridToolbarContainer>
    );
  }

  const onFilterChange= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.orderReport({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDay:startDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.orderReport({filterField:"date",pageNumber:paginationModel.page,startDay:startDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };

  const renderContent = () => {
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          // autoPageSize
        //   getRowClassName={getRowClassName}
          onFilterModelChange={(val)=>
              {
//           //       console.log("vvvv",val);
            
            
            
            if(val?.items[0]?.value?.length>0){
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
            } else if(!val.items[0]?.value   && startDate ){
              setSearchTerm(null)
              onFilterChange()
              console.log("CHECKING...1")

            }
            else{
              getPartyLeadAdmin()
              setSearchTerm(null)
              setFilter(false)
            }
//           //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
//           //     setSearchTerm(null)
//           //     fetchData()
//           //     console.log("CHECK1")
//           //   }
//           //   else if(!val.items?.value && endDate && startDate && territoryFilter){
//           //     setSearchTerm(null)
  
//           //     onFilterChange1()
//           //     console.log("checkterr")
//           //   }
//           //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
//           //     setSearchTerm(null)
//           //     console.log("check2")
//           //     onFilterChange1()
  
  
//           //   }
//           //   else{
//           //     setSearchTerm(null)
//           //     console.log("check2")
//           //     onFilterChange1()
//           //   }
          }
            
          }
          filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10]}
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };



  const startDay=()=>{
    setAttendanceModal(true)
  }
  
  const endDay=()=>{
    setAttendanceModal1(true)
  }

  const formatDate=async(data)=>{
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
  
  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
  setStartDate1(formattedDate)
  setStartDate(formattedDate)
  console.log("CHECK!!")
  console.log("CHECKENDDATE",endDate)
  
  }
  const finalDateRangeFilter=async(data)=>{
    try{
      setLoading(true)
      setFilter(true)
  
    const datePickerResponse = new Date(data);
  
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
    
    if (data){
    setEndDate(formattedDate)
    setEndDate1(formattedDate)
  }
      if(searchTerm){
      const response = await window.Platform.database.orderReport({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
       
    }
      else{
      const response = await window.Platform.database.orderReport({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      
      }
      setLoading(false)
  
    }
    catch(err){
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
  
      getPartyLeadAdmin()
    
    }
  }
  const clearDateFilter=async()=>{
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if(searchTerm){
      try{
      setLoading(true)
      const response = await window.Platform.database.orderReport({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
        
      setLoading(false)
    }
    catch(e){
      console.log(e)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      getPartyLeadAdmin()  
  
      }
    }
  else{
    setFilter(false)
    getPartyLeadAdmin()
  }
  }


  return (
    <>
  
      <StaffEditorPageContainer>
          {loading && <OpaqueLoading/>}
        <HeaderContainer>
          {/* <Typography variant="h5">Attendance Management</Typography> */}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Attendance Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date" maxDate={yesterday}  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} />
            </LocalizationProvider> 
             <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>
            </div>
            <Stack spacing={1} direction={"row"}>
          
<FabStyle variant="extended" color="primary" size='small'

onClick={()=>exportData()} disabled={!filter}

>

<GetAppIcon sx={{ mr: 1 }} />

Export

</FabStyle>
  </Stack>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>

    </>
  );
}
