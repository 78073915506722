import React, { useContext, useEffect } from "react";
import { ROLES } from "../../constants";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarBrand,
  Nav,
} from "reactstrap";

import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Fab, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AddCircle, ChargingStationRounded, Email, Home, ManageAccounts, ManageSearch, ManageSearchTwoTone, Sell, TrackChanges, Warehouse } from "@mui/icons-material";
import { AuthContext } from "../contextAPI/ContextAPI";

const StyledNavBar = styled(Navbar)`
  font-family: "Montserrat";
`;

const AccountNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  ${(props) =>
    props.height &&
    `
    height: ${props.height}px !important;
    width: ${props.height}px !important;
  `}
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

export const getNavItems = () => 
    [
            {
                title: 'User Management',
                icon: ManageAccounts,
                link: '/usermanagement',
                role: ['admin'], 
              },
              {
                title: 'Role Management',
                icon: ManageSearch,
                link: '/rolemanagement',
                role: ['admin'],
              },
              {
                title: 'Visits Management',
                icon: ManageSearch,
                link: '/visitmanagement',
                role: ['admin'],
              },
              {
                title: 'Attendance Management',
                icon: ManageSearch,
                link: '/attendancemanagement',
                role: ['admin'],
              },
            //   {
            //     title: 'Add User',
            //     icon: AddCircle,
            //     link: '/',
            //     role: ['admin'],
            //   },
              // {
              //   title: 'Tracking',
              //   icon: TrackChanges,
              //   link: '/tracking',
              //   role: ['user', 'admin'],
              // },
              // {
              //   title: 'Quotation Generation',
              //   icon: ManageSearchTwoTone,
              //   link: '/quotationgenerate',
              //   role: ['user', 'admin'],
              // },
              // {
              //   title: 'SO Generation',
              //   icon: ChargingStationRounded,
              //   link: '/sogeneration',
              //   role: ['user', 'admin'],
              // },
              // {
              //   title: 'Billing',
              //   icon: Sell,
              //   link: '/billing',
              //   role: ['user', 'admin'],
              // },
              // {
              //   title: 'Warehouse Management',
              //   icon: Warehouse,
              //   link: '/warehousemanagement',
              //   role: ['user', 'admin'],
              // },
        ];
        // break;
    //   case ROLES.ZONAL_HEAD:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "leaderboard",
    //         text: "Targets Management",
    //         path: "/targets-management",
    //       },
    //       {
    //         icon: "school",
    //         text: "Learning",
    //         path: "/learning",
    //       },
    //       {
    //         icon: "groups",
    //         text: "Merchants",
    //         path: "/merchants-details",
    //       },
    //     ];
    //     break;
    //   case ROLES.AREA_HEAD:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "groups",
    //         text: "Merchants",
    //         path: "/merchants-details",
    //       },
    //     ];
    //     break;
    //   case ROLES.CITY_HEAD:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "groups",
    //         text: "Merchants",
    //         path: "/merchants-details",
    //       },
    //     ];
    //     break;
    //   case ROLES.SALES_MANAGER:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "list",
    //         text: "Log Market Visits",
    //         path: "/log-market-visits",
    //       },
    //       {
    //         icon: "groups",
    //         text: "Merchants",
    //         path: "/merchants-details",
    //       },
    //     ];
    //     break;
    //   case ROLES.SERVICE_MANAGER:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "list",
    //         text: "Log Market Visits",
    //         path: "/log-market-visits",
    //       },
    //       {
    //         icon: "groups",
    //         text: "Merchants",
    //         path: "/merchants-details",
    //       },
    //     ];
    //     break;
    //   case ROLES.HME:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "leaderboard",
    //         text: "Targets Management",
    //         path: "/targets-management",
    //       },
    //       {
    //         icon: "school",
    //         text: "Learning",
    //         path: "/learning",
    //       },
    //       {
    //         icon: "log-market-visits",
    //         text: "Log Market Visits",
    //         path: "/log-market-visits",
    //       },
    //     ];
    //     break;
    //   case ROLES.SALES_MANAGER_ENGAGEMENT:
    //     results = [
    //       {
    //         icon: "manage_accounts",
    //         text: "User Management",
    //         path: "/user-management",
    //       },
    //       {
    //         icon: "supervised_user_circle",
    //         text: "FOS Management",
    //         path: "/fos-management",
    //       },
    //       {
    //         icon: "format_list_numbered",
    //         text: "Leads Management",
    //         path: "/leads-management",
    //       },
    //       {
    //         icon: "log-market-visits",
    //         text: "Log Market Visits",
    //         path: "/log-market-visits",
    //       },
        // ];
        // break;
//       default:
//         throw new Error("Invalid Role");
//     }
//     return results;
//   } else {
//     window.NotificationUtils && window.NotificationUtils.showError("Invalid User");
//   }
// };
const isMobile = window.innerWidth < 900;

const Dot = styled.span`
  position: relative;
  display: inline-block;
  margin: 0 8px;
  color: black;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-140%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #777;
  }
`;

const Header = (props) => {
    const { auth, setAuth} = useContext(AuthContext);
    const location = useLocation();

    useEffect(() => {
        console.log("checking location", location.pathname);
    }, [location])

    const navItems = getNavItems(props)
  
    const onClickLogout = async() => {
      try {
        await window.Platform.database.signOut();
        console.log(props)
        props.onAuthUserChanged && props.onAuthUserChanged();
      } catch (error) {
        window.NotificationUtils.showError("Something Went Wrong");
      }
    }
  
    const getUserName = () => {
      let name = `${props?.user?.username}
   
    `;
      let length = 15;
      if(name.length>length){
        name = name.substring(0,length - 3) + "...";
      }
      return name;
    }
  
    return (
      <StyledNavBar color="white" expand="lg">
        <NavbarBrand href="/dashboard" className="mr-auto nav-item">
          <Tooltip title="Home">

        <FabStyle color="primary" aria-label="add" size="small">
        <Home />
      </FabStyle>
          </Tooltip>
        </NavbarBrand>
        <Nav className="ml-auto" navbar >
          {navItems.map((navItem) => {
            console.log("current paths",auth?.history?.location?.pathname )
            return (  
              <li className="nav-item" key={navItem.link}>
                <NavLink
                  to={navItem.link}
                  
                  className={`nav-link${
                    location?.pathname.includes(navItem.link)
                      ? " active"
                      : ""
                  }`}
                >
              {navItem.title}  
                </NavLink>
                <Dot />
              </li>
            );
          })}
  
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              My Account
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem style={{ padding:"10px 12px",fontWeight: "600", color: "black" }}>
                <AccountNameContainer>
                  <StyledAvatar height={30} src={props.user?.profilePicURL || ""} />{" "}
                  {getUserName()}
                </AccountNameContainer>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{ fontWeight: "600", color: "black" }}
              >
                Notifications{" "}
               
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{ fontWeight: "600", color: "black" }}
                onClick={window.requestPasswordReset}
              >
                Change Password
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{ fontWeight: "600", color: "black" }}
                onClick={onClickLogout}
              >
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <AccountNameContainer style={{fontSize: "0.8em",
    marginLeft: "0.5em",
    color: "gray"}}> v1.0</AccountNameContainer>
  
        </Nav>
      </StyledNavBar>
    );
  };
  
  export default Header;
  