import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const isMobile = window.innerWidth < 900;


const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding:10px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom: 0px;
  }
`;
// const StyledContainer = styled.div`
//   /* Default styles */
//   width: 100%;
//   max-width: 960px;
//   margin: 0 auto;
  
//   /* Mobile styles */
//   @media (max-width: 600px) {
//     padding: 0 10px;
//   }
// `;
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;
  
  const InventoryItemsModal = ({ onClose, items }) => {
    
  const getItemColumns = () => {
    let result = [
      {
        field: "item",
        headerName: "Name",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "category",
        headerName: "Category",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "qty",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "ctn",
        headerName: "Carton",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "weight",
        headerName: "Weight",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "cbm",
        headerName: "Cubic Meter",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "unitOfAmount",
        headerName: "Unit of Amount",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        editable: true,
      },
      
    ];
  
  
    return result
  }
  
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport
            csvOptions={{
              fileName: "items-list",
            }}
          />
        </GridToolbarContainer>
      );
    }
  
    const renderContent = () => {
      return (
        <DataGridContainer>
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            className="payrollGrid"
            checkboxSelection={true}
            rows={items}
            columns={getItemColumns()}
            rowHeight={60}
            disableSelectionOnClick
            disableRowSelectionOnClick
            autoPageSize
            components={{
              Toolbar: CustomToolbar,
            }}
            pageSizeOptions={[10]}
          />
        </Box>
        </DataGridContainer>
      );
    };
  
    return (
      <Dialog open={true} onClose={onClose} maxWidth="lg">
        <ModalHeaderContainer>
          <Typography variant="h5">Items</Typography>
          <IconButton  onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        <DialogContent style={{ padding: "10px" }}>
          {renderContent()}
        </DialogContent>
      </Dialog>
    );
  };
  
  export default InventoryItemsModal;
  