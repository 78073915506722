import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Tabs,
  Tab,
  Autocomplete,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;

const TableContainer = styled.div`
  width: ${isMobile ? "100%" : "98%"};
  border-radius: 8px;
  margin-left: ${isMobile ? "0px" : "10px"};
  margin-bottom: ${isMobile ? "0px" : "10px"};
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const ProductMittplusModal = ({
  props,
  onClose,
  remarks,
  data,
  id,
  distributorId,
  fetchData,
  selectedTab,
  status,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    id: props?.id || "",
    productType: props?.productType || "",
    productName: props?.product_name || "",
    partnerName: props?.partnerName || "",
    invoice: props?.invoice || "",
    accessCodePurchased: props?.access_code_purchased || "",
    landingCost: props?.landing_cost || "",
    validityEnd: props?.validity_end_date,
    discountType: props?.discountType || '',
    class: props?.class || '',
    flatDiscount: props?.flatDiscount || '',
    maxDiscount: props?.maxDiscount || '',
    minDiscount: props?.minDiscount || '',
    });
  const [className, setClassName] = useState();

  const [selectedClass, setSelectedClass] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [mittplusProductGroups, setMittplusProductGroups] = useState([]);
  const [productGroup, setProductGroup] = useState();
  const [renderPartnerForm, setRenderPartnerForm] = useState(true);
  const [date, setDate] = useState(null);
  const [partnerProductId, setPartnerProductId] = useState();
  const [subscriptionProductId, setSubscriptionProductId] = useState();
  const [errors, setErrors] = useState({});

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getClass();
    getMittplusProductGroups();
    if (props?.productTypeName?.includes("Subscription")) {
      setRenderPartnerForm(false);
    } else {
      setRenderPartnerForm(true);
    }
    setProductGroup(props?.productType || "");

    const newDate = new Date(props?.validity_end_date);
    formatDate(dayjs(newDate));
  }, []);

  const productGroupHandler = (data) => {
    setForm({
      id: '',
      productType: '',
      productName: '',
      partnerName: '',
      invoice: '',
      accessCodePurchased: '',
      landingCost: '',
      validityEnd: '',
    });
    setProductGroup(data);
    if (data?.name?.includes("Partner")) {
      setRenderPartnerForm(true);
    } else {
      setRenderPartnerForm(false);
    }
  };

  const formatDate = (data) => {
    const timestamp = new Date(data.$d);
    const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
    setDate(data);

    changeHandler("validityEnd", unixTimestamp);
  };

  const changeHandler = (fieldName, value) => {
    let val = value;
    if (fieldName === "landingCost" || fieldName === "accessCodePurchased") {
      val = val.replace(/\D/g, "");
    }
    if (fieldName === "discountType") {
      if (value === "flat") {
        setForm({
          ...form,
          discountType: value,
          flatDiscount: "",
          minDiscount: "",
          maxDiscount: "",
        });
      } else if (value === "range") {
        setForm({
          ...form,
          discountType: value,
          minDiscount: "",
          maxDiscount: "",
          flatDiscount: "",
        });
      }
    }

    setForm((prevForm) => ({ ...prevForm, [fieldName]: val }));
  };

  const classHandler = (data) => {
    const arrOfClassIds = data?.map((proClass) => proClass.classId);
    const arrOfClassNames = data?.map((proClass) => proClass.className);
    changeHandler("class", arrOfClassIds);
    // changeHandler("className", arrOfClassNames);
    setSelectedClass(data);
  };

  const validateForm = (data) => {
    const errors = {};

    if (!dataGrid?.length) {

      if (!data.productName?.trim()) {
        errors.productName = "Product name is required";
      }

      if (!data.partnerName?.trim()) {
        errors.partnerName = "Partner name is required";
      }

      if (!data.invoice?.trim()) {
        errors.invoice = "Invoice is required";
      }

      if (!data.landingCost?.toString()?.trim()) {
        errors.landingCost = "Landing cost is required";
      }

      if (data.accessCodePurchased?.trim()) {
        if (!data.accessCodePurchased?.trim()) {
          errors.accessCodePurchased = "Access code purchased is required";
        }

        if (!data.class?.length) {
          errors.class = "Class is required";
        }

        if (!data.validityEnd?.toString()?.trim()) {
          errors.validityEnd = "Validity end is required";
        }
      }
    }

    return errors;
  };

  const renderContentSubscription = () => {
    return (
      <div style={{ overflowY: "auto" }}>
        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Product Name"
              value={form?.productName}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("productName", e.target.value)}
              required
              error={errors.productName ? true : false}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Partner Name"
              value={form?.partnerName}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("partnerName", e.target.value)}
              required
              error={errors.partnerName ? true : false}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Invoice"
              value={form?.invoice}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("invoice", e.target.value)}
              required
              error={errors.invoice ? true : false}
            />
          </FormControl>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>

            <Autocomplete
              multiple
              options={className || []}
              getOptionLabel={(option) => `${option?.className}` || ""}
              getOptionValue={(option) => option?.classId || ''}
              style={{ width: "100%" }}
              value={selectedClass || form.class}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                classHandler(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Class"}
                  required
                  variant="outlined"
                  error={errors.class ? true : false}
                />
              )}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Total Access Codes Purchased"
              value={form?.accessCodePurchased}
              type="text"
              fullWidth
              onChange={(e) =>
                changeHandler("accessCodePurchased", e.target.value)
              }
              required
              error={errors.accessCodePurchased ? true : false}
            />
          </FormControl>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Landing Cost"
              value={form?.landingCost}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("landingCost", e.target.value)}
              required
              error={errors.landingCost ? true : false}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Access Code Validity Period"
                value={date}
                sx={{ width: "100%" }}
                disabled={loading}
                format="YYYY/MM/DD"
                onChange={(data) => formatDate(data)}
                required
                error={errors.validityEnd ? true : false}
              />
            </LocalizationProvider>
          </FormControl>
        </Stack>
        <div style={{ overflowY: "auto" }}>
  <Stack direction={"row"} spacing={2} padding={2}>
    <FormControl component="fieldset" style={{ width: isMobile ? "100%" : "50%" }}>
      <FormLabel component="legend">Discount Type</FormLabel>
      <RadioGroup
        row
        value={form.discountType}
        onChange={(e) => changeHandler("discountType", e.target.value)}
      >
        <FormControlLabel value="flat" control={<Radio />} label="Flat Discount" />
        <FormControlLabel value="range" control={<Radio />} label="Discount Range" />
      </RadioGroup>
    </FormControl>
  </Stack>

  {form.discountType === "flat" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Discount Amount"
        type="number"
        value={form.flatDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("flatDiscount", e.target.value)}
        required
      />
    </Stack>
  )}

  {form.discountType === "range" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Minimum Range"
        type="number"
        value={form.minDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("minDiscount", e.target.value)}
        required
      />
      <TextField
        label="Maximum Range"
        type="number"
        value={form.maxDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("maxDiscount", e.target.value)}
        required
      />
    </Stack>
  )}

</div>
        {status && (
          <Stack
            direction={"row"}
            spacing={2}
            padding={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button variant="contained" onClick={() => handleAdd(form)}>
              Add
            </Button>
          </Stack>
        )}

        {status && (
          <Stack direction={"row"} spacing={2} padding={2}>
            <DataGrid
              className="payrollGrid"
              style={{ height: !dataGrid?.length ? "200px" : "auto" }}
              density="compact"
              rows={dataGrid || []}
              columns={getColumnsSubscription()}
              rowHeight={60}
              disableSelectionOnClick
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          </Stack>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            // disabled={!dataGrid.length}
            onClick={
              status ? () => onsubmit(form) : () => editProductMittplus(form)
            }
          >
            {status ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    );
  };

  const renderContentPartner = () => {
    return (
      <div style={{ overflowY: "auto" }}>
        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Product Name"
              value={form?.productName}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("productName", e.target.value)}
              required
              error={errors.productName ? true : false}
            />
          </FormControl>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Partner Name"
              value={form?.partnerName}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("partnerName", e.target.value)}
              required
              error={errors.partnerName ? true : false}
            />
          </FormControl>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Invoice"
              value={form?.invoice}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("invoice", e.target.value)}
              required
              error={errors.invoice ? true : false}
            />
          </FormControl>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Landing Cost"
              value={form?.landingCost}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("landingCost", e.target.value)}
              required
              error={errors.landingCost ? true : false}
            />
          </FormControl>
        </Stack>
        <div style={{ overflowY: "auto" }}>
  <Stack direction={"row"} spacing={2} padding={2}>
    <FormControl component="fieldset" style={{ width: isMobile ? "100%" : "50%" }}>
      <FormLabel component="legend">Discount Type</FormLabel>
      <RadioGroup
        row
        value={form.discountType}
        onChange={(e) => changeHandler("discountType", e.target.value)}
      >
        <FormControlLabel value="flat" control={<Radio />} label="Flat Discount" />
        <FormControlLabel value="range" control={<Radio />} label="Discount Range" />
      </RadioGroup>
    </FormControl>
  </Stack>

  {form.discountType === "flat" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Discount Amount"
        type="number"
        value={form.flatDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("flatDiscount", e.target.value)}
        required
      />
    </Stack>
  )}

  {form.discountType === "range" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Minimum Range"
        type="number"
        value={form.minDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("minDiscount", e.target.value)}
        required
      />
      <TextField
        label="Maximum Range"
        type="number"
        value={form.maxDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("maxDiscount", e.target.value)}
        required
      />
    </Stack>
  )}

</div>
        <Stack
          direction={"row"}
          spacing={2}
          padding={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {status && (
            <Button variant="contained" onClick={() => handleAdd(form)}>
              Add
            </Button>
          )}
        </Stack>

        {status && (
          <Stack direction={"row"} spacing={2} padding={2}>
            <DataGrid
              className="payrollGrid"
              style={{ height: !dataGrid.length ? "200px" : "auto" }}
              density="compact"
              rows={dataGrid || []}
              columns={getColumnsPartner()}
              rowHeight={60}
              disableSelectionOnClick
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          </Stack>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Button
            variant="contained"
            disabled={!dataGrid.length}
            onClick={() => onsubmit(form)}
          >
            Create
          </Button> */}

          <Button
            variant="contained"
            // disabled={!dataGrid.length}
            onClick={
              status ? () => onsubmit(form) : () => editProductMittplus(form)
            }
          >
            {status ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    );
  };

  const getMittplusProductGroups = async (data) => {
    let results = await window.Platform.database.getMittplusProductGroups();
    setMittplusProductGroups(results.data);
    const defaultProductGroupId = results.data.find((productGroup) => {
      if (productGroup?.name?.includes("Partner")) {
        return productGroup.id;
      }
    });

    const subscriptionProductGroupId = results.data.find((productGroup) => {
      if (productGroup?.name?.includes("Subscription")) {
        return productGroup.id;
      }
    });

    if (props?.productTypeName?.includes("Subscription")) {
      setProductGroup(subscriptionProductGroupId);
    } else {
      setProductGroup(defaultProductGroupId);
    }
    setPartnerProductId(defaultProductGroupId);
    setSubscriptionProductId(subscriptionProductGroupId);
  };

  const handleDeleteData = async (id) => {
    let res = dataGrid.filter((el) => {
      return el.id != id;
    });
    setDataGrid(res);
  };

  const getColumnsSubscription = () => {
    let result = [
      {
        field: "productName",
        headerName: "Product Name",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let productName = params.row.productName || "N/A";
          return (
            <Tooltip title={productName || "N/A"}>
              <Typography variant="inherit">{productName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "partnerName",
        headerName: "Partner Name",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let productName = params.row.partnerName || "N/A";
          return (
            <Tooltip title={productName || "N/A"}>
              <Typography variant="inherit">{productName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "invoice",
        headerName: "Invoice",
        width: 180,
        sortable: false,
        renderCell: (params) => {
          let invoice = params.row.invoice || "N/A";
          return (
            <Tooltip title={invoice || "N/A"}>
              <Typography variant="inherit">{invoice}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "class",
        headerName: "Class",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          const filteredClass = className.filter(item => params.row.class.includes(item.classId));
          return (
            <Tooltip>
              <Typography variant="inherit">{filteredClass?.map(item => item.className).join(', ')}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalAccessCodePurchased",
        headerName: "Total Access Code Purchased",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let totalAccessCodePurchased =
            params.row.accessCodePurchased || "N/A";
          return (
            <Tooltip title={totalAccessCodePurchased || "N/A"}>
              <Typography variant="inherit">
                {totalAccessCodePurchased}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "landingCost",
        headerName: "Landing Cost",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let landingCost = params.row.landingCost || "N/A";
          return (
            <Tooltip title={landingCost || "N/A"}>
              <Typography variant="inherit">{landingCost}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "accessCodeValidityPeriod",
        headerName: "Access Code Validity Period",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let accessCodeValidityPeriod = params.row.validityEnd || "N/A";
          return (
            <Tooltip title={accessCodeValidityPeriod || "N/A"}>
              <Typography variant="inherit">
                {accessCodeValidityPeriod}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "discountType",
        headerName: "discountType",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let newDiscountType = params.row.discountType || "N/A";
          return (
            <Tooltip title={newDiscountType || "N/A"}>
              <Typography variant="inherit">
                {newDiscountType}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "flatDiscount",
        headerName: "flatDiscount",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let newFlatDiscount = params.row.flatDiscount || "N/A";
          return (
            <Tooltip title={newFlatDiscount || "N/A"}>
              <Typography variant="inherit">
                {newFlatDiscount}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "maxDiscount",
        headerName: "maxDiscount",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let newMaxDiscount = params.row.maxDiscount || "N/A";
          return (
            <Tooltip title={newMaxDiscount || "N/A"}>
              <Typography variant="inherit">
                {newMaxDiscount}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "minDiscount",
        headerName: "minDiscount",
        width: 220,
        sortable: false,
        renderCell: (params) => {
          let newMinDiscount = params.row.minDiscount || "N/A";
          return (
            <Tooltip title={newMinDiscount || "N/A"}>
              <Typography variant="inherit">
                {newMinDiscount}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => handleDeleteData(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];

    return result;
  };

  const getColumnsPartner = () => {
    let result = [
      {
        field: "productName",
        headerName: "Product Name",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let productName = params.row.productName || "N/A";
          return (
            <Tooltip title={productName || "N/A"}>
              <Typography variant="inherit">{productName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "partnerName",
        headerName: "Partner Name",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let productName = params.row.partnerName || "N/A";
          return (
            <Tooltip title={productName || "N/A"}>
              <Typography variant="inherit">{productName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "invoice",
        headerName: "Invoice",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let invoice = params.row.invoice || "N/A";
          return (
            <Tooltip title={invoice || "N/A"}>
              <Typography variant="inherit">{invoice}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "landingCost",
        headerName: "Landing Cost",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let landingCost = params.row.landingCost || "N/A";
          return (
            <Tooltip title={landingCost || "N/A"}>
              <Typography variant="inherit">{landingCost}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "discountType",
        headerName: "discountType",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let newDiscountType = params.row.discountType || "N/A";
          return (
            <Tooltip title={newDiscountType || "N/A"}>
              <Typography variant="inherit">{newDiscountType}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "flatDiscount",
        headerName: "flatDiscount",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let newflatDiscount = params.row.flatDiscount || "N/A";
          return (
            <Tooltip title={newflatDiscount || "N/A"}>
              <Typography variant="inherit">{newflatDiscount}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "maxDiscount",
        headerName: "maxDiscount",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let newmaxDiscount = params.row.maxDiscount || "N/A";
          return (
            <Tooltip title={newmaxDiscount || "N/A"}>
              <Typography variant="inherit">{newmaxDiscount}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "minDiscount",
        headerName: "minDiscount",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let newminDiscount = params.row.minDiscount || "N/A";
          return (
            <Tooltip title={newminDiscount || "N/A"}>
              <Typography variant="inherit">{newminDiscount}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => handleDeleteData(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];

    return result;
  };

  const getClass = async (data) => {
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({
      ...obj,
      id: index + 1,
    }));

    setClassName(jsonArrayWithId);
    const classIds = props?.class?.split(",");
    const filteredArrayClass = jsonArrayWithId.filter((item) =>
      classIds?.includes(item.classId)
    );
    const classNames = props?.className?.split(",");
    if (classIds) {
      setSelectedClass(filteredArrayClass);
    }
    setForm((prev) => {
      return {
        ...prev,
        class: classIds,
      };
    });
  };

  const onsubmit = async (data) => {
    try {
      setLoading(true);
      await window.Platform.database.addMittplusProduct({
        orders: dataGrid,
      });

      window.NotificationUtils.showSuccess("Products added successfully!");
      setLoading(false);
      onClose();
      fetchData();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const editProductMittplus = (data) => {
    let pushItem;
    if (renderPartnerForm) {
      pushItem = {
        ...data,
        productType: partnerProductId.id,
      };
    } else {
      pushItem = {
        ...data,
        productType: subscriptionProductId.id,
      };
    }
    setDataGrid([...dataGrid, pushItem]);
    onUpdate(pushItem);
  };

  const onUpdate = async (data) => {
    try {
      setLoading(true);
      await window.Platform.database.updateMittplusProduct(data);
      window.NotificationUtils.showSuccess("Products updated successfully!");
      setLoading(false);
      onClose();
      fetchData();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAdd = (data) => {
    debugger
    setLoading(true);
    const errors = validateForm(data);
    setErrors(errors);
    if (Object.keys(errors)?.length === 0) {
      let pushItem;
      if (renderPartnerForm) {
        pushItem = {
          ...data,
          id: dataGrid?.length + 1,
          productType: partnerProductId.id,
        };
      } else {
        pushItem = {
          ...data,
          id: dataGrid?.length + 1,
          productType: subscriptionProductId.id,
        };
      }
      setDataGrid([...dataGrid, pushItem]);
      setSelectedClass([]);
      setForm({
        id: "",
        productType: "",
        productName: "",
        partnerName: "",
        invoice: "",
        accessCodePurchased: "",
        landingCost: "",
        validityEnd: "",
      });
      setLoading(false);
    } else {
      window.NotificationUtils.showWarning(
        "Please fill all the required fields."
      );
      setLoading(false);
    }
  };

  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}>
      {loading && <OpaqueLoading />}
      <ModalHeaderContainer style={{ marginBottom: 2, paddingBottom: 3 }}>
        <Typography variant={isMobile ? "h6" : "h5"}>
          {status ? "Add Mittplus Product" : "Edit Mittplus Product"}
          <Tooltip title="Order Id">{id}</Tooltip>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </ModalHeaderContainer>
      <TableContainer>
        <TabsContainer>
          <StyledTabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Product Details " />
          </StyledTabs>
          <Stack direction={"row"} spacing={2} padding={2}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Product Group</InputLabel>
              <Select
                labelId={`product-group`}
                label="Product Group"
                value={productGroup || ""}
                onChange={(e) => productGroupHandler(e.target.value)}
                inputProps={{ readOnly: props?.productType ? true : false }}
              >
                {mittplusProductGroups?.map((option, optionIndex) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {renderPartnerForm
            ? renderContentPartner()
            : renderContentSubscription()}
        </TabsContainer>
      </TableContainer>
    </Dialog>
  );
};

export default ProductMittplusModal;
