import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';

import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

import "./orderLineItemModal.css";
import LogoMittsure from '../Logo1.png';

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const OrderLineItemModal = ({ onCancel ,data, props }) => {
    const [dataSet, setDataSet] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [seriesNames,setSeriesNames]=useState({})

    useEffect(() => {
      const obj = props?.find((item) => item.orderId === data.id);

      const groupedData = {};
      let a={}
      

      data.data.forEach(item => {
        a[item.series]=item.seriesName
        const seriesName = item.series ? item.series : '';
        if (!groupedData[seriesName]) {
          groupedData[seriesName] = [];
        }
        groupedData[seriesName].push(item);
      });
      setSeriesNames(a)

      setDataSet(obj);
      setFilteredData(groupedData);
    }, [])

    // function titleCase(value){
    //   let a=value.split("")
    //   a[0] = a[0].toUpperCase()
    //   return a.join("")
      
    // }

    function titleCase(value) {
      return value
        .split(" ") 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" "); 
    }

    const formatDateToIndian = (dateString) => {
      const date = new Date(dateString);
      
      const day = String(date.getDate()).padStart(2, '0'); 
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = String(date.getFullYear()); 
      return `${day}-${month}-${year}`;
    };
    
    const formattedDate = formatDateToIndian(dataSet.createdAt);
    

    const downloadInvoice = () => {
      
      const date = new Date();
      const currentYear = date.getFullYear();
      const nextYear = (currentYear + 1).toString();
      const upcomingYear = nextYear.slice(-2);
    
      const tableSections = Object.keys(filteredData).map(seriesName => {
        const name = seriesName || '';
    
        const tableRows = filteredData[seriesName].map(item => `
          <tr>
            <td style="width: 8%">${item?.QTY || ''}</td>
            <td style="width: 16%">${item?.sku_code || ''}</td>
            <td style="width: 40%">${item?.nameSku || item?.product_name || ''}</td>
            <td style="width: 18%">${item?.className || ''}</td>
            <td style="width: 9%">${item?.Price || ''}</td>
            <td style="width: 9%">${item?.Price * item?.QTY || ''}</td>
          </tr>
        `).join('');
    
        const totalSum = filteredData[seriesName]
          .reduce((sum, item) => sum + (item?.Price || 0) * (item?.QTY || 0), 0);
  
        return `
          <div class="table-section" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <h4 style="text-align: left;"><b>${titleCase(seriesNames[name])}</b></h4>
            <table border="1" style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="width: 8%">Qty.</th>
                  <th style="width: 16%">Codes</th>
                  <th style="width: 40%">Name</th>
                  <th style="width: 18%">Class</th>
                  <th style="width: 9%">Price</th>
                  <th style="width: 9%">Amount</th>
                </tr>
              </thead>
              <tbody>
                ${tableRows}
              </tbody>
              <tfoot>
                <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                  <td><b>Sub Total:</b></td>
                  <td><b>${totalSum}</b></td>
                </tr>
                <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                  <td><b>Discount:</b></td>
                  <td><b>${dataSet.seriesDiscount?JSON.parse(dataSet.seriesDiscount)[name] || 0 : 0}%</b> </td>
                </tr>
                <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                  <td><b>Net Amount:</b></td>
                  <td><b>${(totalSum - (totalSum * (dataSet.seriesDiscount ? (JSON.parse(dataSet.seriesDiscount)[name] || 0) : 0) / 100)).toFixed(2)}</b> </td>
                </tr>
              </tfoot>
            </table>
          </div>
        `;
      }).join('');
    
      const htmlContent = `
        <div>
          <style>
            body {
              font-size: 15px;
            }
            h4, h5 {
              font-size: 16px;
            }
            table {
              font-size: 15px;
              page-break-after: auto;
            }
            .table-section {
              page-break-inside: avoid;
            }
            .totals {
              text-align: right;
              margin-right: 20px;
              margin-top: 20px;
            }
          </style>
          <div style="display: flex; justify-content: space-between; align-items: center; margin: 20px;">
            <div class="logo">
              <img src="${LogoMittsure}" alt="Logo" style="width: 150px; height: auto;" />
            </div>
            <div>
              <p>Date: ${formattedDate}</p>
              <p>SO #: ${dataSet.so_id || "N/A"}</p>
            </div>
          </div>
          <div class="main-heading" style="text-align: center; margin-top: 10px;">
            <h5><b>MittSure Technologies LLP</b></h5>
            <h4><b>ORDER FORM - EM - PAN INDIA (${currentYear}-${upcomingYear})</b></h4>
          </div>
          <div class="table-section" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
              <tbody>
                <tr>
                  <td><b>Bill To:</b> ${dataSet?.schoolName || ''}</td>
                  <td><b>Ship To:</b> ${dataSet?.Address || ''}</td>
                </tr>
                <tr>
                  <td><b>School Name:</b> ${dataSet?.schoolName || ''}</td>
                  <td><b>City:</b> ${dataSet?.state || ''}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td><b>Transport/Travels:</b> ${dataSet?.transporter_name || ''}</td>
                  <td><b>OTP Consent:</b> ${dataSet?.otpConsent || ''}</td>
                </tr>
                <tr>
                  <td><b>RM:</b> ${dataSet?.name || ''}</td>
                  <td><b>RM Contact:</b> ${dataSet?.mobno || ''}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          ${tableSections}
          <div class="totals">
            <p><b>Total: ${dataSet.originalAmount || "N/A"}</b></p>
            <p><b>Net to Pay: ${dataSet.totalAmount || "N/A"}</b></p>
          </div>
         <div style="margin: 20px; width: 100%;">
  <h4 style="text-align: left;"><b>Add-On Products</b></h4>
  <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
    <thead>
      <tr style="background-color: #f2f2f2;">
        <th style="padding: 8px; text-align: center; border: 1px solid #ddd;">S no.</th>
        <th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Name</th>
      </tr>
    </thead>
    <tbody>
      ${
        JSON.parse(dataSet.addOnProduct || '[]')
          .map(
            (product, ind) =>
              `<tr>
                 <td style="padding: 8px; text-align: center; border: 1px solid #ddd;">${ind + 1}</td>
                 <td style="padding: 8px; text-align: center; border: 1px solid #ddd;">${product}</td>
               </tr>`
          )
          .join('')
      }
    </tbody>
  </table>
</div>
      `;
    
      const element = document.createElement('div');
      element.innerHTML = htmlContent;
      document.body.appendChild(element);
    
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'Order_form.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
      };
      html2pdf().set(options).from(element).toPdf().save('Order_form.pdf').then(() => {
        document.body.removeChild(element);
      });
    };
    
    

const getColumns = () => {
  let result = [
    {
      field: "itemName",
      headerName: "Item Name",
      sortable: false,
      width: 250,
      
      editable: false,
      renderCell: (params) => {
        let name = params.row?.nameSku || params.row?.product_name || "N/A";

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "QTY",
      headerName: "Quantity",
      width: 120,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.QTY||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "Price",
        headerName: "Unit Price",
        width: 120,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.Price||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Total",
        headerName: "Total Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Total||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
     

 
  ];


  return result
}



const renderContent = () => {
  return (
      <>
        <DataGrid
          className="payrollGrid"
          sx={{ height: isMobile ? "555px" : "500px"}}
          density="compact"
          rows={data.data}
          columns={getColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
        />
        <Button variant="contained" color="primary"
          onClick={()=> downloadInvoice()} sx={{ mt: 2, borderRadius: '10px' }}>
          <DownloadIcon sx={{ mr: 1 }}  />
          Download Order Form
        </Button>
      </>
  );
};
    
    // console.log("customerdata", loading)
  
  
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px",minWidth:isMobile?350:750,width:isMobile?350:750
      },
    }}>
    <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Order Line Items {/*- <Tooltip title="Order Id">{"("+ data.id+")"}</Tooltip>*/}</Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default OrderLineItemModal;
  