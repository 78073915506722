import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Fab
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { isEmail } from "validator";
import styled from "styled-components";
// import { AuthContext } from "../../components/contextAPI/ContextAPI";
import { LOCAL_STORAGE_KEYS } from "../../constants";
import { AuthContext } from "../../components/contextAPI/ContextAPI";

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;
const CenterContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

const CenterRow = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
`;

const InputFieldContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LoginCard = styled(Card)`
  width: 100%;
  border-radius: 4px;
`;

const StyledCardContent = styled(CardContent)`
  padding: 20px !important;
`;

const Logo = styled.img`
  height: 60px;
  width: 70%;
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledForgotPassword = styled(Typography)`
  cursor: pointer;
`;

const StyledHeading = styled(Typography)`
  font-weight: bold;
`;

const Login = (props) => {
  const { auth, setAuth} = useContext(AuthContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let contextState={...auth}

  useEffect(()=> {
    // window.NotificationUtils && window.NotificationUtils.showSuccess("LOGIN")
    // console.log(window.NotificationUtils)
  },[])


  const checkForErrors = () => {
    if (!email || !password) {
      window.NotificationUtils.showError(
        "Please enter a valid email and password"
      );
      return true;
    }
  };

  const onClickLogin = async () => {
    // console.log("hello")
    var hasErrors = checkForErrors();
    if (hasErrors) {
      return;
    }
    try {
      contextState.loading = true;
      // console.log(contextState)
      console.log(window.Platform)
      let userResults = await window.Platform.database.login({ email, password });
      
      setAuth(userResults.attributes)
      contextState.loggedIn=true
      console.log("logged in", userResults);
      if(userResults.status === true){
        window.NotificationUtils.showError(userResults.message);
      }
      contextState.token= userResults.token
      contextState.user=userResults.user
      contextState.email=userResults.user.email
      setAuth(contextState)
      if (userResults) {
        // window.location.href = "/"
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.STORED_USER_DATA,  JSON.stringify(userResults))
        window.localStorage.setItem("token",userResults.token)
        props.onAuthUserChanged && props.onAuthUserChanged(userResults);
        window.location.reload()
      }
      console.log("auuto", auth)
      window.NotificationUtils.showSuccess("Logged In Successfully");
    } catch (error) {
      // if (error && error.code === 101) {
      //   window.NotificationUtils.showError("Invalid Username/Password");
      // } else {
        console.error(error)
        // window.NotificationUtils.showError(error.message);
      // }
    }
    finally {
      contextState.loading = false;
    }
  };

  const onClickForgotPassword = async () => {
    try {
      // if (!email || !isEmail(email)) {
      //   window.NotificationUtils.showError("Please enter a valid email address");
      //   return;
      // }
      let response = await window.requestPasswordReset(email);
      window.NotificationUtils.showSuccess("Password reset email sent successfully");
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  const renderLoginCard = () => {
    return (
      <LoginCard raised>
        <StyledCardContent>
          <HeaderContainer>
            <StyledHeading variant="h5" align="left">
              Login
            </StyledHeading>
          </HeaderContainer>
          <InputFieldContainer>
            <TextField
              fullWidth={true}
              label="Username"
              placeholder="someone@example"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e && e.key && e.key === "Enter") {
                  onClickLogin();
                }
              }}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e && e.key && e.key === "Enter") {
                  onClickLogin();
                }
              }}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            {/* <StyledForgotPassword
              color="secondary"
              onClick={onClickForgotPassword}
              align="right"
              variant="subtitle2"
            >
              Forgot Password ?
            </StyledForgotPassword> */}
          </InputFieldContainer>
          <LoginButtonContainer>
            {/* <Button
              onClick={onClickLogin}
              size="large"
              variant="contained"
              color="primary"
            >
              Login
            </Button> */}
            <FabStyle variant="extended" color="primary" 
             onClick={onClickLogin}
            //  size="large"
            //  variant="contained"
            //  color="primary" 
             >
  {/* <SaveAlt sx={{ mr: 1 }} /> */}
  Login
</FabStyle>
          </LoginButtonContainer>
        </StyledCardContent>
      </LoginCard>
    );
  };

  const renderLogo = () => {
    return <Logo src="./logo1.png" />;
  };

  return (
    <CenterContent>
      <CenterRow>
        {renderLogo()}
        {renderLoginCard()}
      </CenterRow>
    </CenterContent>
  );
};

export default Login;
