// project import
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from '../../third-party/SimpleBar';
import { Button, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
// import AuthContextProvider from '../../contextAPI/ContextAPI';
// import React, { useContext, useEffect, useState } from "react";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerAction = styled(ListItem)`
  gap: 10px;
  border-left: 5px solid transparent;
  transition: all 0.2s;

  &:hover,
  &.active {
    border-left: 5px solid white;
    ${(props) => props.isMobile && "border-radius: 10px;"}
    background: ${(props) => (props.isMobile ? "black" : "#F6F7FA")} !important;

    i,
    span {
      color: ${(props) => (props.isMobile ? "black" : "black")} !important;
    }
  }
`;
const DrawerItemIcon = styled.i`
  color: black;
`;
const DrawerItemText = styled(ListItemText)`
  margin-left: 16;

  color: black;
`;

const DrawerContent = (props) => 
{
  // const { auth, setAuth} = useContext(AuthContextProvider)
  const navigation = useNavigate()

  const onClickLogout = async () => {
    try {
      console.log('signedout')
      await window.Platform.database.signout();
      localStorage.clear();
  navigation('/login')
  props.onAuthUserChanged()
      // auth.onAuthUserChanged && auth.onAuthUserChanged();
    } catch (error) {
      window.NotificationUtils.showError("Something Went Wrong");
      console.error(error.message)
    }
  };
return (


  < >
    <Navigation moduleAccess={props.moduleAccess} />
    {/* <NavCard /> */}
    <List style={{ marginBottom: 10, width: "100%" }}>
            <Tooltip title="Log Out" placement="left">
            <DrawerAction
              // isMobile={isMobile}
              className="nav-link"
              onClick={onClickLogout}
              style={{ cursor: "pointer" }}
            >
              <DrawerItemIcon className="icon material-icons">
                {"power_settings_new"}
              </DrawerItemIcon>

           <DrawerItemText primary={"Logout"} />
            </DrawerAction>
            </Tooltip>
          </List>
  </>
)}

export default DrawerContent;
