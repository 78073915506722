import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Typography, Button, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Add } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import AddTransporterModal from "../../components/modals/addTransporterModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import BulkAddTransporter from "../../components/modals/BulkAddTransporter";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative;`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};
  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

export default function TransporterManagement() {

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [transporterModal, setTransporterModal] = useState(false);
  const [editTransporterModal, setEditTransporterModal] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [rowCount, setRowCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
    const [bulkTransporter, setBulkTransporter] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 11,
  });

  useEffect(() => {
    getTransporters();
  }, [paginationModel.page]);

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(timestampFromDB).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const getTransporters = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.getTransporters({pageNumber: paginationModel.page});
      setRowCount(results.data1);

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  // const deleteTransporter = async (item) => {
  //   try {
  //     setLoading(true);
  //     await window.Platform.database.deleteTransporter({ id: item.transporterId });
  //     getTransporters();
  //     setLoading(false);
  //     window.NotificationUtils.showSuccess("Transporter deleted successfully!");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteTransporter = async (item) => {
    try {
      const confirmation = window.confirm(`Are you sure you want to delete transporter ?`);
      if (confirmation) {
        setLoading(true);
        await window.Platform.database.deleteTransporter({ id: item.transporterId });
        getTransporters();
        setLoading(false);
        window.NotificationUtils.showSuccess("Transporter deleted successfully!");
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleEdit = (data) => {
    setDataModal(data);
    setEditTransporterModal(true);
    setTransporterModal(false);
  };

  const getTransporterColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "transporterName",
        headerName: "Transporter Name",
        sortable: false,
        width: 200,
        filterOperators: stringOperators,
        editable: false,
        valueGetter: (params) => {
          return (params.row?.transporter_name || "N/A");
        },
        renderCell: (params) => {
          let transporterName = params.row?.transporter_name || "N/A";
          return (
            <Tooltip title={transporterName || "N/A"}>
              <Typography variant="inherit">{transporterName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "completeAddress",
        headerName: "Complete Address",
        width: 350,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.complete_address || "N/A");
        },
        renderCell: (params) => {
          let completeAddress = params.row.complete_address || "N/A";
          return (
            <Tooltip title={completeAddress || "N/A"}>
              <Typography variant="inherit">{completeAddress}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "pinCode",
        headerName: "PIN code",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.pin_code || "N/A");
        },
        renderCell: (params) => {
          let pinCode = params.row.pin_code || "N/A";
          return (
            <Tooltip title={pinCode || "N/A"}>
              <Typography variant="inherit">{pinCode}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "state",
        headerName: "State",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.state || "N/A");
        },
        renderCell: (params) => {
          let state = params.row.state || "N/A";
          return (
            <Tooltip title={state || "N/A"}>
              <Typography variant="inherit">{state}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.phone || "N/A");
        },
        renderCell: (params) => {
          let phone = params.row.phone || "N/A";
          return (
            <Tooltip title={phone || "N/A"}>
              <Typography variant="inherit">{phone}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        width: 250,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.email || "N/A");
        },
        renderCell: (params) => {
          let email = params.row.email || "N/A";
          return (
            <Tooltip title={email || "N/A"}>
              <Typography variant="inherit">{email}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name ",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.contact_person_name || "N/A");
        },
        renderCell: (params) => {
          let contactPersonName = params.row.contact_person_name || "N/A";
          return (
            <Tooltip title={contactPersonName || "N/A"}>
              <Typography variant="inherit">{contactPersonName}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "contactPersonNumber",
        headerName: "Contact Person Number",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.contact_person_number || "N/A");
        },
        renderCell: (params) => {
          let contactPersonNumber = params.row.contact_person_number || "N/A";
          return (
            <Tooltip title={contactPersonNumber || "N/A"}>
              <Typography variant="inherit">{contactPersonNumber}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "gst",
        headerName: "GST",
        width: 200,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.gst || "N/A");
        },
        renderCell: (params) => {
          let gst = params.row?.gst || "N/A";
          return (
            <Tooltip title={gst || "N/A"}>
              <Typography variant="inherit">{gst}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "servingStates",
        headerName: "Serving States",
        width: 250,
        filterable: true,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.serving_states || "N/A");
        },
        renderCell: (params) => {
          let servingStates = params.row.serving_states || "N/A";
          return (
            <Tooltip title={servingStates || "N/A"}>
              <Typography variant="inherit">{servingStates}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "created",
        headerName: "Created At",
        width: 250,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.createdAt ? formatTimeStamp(params.row?.createdAt) : "N/A");
        },
        renderCell: (params) => {
          let createdAt = formatTimeStamp(params.row.createdAt) || "N/A";
          return (
            <Tooltip title={createdAt || "N/A"}>
              <Typography variant="inherit">{createdAt}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 250,
        filterable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit Transporter"}>
                <IconButton onClick={() => handleEdit(params.row)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Transporter"}>
                <IconButton onClick={() => deleteTransporter(params.row)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];

    return result;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-transporters",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          density="compact"
          rows={accessChanges || []}
          columns={getTransporterColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          disableDensitySelector
          rowHeight={60}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          filterMode="server"
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={rowCount}
          pageSizeOptions={[10]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const addTransporter = () => {
    setTransporterModal(true);
  };

  return (
    <>
      <StaffEditorPageContainer>
        {loading && <OpaqueLoading />}
        <HeaderContainer>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          ></div>
          <Stack spacing={1} direction={"row"}>
          
                      <Button variant="contained" color="primary"
                        onClick={selectedTab === 0 ? () => setBulkTransporter(true) : ""} sx={{ mr: 1, borderRadius: '10px' }}>
                        <Add sx={{ mr: 1 }} />
                        {selectedTab === 0 ? "Bulk Add Transporter" : ""}
                      </Button>
          
                      <Button variant="contained" color="primary"
                        onClick={selectedTab === 0 ? addTransporter : ""} sx={{ mr: 1, borderRadius: '10px' }}>
                        <Add sx={{ mr: 1 }} />
                        {selectedTab === 0 ? "Add transporter" : ""}
                      </Button>
          
                    </Stack>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {transporterModal && (
        <AddTransporterModal
          onClose={() => setTransporterModal(false)}
          fetchData={() => getTransporters()}
        />
      )}

      {editTransporterModal && (
        <AddTransporterModal
          onClose={() => setEditTransporterModal(false)}
          fetchData={() => getTransporters()}
          props={dataModal}
        />
      )}

{
        bulkTransporter && <BulkAddTransporter onClose={() => setBulkTransporter(false)} fetchData={() => getTransporters()} />
      }
    </>
  );
}
