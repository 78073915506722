import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const SeriesCategoryModal = ({ onClose,status,fetchData,propData }) => {
    console.log("propData",propData)
    const [form, setForm] = useState( {seriesName:propData?.seriesName||"",
        id: propData?.seriesTableId
   
    });
  const [loading,setLoading]=useState(false)


    const [product,setProduct]=useState()
    const [selectedProduct,setSelectedProduct]=useState(null)

 
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };

    const submitHandler = async() => {
      // console.log("creating activity...", form);
      try{
        console.log("PRODUCT RESULTS: " , form)
        setLoading(true)
        
        // console.log("PLANTCODE",plantcode[0].plant_code__c)
        if (propData) {
          await window.Platform.database.updateSeriesCategory({...form});
          window.NotificationUtils.showSuccess("Series updated successfully")
        } else {
          await window.Platform.database.addSeriesCategory({...form});
          window.NotificationUtils.showSuccess("Series added successfully")
        }
    
    // setProduct(results.data)
    setLoading(false)
    fetchData()
    onClose()
      }
      catch(err){
        window.NotificationUtils.showError(err?.message);
        console.log(err);
    setLoading(false)
     
      }
console.log(form)
    };

    console.log("FORMMMMM",form)

  
    return (
      <StyledDialog disableEnforceFocus maxWidth="sm" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{status?"Add Series":"Edit Series"}</Typography>
          <IconButton  onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
           

          
    <Stack direction={"row"} spacing={2} padding={1}>
          
<TextField label="Series Name"   value={form.seriesName||""} type="text" fullWidth  onChange={(e)=>changeHandler("seriesName",e.target.value)}/>
           </Stack>
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
                disabled={!form.seriesName?.trim()?.length}
              >
                {propData ? 'Update' : 'Create'}
              </Button>
            </div>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default SeriesCategoryModal;
  