import { Avatar, Button, CircularProgress, Divider, Fab, Tab, Tabs, Tooltip, Typography, IconButton, Stack } from '@mui/material';

import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import React, { useState, useEffect, useContext } from 'react'

import SwapVertIcon from '@mui/icons-material/SwapVert';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {

    DataGrid,

    GridToolbarContainer,

    GridToolbarColumnsButton,

    GridToolbarFilterButton,

    GridToolbarDensitySelector,

    GridToolbarExport,

    GridDensitySelector,
    getGridStringOperators,

} from "@mui/x-data-grid";


import DeleteIcon from '@mui/icons-material/Delete';

import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json"

import Drawer from "../../components/common/drawer/drawer";

import { TABS, ROLES, DATE_FORMATS } from '../../constants';

// import ReplaceManagerModal from "../../components/modals/replaceManagerModal";

// import BulkUploadRoleSelector from "../../components/modals/bulkUploadRoleSelector";

// import BulkUserDelete from '../../components/modals/bulkUserDelete';

// import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import moment from 'moment';

import { AuthContext } from '../../components/contextAPI/ContextAPI';

import { Add, AddAPhoto, AddTask, SaveAlt, SaveAltOutlined, SaveAs, ManageSearch, Send } from '@mui/icons-material';

// import { LottiePlayer } from 'lottie-react';

import Lottie from 'lottie-react';

import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import { useNavigate } from 'react-router-dom';
import UserModal from '../../components/modals/userModal';
import SchoolModal from '../../components/modals/schoolModal';
import DistributorModal from '../../components/modals/distributorModal';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';
import CommonOtpModal from '../../components/modals/commonOtpModal';
import BulkAddSchool from '../../components/modals/bulkAddSchool';
import BulkAddDistributor from '../../components/modals/bulkAddDistributor';
import CommonOtpModalConsent from '../../components/modals/commonOtpModalConsent';
import "../../App.css"




const isMobile = window.innerWidth < 900;



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;



const HeaderContainer = styled.div`

    display:flex;

    align-items:center;

    justify-content:space-between;

    margin-bottom:7px;

`



const TableContainer = styled.div`
//   height: calc(
//     100vh - ${isMobile ? "56px - 20px - 82.23px" : "128px - 29px - 50.77px"}
//   );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;



const DetailsPreviewContainer = styled.div`

    height:100%;

    width:100%;

    display:flex;

    justify-content:center;

    align-items:center;

    ${isMobile && `

    height: calc(100vh - 56px - 20px);

  `}

`



const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;



const AvatarContainer = styled.div`

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

`;



const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
//   border: solid 1px lightGrey;
  display: ${isMobile ? 'flex' : 'grid'};
  border-radius: 4px;


  ${isMobile &&
    `
    flex-direction: column;
  `}
`;



const LoadingContainer = styled.div`

height: calc(100% - 49px);

display:flex;

justify-content:center;

align-items:center;

`



const TabsContainer = styled.div`

  ${isMobile && `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}

`



const BulkUploadButton = styled(Button)`

  margin-right:10px !important;

`

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;





const PartyManagement = (props) => {
    
    const location = useLocation();
    const selectedTabProp1 = location.state?.selectedTabProp;
  
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [paginationModel, setPaginationModel] = useState({
        page: currentPage - 1,
        pageSize: 11,
    });
    const [paginationModel1, setPaginationModel1] = useState({
        page: currentPage1 - 1,
        pageSize: 11,
    });
    const [otpVerify, setOtpVerify] = useState(false)
    const [rowCount, setRowCount] = useState()
    const [rowCount1, setRowCount1] = useState()

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [dateRange1, setDateRange1] = useState(false)

    const [startDate1, setStartDate1] = useState()
    const [endDate1, setEndDate1] = useState()
    const [minDate, setMinDate] = useState()
const [consentData, setConsentData] = useState(null)

    const navigation = useNavigate()

    const [userModal, setUserModal] = useState(null)

    const [staffMembers, setStaffMembers] = useState([]);

    const [visibleEmployees, setVisibleEmployees] = useState([]);

    const [reasonModalObj, setReasonModalObj] = useState({});

    const [selectedMember, setSelectedMember] = useState(null);

    const [selectedTab, setSelectedTab] = useState(selectedTabProp1 || TABS.REGISTERED);
    const [showDeletionReasonPicker, setShowDeletionReasonPicker] = useState(false);


    const [loading, setLoading] = useState(false);

    const { auth, setAuth } = useContext(AuthContext)
    const [schoolModal, setSchoolModal] = useState(false);
    const [distributorModal, setDistributorModal] = useState(false);
    const [editSchool, setEditSchool] = useState(false);
    const [editData, setEditData] = useState();
    const [editData1, setEditData1] = useState();
    const [editDistributor, setEditDistributor] = useState(false);
    const [filter, setFilter] = useState(false)

    const [searchTerm, setSearchTerm] = useState(null)
    const [searchTerm1, setSearchTerm1] = useState(null)
    const [otpModalData, setOtpModalData] = useState()
    const [otpModal1, setOtpModal1] = useState(false)

    const [otpModalDataDistributor, setOtpModalDataDistributor] = useState()
    const [otpmModalDistributor, setOtpModalDistributor] = useState(false)
    const [bulkSchool, setBulkSchool] = useState(false)
    const [bulkDistributor, setBulkDistributor] = useState(false)

    const consentOtpResend = async (data) => {
        try {
            setLoading(true)
            const response = await window.Platform.database.consentOtpResend({ partyId: data.schoolId })

            setLoading(false)
            window.NotificationUtils.showSuccess("OTP Sent Successfully");

            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    const consentOtpSend = async (data) => {
        try {
            setLoading(true)
            const response = await window.Platform.database.consentOtpSend({ partyId: data.schoolId })

            setLoading(false)
            window.NotificationUtils.showSuccess("OTP Sent Successfully");

            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const consentOtpSubmit = async (data, otp) => {
        try {
            setLoading(true)
            
            // const response = await window.Platform.database.resendOTP({ mobile: otpModalData1.mobileNo })
            const response = await window.Platform.database.consentOtpSubmit({ partyId: data.partyId, otp: otp })

            setLoading(false)
            window.NotificationUtils.showSuccess("OTP Sent Successfully");

            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {


        if (filter && selectedTab === 0) {
            onFilterChange(searchTerm)
        }
        //   school

    }, [paginationModel.page]);
    useEffect(() => {


        if (filter && selectedTab === 1) {
            onFilterChange1(searchTerm1)
        }
        //   distributor

    }, [paginationModel1.page]);
    useEffect(() => {

        console.log("auth", auth);
        if (!filter) {
            fetchEmployees();
        }

    }, [selectedTab, paginationModel.page, paginationModel1.page])


    let contextState = { ...auth }


    const formatTimeStamp = (timestampFromDB) => {
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,  // Use 12-hour clock
        };

        const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);

        return formattedDate;
    };


    const schoolColumns = () => {
        const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

        let result = [ {
            field: "approvalStatus",
            headerName: "Approval Status",
            width: 150,
            filterable: false,
    
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            valueGetter: (params) => {
                let name = params.row?.approveStatus === 0 ? "Pending" : params.row?.approveStatus === 1 ? "Approved" : "Rejected" || "N/A";
                return name;
            },
            renderCell: (params) => {
      
              let name = params.row?.approveStatus===0?"Pending": params.row?.approveStatus===1?"Approved":"Rejected"||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },

            {

                field: "schoolName",

                headerName: "Name",

                minWidth: 250,

                valueGetter: (params) => {

                    let name = params.row?.schoolName
                    return name;

                },

                flex: 0.22,

                editable: false,
                filterOperators: stringOperators
                ,

                renderCell: (params) => {

                    let name = params.row?.schoolName || "N/A"

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "name",

                headerName: "Assigned To",

                minWidth: 200,

                valueGetter: (params) => {

                    let name = params.row.name
                    return name;

                },

                flex: 0.22,

                editable: false,
                filterOperators: stringOperators
                ,

                renderCell: (params) => {

                    let name = params.row.name || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "email",

                headerName: "School Email",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterable: false,


                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.email || "undefined"}>

                            <Typography variant="inherit">{params.row.email || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

        },
        {
            field: "createdAt",
            headerName: "Created At",
            width: 250,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            filterable: false,
      
            // editable: true,
            renderCell: (params) => {
      
              let name =formatTimeStamp( params.row.createdAt)||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          
        {

                field: "Website",

                headerName: "School Website",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterable: false,
                valueGetter: (params) => {
                    let name = params.row?.website || "N/A";
                    return name;
                },
                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.website || "undefined"}>

                            <Typography variant="inherit">{params.row.website || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },
            {

                field: "onboardForErp",

                headerName: "Onboarder For ERP",

                minWidth: 200,

                flex: 0.35,

                editable: false,
                filterable: false,
                valueGetter: (params) => {
                    let name = params.row?.onboardForErp;
                    return name;
                },
                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.onboardForErp || "undefined"}>

                            <Typography variant="inherit">{params.row.onboardForErp === 1 ? "Yes" : "No" || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "makerName",

                headerName: "Decision Maker Name",

                minWidth: 200,

                flex: 0.35,

                editable: false,
                filterable: false,

                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.makerName || "undefined"}>

                            <Typography variant="inherit">{params.row.makerName || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "makerContact",

                headerName: "Decision Maker Contact",

                minWidth: 200,

                flex: 0.35,

                editable: false,
                filterable: false,

                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.makerContact || "undefined"}>

                            <Typography variant="inherit">{params.row.makerContact || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },

            // {

            //   field: "employeeCode",

            //   headerName: "Employee code",

            //   minWidth:200,

            //   flex: 0.17,

            //   editable:false,

            //   renderCell: (params) => {

            //     return (

            //       <Tooltip title={params.row.employeeCode || "undefined"}>

            //         <Typography variant="inherit">{params.row.employeeCode}</Typography>

            //       </Tooltip>

            //     )

            //   },

            // },

            {

                field: "board",

                headerName: "Board",

                minWidth: 150,

                flex: 0.22,

                editable: false,
                filterable: false,

                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.board || "undefined"} >

                            <Typography variant="inherit">{params.row.board || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "medium",

                headerName: "Medium",

                editable: false,

                hide: true,

                minWidth: 150,
                filterable: false,

                valueGetter: (params) => {

                    let name = params.row.medium;

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.medium || "N/A";
                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "incorporationDate",

                headerName: "Incorporation Date",

                editable: false,

                hide: true,

                minWidth: 250,
                filterable: false,

                valueGetter: (params) => {

                    let name = formatTimeStamp(params.row.incorporationDate);

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.incorporationDate ? formatTimeStamp(params.row.incorporationDate) : "N/A";
                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "grade",

                headerName: "Grade",

                editable: false,

                hide: true,

                minWidth: 270,
                filterable: false,

                valueGetter: (params) => {
// {console.log(params,"=================================>>>>>>>>>>>>>>>>>>>>>")}
                    let name = params.row.grade_names;

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.grade_names || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{ name?.length > 35 ? name.slice(0, 35) + '...' : name }</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "strength",

                headerName: "Strength",

                editable: false,

                hide: true,

                minWidth: 200,
                filterable: false,

                valueGetter: (params) => {

                    let name = params.row.strength;

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.strength || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },
            {

                field: "State",

                headerName: "State",

                editable: false,

                hide: true,

                minWidth: 200,
                filterOperators: stringOperators
                ,
                valueGetter: (params) => {

                    let name = params.row.State

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.State || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "District",

                headerName: "District",

                editable: false,

                hide: true,

                minWidth: 200,
                filterOperators: stringOperators
                ,
                valueGetter: (params) => {

                    let name = params.row.District

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.District || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "AddressLine1",

                headerName: "Address",

                editable: false,

                hide: true,

                minWidth: 200,
                filterable: false,

                valueGetter: (params) => {

                    let name = params.row?.AddressLine1?.toString() + " " + params.row.AddressLine2 || "";

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row?.AddressLine1?.toString() + " " + params.row.AddressLine2 || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "Landmark",

                headerName: "Landmark",

                editable: false,

                hide: true,

                minWidth: 200,
                filterable: false,

                valueGetter: (params) => {

                    let name = params.row.Landmark;

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.Landmark || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "Location",

                headerName: "Location",

                editable: false,

                hide: true,

                minWidth: 200,
                filterable: false,

                valueGetter: (params) => {

                    let name = params.row.Location;

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.Location || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },
            {
                field: "Approve Order",
                headerName: "Actions ",
                width: 200,
                // height: 10,
                // headerAlign: 'center',
                sortable: false,
                // editable: true,
                filterable: false,
                disableExport: true,
                renderCell: (params) => {

                    let value = params.row?.schoolId

                    return (
                        <Stack direction={"row"} spacing={1}>
                            <Tooltip title={"Edit School"}>

                                <IconButton onClick={() => handleEdit(params)}>
                                    <DriveFileRenameOutlineIcon />
                                </IconButton>
                                <IconButton onClick={(e) => handleDelete(params.row.schoolId, e)}>
                  <DeleteIcon />
                </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Click to approve school"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "1" });
                            }} >
                                <ThumbUpIcon />
                            </IconButton></Tooltip>


                            <Tooltip title="Click to reject school"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "red" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "2" });
                            }} >
                                <ThumbDownIcon />
                            </IconButton></Tooltip> */}
                        </Stack>
                    )

                },
            },

            // {
            //     field: "Consent",
            //     headerName: "Consent",
            //     width: 120,
            //     // height: 10,
            //     // headerAlign: 'center',
            //     sortable: false,
            //     // editable: true,
            //     filterable: false,
            //     disableExport: true,
            //     renderCell: (params) => {

            //         let value = params.row?.consent

            //         return (
            //             <Stack direction={"row"} spacing={1}>
            //                 <Tooltip title={"Consent Approval"}>

            //                     <IconButton disabled={params.row.consent || !params.row.makerContact?.length} onClick={() =>{
            //                         setConsentData(params.row)
            //                         updateConsent(params.row)}} >
            //                         <Send />
            //                     </IconButton>


            //                 </Tooltip>
            //                 {/* <Tooltip title="Click "><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"#008000"}}  onClick={(e) => {
            //       e.stopPropagation();
            //       approveRejectOrder({id:value,approvalStatus:"1"});}} >
            //      <ThumbUpIcon/>
            //     </IconButton></Tooltip> */}
            //                 {/* <Tooltip title="Click to reject school"><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
            //       e.stopPropagation();
            //       approveRejectOrder({id:value,approvalStatus:"2"});}} >
            //      <ThumbDownIcon/>
            //     </IconButton></Tooltip> */}
            //             </Stack>
            //         )

            //     },
            // },
            // {

            //     field: "Consent Status",

            //     headerName: "Consent Status",

            //     editable: false,

            //     hide: true,

            //     minWidth: 150,
            //     filterable: false,

            //     valueGetter: (params) => {

            //         let name = params.row.consent ? 'true' : 'false';

            //         return name;

            //     },

            //     renderCell: (params) => {

            //         let name = params.row.consent ? 'true' : 'false';

            //         return (

            //             <Tooltip title={name || "undefined"}>

            //                 <Typography variant="inherit">{name === 'true' ? 'Active' : 'Inactive'}</Typography>

            //             </Tooltip>

            //         )

            //     },

            // },

        ];
        return result
    }

    const distributorColumn = () => {
        const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

        let result = [ {
            field: "approvalStatus",
            headerName: "Approval Status",
            width: 200,
            filterable: false,
    
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name = params.row?.approveStatus===0?"Pending": params.row?.approveStatus===1?"Approved":"Rejected"||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },

            {

                field: "DistributorName",

                headerName: "Name",

                minWidth: 250,

                valueGetter: (params) => {

                    let name = params.row.DistributorName

                    return name;

                },

                flex: 0.22,

                editable: false,
                filterOperators: stringOperators
                ,

                renderCell: (params) => {

                    let name = params.row.DistributorName || "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "GSTno",

                headerName: "GSTno",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterable: false,


                valueGetter: (params) => {

                    return params.row.GSTno || ""

                },

                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.GSTno || "undefined"}>

                            <Typography variant="inherit">{params.row.GSTno || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

            },



            {

                field: "name",

                headerName: "Assigned To",

                minWidth: 200,

                flex: 0.22,

                editable: false,
                filterOperators: stringOperators
                ,

                renderCell: (params) => {

                    return (

                        <Tooltip title={params.row.name || "undefined"}>

                            <Typography variant="inherit">{params.row.name || "N/A"}</Typography>

                        </Tooltip>

                    )

                },

        },  {
            field: "createdAt",
            headerName: "Created At",
            width: 250,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            filterable: false,
      
            // editable: true,
            renderCell: (params) => {
      
              let name =formatTimeStamp( params.row.createdAt)||"N/A";
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },

          {
            field: "transporter_name",
            headerName: "Transporter Name",
            minWidth: 250,
            flex: 0.35,
            editable: false,
            filterable: false,
            valueGetter: (params) => {
                let name = params.row.transporter_name;
                if (name == null || (name?.length == 0)) name = "N/A"
                return name;
            },
            renderCell: (params) => {
                let name = params.row.transporter_name;
                if (name == null || (name?.length == 0)) name = "N/A";
                return (
                    <Tooltip title={name || "N/A"}>
                        <Typography variant="inherit">{name}</Typography>
                    </Tooltip>
                )
            },
        },
        {
            field: "stockist_name",
            headerName: "Stockist Name",
            minWidth: 250,
            flex: 0.35,
            editable: false,
            filterable: false,
            valueGetter: (params) => {
                let name = params.row.stockistName;
                if (name == null || (name?.length == 0)) name = "N/A"
                return name;
            },
            renderCell: (params) => {
                let name = params.row.stockistName;
                if (name == null || (name?.length == 0)) name = "N/A";
                return (
                    <Tooltip title={name || "N/A"}>
                        <Typography variant="inherit">{name}</Typography>
                    </Tooltip>
                )
            },
        },


            {

                field: "makerName",

                headerName: "Contact Person Name",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterable: false,


                valueGetter: (params) => {

                    let name = params.row.makerName

                    if (name == null || (name?.length == 0)) name = "N/A"

                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.makerName

                    if (name == null || (name?.length == 0)) name = "N/A";

                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },

            },

            {

                field: "makerContact",

                headerName: "Contact Person Contact",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterable: false,

                valueGetter: (params) => {

                    // console.log(moment.unix(params.row.makerContact).format("MM/DD/YYYY"))

                    let name = params.row.makerContact




                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.makerContact || "N/A";



                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },



            },
            {

                field: "State",

                headerName: "State",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterOperators: stringOperators,


                valueGetter: (params) => {

                    // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

                    let name = params.row.State





                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.State || "N/A";



                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },


            },

            {

                field: "District",

                headerName: "District",

                minWidth: 250,

                flex: 0.35,

                editable: false,
                filterOperators: stringOperators
                ,

                valueGetter: (params) => {

                    // console.log(moment.unix(params.row.District).format("MM/DD/YYYY"))

                    let name = params.row.District





                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.District || "N/A";



                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },


            },
            {

                field: "Address",

                headerName: "Address",

                minWidth: 250,

                flex: 0.35,
                filterable: false,

                editable: false,

                valueGetter: (params) => {

                    // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

                    let name = params.row.AddressLine1 + " " + params.row.AddressLine2





                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.AddressLine1 + " " + params.row.AddressLine2 || "N/A";



                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },


            },

            {

                field: "Pincode",

                headerName: "Pincode",

                minWidth: 150,

                flex: 0.35,

                editable: false,
                filterable: false,


                valueGetter: (params) => {

                    // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

                    let name = params.row.Pincode





                    return name;

                },

                renderCell: (params) => {

                    let name = params.row.Pincode || "N/A";



                    return (

                        <Tooltip title={name || "undefined"}>

                            <Typography variant="inherit">{name}</Typography>

                        </Tooltip>

                    )

                },


            }, {
                field: "Approve Order",
                headerName: "Actions ",
                width: 200,
                // height: 10,
                // headerAlign: 'center',
                sortable: false,
                // editable: true,
                filterable: false,
                disableExport: true,
                renderCell: (params) => {

                    let value = params.row?.distributorID

                    return (
                        <Stack direction={"row"} spacing={1}>
                            <Tooltip title="Convert Distributor to Stockist">
                            <IconButton
        onClick={()=>handleConvertStockist(params)}
        style={{
        //   transform: rotate ? 'rotate(360deg)' : 'rotate(0deg)',
          transition: 'transform 0.6s ease',
        }}
      >
        <SwapVertIcon />
      </IconButton>
    </Tooltip>
                            <Tooltip title={"Edit Distributor"}>

                                <IconButton onClick={() => handleEdit1(params)}>
                                    <DriveFileRenameOutlineIcon />
                                </IconButton>

                            </Tooltip>
                <Tooltip title="Delete">
                <IconButton
                  color="black"
                  onClick={(e) => handleDeleteDistributor(params.row.distributorID, e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
                            {/* <Tooltip title="Click to approve distributor"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "1" });
                            }} >
                                <ThumbUpIcon />
                            </IconButton></Tooltip>
                            <Tooltip title="Click to reject distributor"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "red" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "2" });
                            }} >
                                <ThumbDownIcon />
                            </IconButton></Tooltip> */}
                        </Stack>
                    )

                },
            },

        ];
        return result
    }

    const handleDeleteDistributor = async  (distributorId, event) => {
        event.stopPropagation();  // Prevent row selection on click
    
        const confirmDelete = window.confirm("Are you sure you want to delete this Distributor ?");
        if (!confirmDelete) return;
    
        try {
          setLoading(true);

          const response = await window.Platform.database.deleteDistributor({ id : distributorId });
    
    
          window.NotificationUtils.showSuccess("Distributor deleted successfully");

          fetchEmployees()
          
        } catch (error) {
          console.error("Error deleting the Distributor:", error);
          window.NotificationUtils.showError("Error deleting the Distributor");
        } finally {
          setLoading(false);
        }
    }

    const handleEdit1 = (data) => {
        console.log("editData", data)
        setEditDistributor(true)
        setEditData1(data.row)
    }

    // const handleConvertStockist = async (data) => {
    //     console.log("lllaaasss >>>>>>>>>",data?.row?.distributorID)

    //     const results = await window.Platform.database.addDistributorToStockist(data?.row?.distributorID);

    // }
    const handleConvertStockist = async (data) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm('Are you sure you want to convert this distributor into a stockist ?');
        
        if (isConfirmed) {
          console.log("Distributor ID >>>>>>>>>", data?.row?.distributorID);
          
          const results = await window.Platform.database.addDistributorToStockist({distributorId : data?.row?.distributorID});
          
          if (results) {
            alert(results.message);
          } else {
            alert(results.message);
          }
        } else {
          console.log('Conversion canceled');
        }
      };

    const handleEdit = (data) => {
        console.log("editData", data)
        setEditSchool(true)
        setEditData(data.row)
    }

    // const handleDelete = (data) => {
    //     console.log(data.row.schoolId,"dtaaaaaaaaa");
        
    // }

    const handleDelete = async (schoolId, event) => {
        event.stopPropagation();  // Prevent row selection on click
    
        const confirmDelete = window.confirm("Are you sure you want to delete this School?");
        if (!confirmDelete) return;
    
        try {
          setLoading(true);

          const response = await window.Platform.database.deleteSchool({ id : schoolId });
    
    
          window.NotificationUtils.showSuccess("School deleted successfully");

          fetchEmployees()
          
        } catch (error) {
          console.error("Error deleting the School:", error);
          window.NotificationUtils.showError("Error deleting the School");
        } finally {
          setLoading(false);
        }
      };

    const updateConsent = (data) => {
        console.log("consent", data)
        consentOtpSend(data)
        setOtpVerify(true)
        // setEditSchool(true)
        // setEditData(data.row)
    }

    const onSubmitOtp = async(data, otp) => {
        try {
            setLoading(true)
            let onsubmitOtp = await window.Platform.database.consentOtpSubmit({ partyId: data.schoolId, otp: otp})

            if (onsubmitOtp.status) {
                setLoading(false)
                window.NotificationUtils.showError(onSubmitOtp.message);
            } else {
                setLoading(false)
                window.NotificationUtils.showSuccess("Consent verified successfully");
                setOtpVerify(false)
            }

        } catch (error) {
            console.error(error)
            setLoading(false)
            window.NotificationUtils.showError("OTP Submission Failed");

        }
    }

    const handleTabChange = async (value, keepSelected = false) => {

        setVisibleEmployees([]);
        if (!keepSelected) {
            setSelectedMember(null);
        }
        setFilter(false);
        setStartDate(null)
        setEndDate(null)
        setStartDate1(null)
        setEndDate1(null)
        setDateRange1(false)
        setSelectedTab(value);


    }



    const addNewSchool = () => {

        setSchoolModal(true)

        if (isMobile) {

            scrollToPreview();

        }

    }

    const addNewDistributor = () => {

        setDistributorModal(true)


        if (isMobile) {

            scrollToPreview();

        }
    }

    const fetchEmployees = async (data) => {
        setLoading(true);

        try {

            setVisibleEmployees([])
            // console.log("authhhh", contextState)
            let results
            if (selectedTab === 1) {
                results = await window.Platform.database.getDistributor({ pageNumber: paginationModel1.page });
                setRowCount1(results.data1)
            }

            else {
                results = await window.Platform.database.getSchool({ pageNumber: paginationModel.page });
                setRowCount(results.data1)
            }

            const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));



            // console.log("results", results);

            // if (auth?.user?.role === ROLES.ADMIN) {
            console.log("response", results.data);


            // }

            setVisibleEmployees(jsonArrayWithId);

            setLoading(false);
            // window.NotificationUtils.showError(results.message)
            if(results.status){
            setLoading(false);
            window.NotificationUtils.showError(results.message)
              }
              else{
            setLoading(false);
            // window.NotificationUtils.showSuccess(results.message)
              }

        } catch (error) {

            console.error(error);

            window.NotificationUtils.showError("Error Fetching Data");

            setLoading(false);

        }

    }


    const scrollToPreview = () => {

        window.scrollTo(0, document.getElementById("detailsPreviewContainer").getBoundingClientRect().top);

    }

    const onFilterChange = async (filterModel) => {
        try {
            setLoading(true);
            setFilter(true)
            console.log(filterModel, 'filterModel')
            if (filterModel) {
                const response = await window.Platform.database.filterSchool({ filterField: filterModel.field, filterValue: filterModel.value, pageNumber: paginationModel.page, startDate: startDate, endDate: endDate })
                console.log("respponse", response);
                const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                setVisibleEmployees(jsonArrayWithId)
                setRowCount(response.data1)
            }
            else {
                const response = await window.Platform.database.filterSchool({ filterField: "date", pageNumber: paginationModel.page, startDate: startDate, endDate: endDate })
                console.log("respponse", response);
                const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                setVisibleEmployees(jsonArrayWithId)
                setRowCount(response.data1)
            }

            setLoading(false);
        }
        catch (err) {
            console.error(err)
            setLoading(false);
            window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
        }

    };

    const onFilterChange1 = async (filterModel) => {


        try {
            setLoading(true);
            setFilter(true)
            console.log(filterModel, 'filterModel')
            if (filterModel) {
                const response = await window.Platform.database.filterDistributor({ filterField: filterModel.field, filterValue: filterModel.value, pageNumber: paginationModel1.page })
                console.log("respponse", response);
                const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                setVisibleEmployees(jsonArrayWithId)
                setRowCount(response.data1)
            }
            else {
                const response = await window.Platform.database.filterDistributor({ filterField: "date", pageNumber: paginationModel1.page, startDate: startDate, endDate: endDate })
                console.log("respponse", response);
                const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                setVisibleEmployees(jsonArrayWithId)
                setRowCount(response.data1)

            }

            setLoading(false);
        }
        catch (err) {
            console.error(err)
            setLoading(false);
            window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
        }

    };

    function CustomToolbar() {

        return (

            <GridToolbarContainer>

                <GridToolbarColumnsButton />

                <GridToolbarFilterButton />

                {/* <GridToolbarDensitySelector /> */}

                <GridToolbarExport

                    csvOptions={{

                        fileName: "users-report",

                    }}

                />

            </GridToolbarContainer>

        );

    }

    const renderTabContent = () => {




        if (selectedTab === 0) {

            return (

                <DataGridContainer style={{ height: !visibleEmployees.length ? '200px' : 'auto' }}>

                    <DataGrid

                        className="payrollGrid"

                        // checkboxSelection={true}
                        density='compact'

                        rows={visibleEmployees || []}

                        columns={schoolColumns()}

                        // disableSelectionOnClick

                        disableSelectionOnClick

                        disableRowSelectionOnClick
                        pageSizeOptions={[10]}

                        onRowClick={(rowParams, event) => {

                            setUserModal(rowParams.row)
                            console.log(rowParams.onRowClick)
                        }}
                        rowHeight={60}
                        onFilterModelChange={(val) => {
                            console.log("vvvv", val);



                            if (val?.items[0]?.value?.length > 0) {
                                onFilterChange(val.items[0])
                                setSearchTerm(val.items[0])
                            }
                            else if (!val.items[0]?.value && startDate && endDate) {
                                setSearchTerm(null)
                                onFilterChange()
                                console.log("CHECKING...1")

                            }
                            else {
                                fetchEmployees()
                                setFilter(false)
                                setSearchTerm(null)
                            }
                            //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
                            //     setSearchTerm(null)
                            //     fetchData()
                            //     console.log("CHECK1")
                            //   }
                            //   else if(!val.items?.value && endDate && startDate && territoryFilter){
                            //     setSearchTerm(null)

                            //     onFilterChange1()
                            //     console.log("checkterr")
                            //   }
                            //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
                            //     setSearchTerm(null)
                            //     console.log("check2")
                            //     onFilterChange1()


                            //   }
                            //   else{
                            //     setSearchTerm(null)
                            //     console.log("check2")
                            //     onFilterChange1()
                            //   }
                        }

                        }
                        filterMode="server"

                        rowCount={rowCount}

                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        components={{

                            Toolbar: CustomToolbar,

                        }}

                    />

                </DataGridContainer>

            );
        }
        else if (selectedTab === 1) {
            return (
                <DataGridContainer style={{ height: !visibleEmployees.length ? '200px' : 'auto' }}>

                    <DataGrid

                        className="payrollGrid"

                        // checkboxSelection={true}
                        density='compact'

                        rows={visibleEmployees || []}

                        columns={distributorColumn()}

                        // disableSelectionOnClick

                        disableSelectionOnClick

                        disableRowSelectionOnClick
                        pageSizeOptions={[10]}

                        onRowClick={(rowParams, event) => {

                            setUserModal(rowParams.row)
                            console.log(rowParams.onRowClick)
                        }}
                        rowHeight={60}
                        onFilterModelChange={(val) => {
                            console.log("vvvv", val);



                            if (val?.items[0]?.value?.length > 0) {
                                onFilterChange1(val.items[0])
                                setSearchTerm1(val.items[0])
                            }
                            else if (!val.items[0]?.value && startDate && endDate) {
                                setSearchTerm1(null)
                                onFilterChange1()
                                console.log("CHECKING...1")

                            }
                            else {
                                fetchEmployees()
                                setFilter(false)
                                setSearchTerm1(null)
                            }
                            //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
                            //     setSearchTerm(null)
                            //     fetchData()
                            //     console.log("CHECK1")
                            //   }
                            //   else if(!val.items?.value && endDate && startDate && territoryFilter){
                            //     setSearchTerm(null)

                            //     onFilterChange1()
                            //     console.log("checkterr")
                            //   }
                            //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
                            //     setSearchTerm(null)
                            //     console.log("check2")
                            //     onFilterChange1()


                            //   }
                            //   else{
                            //     setSearchTerm(null)
                            //     console.log("check2")
                            //     onFilterChange1()
                            //   }
                        }

                        }
                        filterMode="server"

                        rowCount={rowCount1}

                        paginationModel={paginationModel1}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel1}

                        components={{

                            Toolbar: CustomToolbar,

                        }}

                    />

                </DataGridContainer>

            );


        }

    }

    const approveRejectOrder = async (val) => {
        const confirmText = (val.approvalStatus === '1') ? 'approve' : 'reject';
        const isConfirmed = window.confirm(`Are you sure you want to ${confirmText} this party?`);
        
        if (!isConfirmed) {
          return;
        }
      
        try {
          setLoading(true);
          const results = await window.Platform.database.approveRejectParty(val);
      
          if (results.status) {
            window.NotificationUtils.showError(results?.message);
            setLoading(false);
            return;
          }
      
          if (val.approvalStatus === '1') {
            window.NotificationUtils.showSuccess("Party Approved");
          } else {
            window.NotificationUtils.showSuccess("Party Rejected");
          }
      
          setLoading(false);
          fetchEmployees();
        } catch (err) {
          console.log(err);
          window.NotificationUtils.showError("Something Went Wrong!");
          setLoading(false);
        }
      };


    const formatDate = async (data) => {
        setMinDate(data)
        setDateRange1(true)
        setEndDate1(null)
        setEndDate(null)
        const datePickerResponse = new Date(data.$d);

        const year = datePickerResponse.getFullYear();
        const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
        const day = String(datePickerResponse.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        setStartDate1(formattedDate)
        setStartDate(formattedDate)
        console.log("CHECK!!")
        console.log("CHECKENDDATE", endDate)

    }
    const finalDateRangeFilter = async (data) => {
        try {
            setLoading(true)
            setFilter(true)

            const datePickerResponse = new Date(data);

            const year = datePickerResponse.getFullYear();
            const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
            const day = String(datePickerResponse.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            if (data) {
                setEndDate(formattedDate)
                setEndDate1(formattedDate)
            }
            if (searchTerm || searchTerm1) {
                if (selectedTab === 0 && searchTerm) {
                    const response = await window.Platform.database.filterSchool({ filterField: searchTerm.field, filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: startDate, endDate: data ? formattedDate : endDate })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
                else if (selectedTab === 1 && searchTerm1) {
                    const response = await window.Platform.database.filterDistributor({ filterField: searchTerm1.field, filterValue: searchTerm1?.value, pageNumber: paginationModel1.page, startDate: startDate, endDate: data ? formattedDate : endDate })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
            }
            else {
                if (selectedTab === 0) {
                    const response = await window.Platform.database.filterSchool({ filterField: "date", filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: startDate, endDate: data ? formattedDate : endDate })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
                else {
                    const response = await window.Platform.database.filterDistributor({ filterField: "date", filterValue: searchTerm1?.value, pageNumber: paginationModel1.page, startDate: startDate, endDate: data ? formattedDate : endDate })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
            }
            setLoading(false)

        }
        catch (err) {
            console.log(err)
            setLoading(false)
            window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");

            fetchEmployees()

        }
    }
    const clearDateFilter = async () => {
        setStartDate(null)
        setEndDate(null)
        setStartDate1(null)
        setEndDate1(null)
        setDateRange1(false)
        if (searchTerm) {
            try {
                setLoading(true)
                if (selectedTab === 0) {
                    const response = await window.Platform.database.filterSchool({ filterField: searchTerm ? searchTerm.field : "date", filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: '', endDate: '' })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
                else {
                    const response = await window.Platform.database.filterDistributor({ filterField: searchTerm1 ? searchTerm1.field : "date", filterValue: searchTerm1?.value, pageNumber: paginationModel1.page, startDate: '', endDate: '' })
                    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
                    setVisibleEmployees(jsonArrayWithId)
                    setRowCount(response.data1)
                }
                setLoading(false)
            }
            catch (e) {
                console.log(e)
                window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
                fetchEmployees()

            }
        }
        else {
            setFilter(false)
            fetchEmployees()
        }
    }

    const onClose = async (data) => {
        console.log("COMMONOTPMODAL", data.makerContact)
        try {
            // const response = await window.Platform.database.sendOtp({ mobile: data.makerContact })
            // window.NotificationUtils.showSuccess("OTP Sent Successfully");

            setOtpModalData(data)
            submitOtp(data)
            // setOtpModal1(true)
            // setSchoolModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onCloseDistributor = async (data) => {
        console.log("COMMONOTPMODAL", data.makerContact)
        try {
            // const response = await window.Platform.database.sendOtp({ mobile: data.makerContact })
            // window.NotificationUtils.showSuccess("OTP Sent Successfully");

            setOtpModalDataDistributor(data)
            submitOtpDistributor(data)
            // setOtpModalDistributor(true)
            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }


    const resendOTP = async (data) => {
        try {
            setLoading(true)
            const response = await window.Platform.database.resendOTP({ mobile: otpModalData.makerContact })

            setLoading(false)
            window.NotificationUtils.showSuccess("OTP Sent Successfully");

            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }


    const resendOTPDistributor = async (data) => {
        try {
            setLoading(true)
            const response = await window.Platform.database.resendOTP({ mobile: otpModalDataDistributor.makerContact })

            setLoading(false)
            window.NotificationUtils.showSuccess("OTP Sent Successfully");

            // setDistributorModal(false)
            // setEditSchool(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const submitOtp = async (data) => {
        try {
            // const response = await window.Platform.database.verifyOtp({ otp: data, mobile: otpModalData.makerContact })
            // if (response.status === true) {
            //     window.NotificationUtils.showError(response.message);
            //     setLoading(false)

            //     return;
            // }
            // setOtpModal1(false)
            setLoading(true)
            const response1 = await window.Platform.database.addSchool(data)
            if (response1.status === true) {
                window.NotificationUtils.showError(response1.message);
                setLoading(false)

                return;
            }
            setSchoolModal(false)
            setLoading(false)
            fetchEmployees()
            window.NotificationUtils.showSuccess("Party Added Successfully");

        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }
    }
    const submitOtpDistributor = async (data) => {
        try {
            // const response = await window.Platform.database.verifyOtp({ otp: data, mobile: otpModalDataDistributor.makerContact })
            // if (response.status === true) {
            //     window.NotificationUtils.showError(response.message);
            //     setLoading(false)

            //     return;
            // }
            // setOtpModalDistributor(false)

            setLoading(true)
            const results = await window.Platform.database.addDistributor(data);
            if (results.status === true) {
                window.NotificationUtils.showError(results.message);
                setLoading(false)

                return;
            }

            setDistributorModal(false)
            setLoading(false)
            fetchEmployees()
            window.NotificationUtils.showSuccess("Party Added Successfully");

        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }
    }
    return (

        <>



            <StaffEditorPageContainer>

                <HeaderContainer>

                    <div style={{ width: "60%", display: "flex", flexDirection: "row", gap: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data) => formatDate(data)} disableFuture={true}/>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="End Date" minDate={minDate} value={endDate1} disabled={!dateRange1 || loading} format="YYYY/MM/DD" onChange={(data) => finalDateRangeFilter(data.$d)} disableFuture={true} />
                        </LocalizationProvider>
                        <Button variant="text" onClick={() => clearDateFilter()} disabled={!dateRange1 || loading} >Clear</Button>
                    </div>


                    <Stack spacing={1} direction={"row"}>







                        {/* <FabStyle variant="extended" color="primary" size='small'

                            onClick={selectedTab === 0 ? () => setBulkSchool(true) : () => setBulkDistributor(true)}

                        >

                            <Add sx={{ mr: 1 }} />

                            {selectedTab === 0 ? "Bulk Add School" : "Bulk Add Distributor"}

                        </FabStyle>

                        <FabStyle variant="extended" color="primary" size='small'

                            onClick={selectedTab === 0 ? addNewSchool : addNewDistributor}

                        >

                            <Add sx={{ mr: 1 }} />

                            {selectedTab === 0 ? "Add School" : "Add Distributor"}

                        </FabStyle> */}

                        <Button variant="contained" color="primary" 
                            onClick={selectedTab === 0 ? () => setBulkSchool(true) : () => setBulkDistributor(true)} sx={{ mr: 1, borderRadius: '10px' }}>
                        <Add sx={{ mr: 1 }}  />
                            { selectedTab === 0 ? "Bulk Add School" : "Bulk Add Distributor" }
                        </Button>

                        <Button variant="contained" color="primary" 
                            onClick={selectedTab === 0 ? addNewSchool : addNewDistributor} sx={{ mr: 1, borderRadius: '10px' }}>
                        <Add sx={{ mr: 1 }}  />
                            { selectedTab === 0 ? "Add School" : "Add Distributor" }
                        </Button>

                    </Stack>

                </HeaderContainer>

                <TableContainer>

                    <TabsContainer>

                        <StyledTabs

                            variant="fullWidth"

                            value={selectedTab}

                            onChange={(event, value) => handleTabChange(value)}

                            indicatorColor="primary"

                            textColor="primary"

                        >

                            <Tab label="School" />


                            {<Tab label="Distributor" />}

                        </StyledTabs>
                        {loading && <OpaqueLoading />}


                        {renderTabContent()}

                    </TabsContainer>


                </TableContainer>

            </StaffEditorPageContainer>



            {otpVerify && <CommonOtpModalConsent data={consentData} closeModal={() => setOtpVerify(false)} onClickSubmit={onSubmitOtp } resendOTP={consentOtpResend} />}
            {
                schoolModal && <SchoolModal onClose1={(data) => onClose(data)} onClose={() => setSchoolModal(false)} fetchData={() => fetchEmployees()} status={true} />
            }
            {
                distributorModal && <DistributorModal onClose={() => setDistributorModal(false)} onClose1={(data) => onCloseDistributor(data)} fetchData={() => fetchEmployees()} status={true} />
            }

            {
                editSchool && <SchoolModal onClose={() => setEditSchool(false)} fetchData={() => fetchEmployees()} dataProp={editData} status={false} />
            }
            {
                editDistributor && <DistributorModal onClose={() => setEditDistributor(false)} fetchData={() => fetchEmployees()} dataProp={editData1} status={false} />
            }
            {
                otpModal1 && <CommonOtpModal closeModal={() => setOtpModal1(false)} onClickSubmit={(data) => submitOtp(data)} resendOTP={() => resendOTP()} />
            }

            {
                otpmModalDistributor && <CommonOtpModal closeModal={() => setOtpModalDistributor(false)} onClickSubmit={(data) => submitOtpDistributor(data)} resendOTP={() => resendOTPDistributor()} />
            }

            {bulkSchool && <BulkAddSchool onClose={() => setBulkSchool(false)} fetchData={() => fetchEmployees()} />

            }
            {bulkDistributor && <BulkAddDistributor onClose={() => setBulkDistributor(false)} fetchData={() => fetchEmployees()} />

            }
        </>

    );

}



export default PartyManagement