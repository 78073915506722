import react, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AttendanceModal from "../../components/modals/attendanceModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as XLSX from "xlsx";
import axios from "axios";


const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
// );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function AttendanceManagement() {
  const apiRef = useRef();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [attendanceModal, setAttendanceModal] = useState(false)
const [attendanceModal1, setAttendanceModal1] = useState(false)
const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()
// const [dataForExportExcel, setDataForExportExcel] = useState([])


useEffect(() => {
  // setAccessChanges(rows);
  if(!filter){
  fetchVisits()}
}, [paginationModel.page]);
useEffect(() => {
 if(filter){
  onFilterChange(searchTerm)
 }
}, [paginationModel.page]);

  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  
  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

 



  const fetchVisits= async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchAttendance({pageNumber:paginationModel.page});
      console.log("results", results);
      setRowCount(results.data1);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
  let result = [
    {
      field: "name",
      headerName: "Employee Name",
      sortable: false,
      width: 200,
      filterOperators: stringOperators 
,
      editable: false,
      renderCell: (params) => {

        let name = params.row?.name||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 250,    
              filterable: false,

      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.startTime?formatTimeStamp( params.row.startTime):"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "endTime",
        headerName: "End Time",
        width: 250,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.endTime&& formatTimeStamp( params.row.endTime)||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "workType",
        headerName: "Work Type",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.workType||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "totalTime",
        headerName: "Total Work Timely",
        width: 150,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {

          let time = params.row?.totalTime

          let hours = Math.floor(time / 60); 
          let minutes = time % 60;       
          
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let name = hours + ":" + minutes + " hrs" 
  
          // let name = params.row?.totalTime||"N/A"
  
          return (
  
            <Tooltip title={name}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "startKm",
        headerName: "Start Km",
        width: 150,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.startKm===0?0:params.row.startKm||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
     
      {
        field: "endKm",
        headerName: "End Km",
        width: 150,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.endKm||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },

      {
        field: "TravelledDistance ",
        headerName: "Travelled Distance ",
        width: 150,            filterable: false,

        sortable: false,
        
        renderCell: (params) => {

          let diff = params.row.endKm - params.row.startKm
  
          let name = diff < 0 ? "NA" :diff 
  
          return (
  
            <Tooltip title={name}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
  
      {
        field: "vehicleType",
        headerName: "Vehicle Type",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.vehicleType||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
      {
        field: "startDetails",
        headerName: "Start Details",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.startDetails||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "endDetails",
        headerName: "End Details",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row?.endDetails||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "startLatLong",
        headerName: "Start Lat Long",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.startLat&&params.row?.startLong ?params.row?.startLat?.toString()+" , "+params.row?.startLong?.toString():"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "endLatLong",
        headerName: "End Lat Long",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row?.endLat&&params.row?.endLong?params.row?.endLat?.toString()+" , "+params.row?.endLong?.toString():"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
   
      {
        field: "endPic",
        headerName: "Actions",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let value =params.row?.endPic
          let value1 =params.row?.startPic

  
          return (
            <Stack direction={"row"} spacing={1}>
                  <Tooltip title="Click to view start image"><IconButton variant="extended" disabled={!value1} color="primary" onClick={(e) => {
              e.stopPropagation();
            openPackaging(value1);}} >
             <RemoveRedEyeIcon/>
            </IconButton></Tooltip>
            {/* {value1 && (
          <img
            src={value1}
            alt="Start Pic"
            style={{ cursor: "pointer" }}
            // onClick={() => openModal(value1)}
          />
        )} */}
  
            <Tooltip title="Click to view end image"><IconButton variant="extended" disabled={!value} color="primary" onClick={(e) => {
              e.stopPropagation();
            openPackaging(value);}} >
             <RemoveRedEyeIcon/>
            </IconButton></Tooltip>
            </Stack>
  
          )
  
        },
      },
 
  ];


  return result
}

const openPackaging = (val) => {
  window.open(val, '_blank');
};

const exportFetchAttendance = async () => {
  try {
    setLoading(true);
    let data = {
      startDate:startDate,
      endDate:endDate
    }
    
    let response = await window.Platform.database.exportFetchAttendance(data);
    console.log(response.data)

    // setDataForExportExcel(response.data)
    if (response.data && response.data.length > 0)  {

      const headers = [
        "Employee Name",
        "Start Time",
        "End Time",
        "Work Type",
        "Total Work Time",
        "Start Km",
        "End Km",
        "Travelled Distance",
        "Vehicle Type",
        "Start Details",
        "End Details",
        "Start Lat",
        "Start Long",
        "End Lat",
        "End Long",
        "Start Pic",
        "End Pic"
      ];

      // Map the data to match the header order
      const dataForExport = response.data.map(item => ({
        "Employee Name": item.name,
        "Start Time": item.formatted_start_time,
        "End Time": item.formatted_end_time,
        "Work Type": item.workType,
        "Total Work Time": item.Total_Work_Time,
        "Start Km": item.startKm,
        "End Km": item.endKm,
        "Travelled Distance": item.distance_difference<0?"N/A":item.distance_difference,
        "Vehicle Type": item.vehicleType,
        "Start Details": item.startDetails,
        "End Details": item.endDetails,
        "Start Lat": item.startLat,
        "Start Long": item.startLong,
        "End Lat": item.endLat,
        "End Long": item.endLong,
        "Start Pic": item.startPic,
        "End Pic":item.endPic
      }));

      // Create a worksheet from the data with headers
      const worksheet = XLSX.utils.json_to_sheet(dataForExport, { header: headers });

      // Create a workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Data");

      // Generate a binary Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Create a Blob from the Excel binary and download it
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Attendance_Data.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    }
    else{
      console.warn("No Data available for export")
    }
  } catch (error) {
    console.error("Error exporting attendance:", error);
    setLoading(false);
  }
};



  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport
          csvOptions={{
            fileName: "export-attendance",
            onlySelected: false
          }}
        /> */}
        <Button onClick={()=>{exportFetchAttendance()}}>Export</Button>
      </GridToolbarContainer>
    );
  }

  const onFilterChange= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterAttendance({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterAttendance({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };

  const renderContent = () => {
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          pageSizeOptions={[10]}
          onFilterModelChange={(val)=>
              {
          //       console.log("vvvv",val);
            
            
            
            if(val?.items[0]?.value?.length>0){
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
            } else if(!val.items[0]?.value   && startDate && endDate){
              setSearchTerm(null)
              onFilterChange()
              console.log("CHECKING...1")

            }
            else{
              fetchVisits()
              setSearchTerm(null)
              setFilter(false)
            }
          //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     fetchData()
          //     console.log("CHECK1")
          //   }
          //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          //     setSearchTerm(null)
  
          //     onFilterChange1()
          //     console.log("checkterr")
          //   }
          //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
  
  
          //   }
          //   else{
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
          //   }
          }
            
          }
          filterMode="server"
          apiRef={apiRef}
rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };



  const startDay=()=>{
    setAttendanceModal(true)
  }
  
  const endDay=()=>{
    setAttendanceModal1(true)
  }

  const formatDate=async(data)=>{
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
  
  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
  setStartDate1(formattedDate)
  setStartDate(formattedDate)
  console.log("CHECK!!")
  console.log("CHECKENDDATE",endDate)
  
  }
  const finalDateRangeFilter=async(data)=>{
    try{
      setLoading(true)
      setFilter(true)
  
    const datePickerResponse = new Date(data);
  
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
    
    if (data){
    setEndDate(formattedDate)
    setEndDate1(formattedDate)
  }
      if(searchTerm){
      const response = await window.Platform.database.filterAttendance({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
       
    }
      else{
      const response = await window.Platform.database.filterAttendance({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      
      }
      setLoading(false)
  
    }
    catch(err){
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
  
      fetchVisits()
    
    }
  }
  const clearDateFilter=async()=>{
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if(searchTerm){
      try{
      setLoading(true)
      const response = await window.Platform.database.filterAttendance({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
        
      setLoading(false)
    }
    catch(e){
      console.log(e)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      fetchVisits()  
  
      }
    }
  else{
    setFilter(false)
    fetchVisits()
  }
  }


  return (
    <>
      
      <StaffEditorPageContainer>
          {loading && <OpaqueLoading/>}
        <HeaderContainer>
          {/* <Typography variant="h5">Attendance Management</Typography> */}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} disableFuture={true} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} disableFuture={true} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>
            </div>
            <Stack spacing={1} direction={"row"}>
           {/* <FabStyle variant="extended" color="primary" size='small'

onClick={()=>startDay()}

>

<Add sx={{ mr: 1 }} />

Start Day

</FabStyle>
<FabStyle variant="extended" color="primary" size='small'

onClick={()=>endDay()}

>

<Add sx={{ mr: 1 }} />

End Day

</FabStyle> */}

<Button variant="contained" color="primary" 
             onClick={()=>startDay()} sx={{ mr: 1, borderRadius: '10px' }}>
  <Add sx={{ mr: 1 }}  />
  Start Day
</Button>

<Button variant="contained" color="primary" 
             onClick={()=>endDay()} sx={{ mr: 1, borderRadius: '10px' }}>
  <Add sx={{ mr: 1 }}  />
  End Day
</Button>

  </Stack>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
      attendanceModal && <AttendanceModal onClose={()=>setAttendanceModal(false)} fetchData={()=>fetchVisits()} status={true} />
    }
    {
      attendanceModal1 && <AttendanceModal onClose={()=>setAttendanceModal1(false)} fetchData={()=>fetchVisits()} status={false} />
    }
    </>
  );
}
