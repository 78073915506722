import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const SeriesCategory = ({ onClose,status,fetchData,propData }) => {
    console.log("propData",propData)
    const [form, setForm] = useState( {id:propData?.specimenId||"",
        
     itemId:propData?.itemId||"",
     discountedPrice:propData?.dicountedPrice||"",
     mrp:propData?.mrp||""
    });
  const [loading,setLoading]=useState(false)


    const [product,setProduct]=useState()
    const [selectedProduct,setSelectedProduct]=useState(null)

    useEffect(()=>{
        // console.log(loading)
        fetchProduct()
      },[])

      const fetchProduct=async(data)=>{
        const results = await window.Platform.database.fetchProduct(data);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    
        setProduct(jsonArrayWithId)
        setSelectedProduct(null)
        if(!status){
            const filteredEmp=results?.data?.filter((data)=>data.skuId===propData.itemId)
            console.log("PROPDATA",filteredEmp)
            setSelectedProduct(filteredEmp[0])
            
           }
    
       }
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };

    const submitHandler = async() => {
      // console.log("creating activity...", form);
      try{
        console.log("PRODUCT RESULTS: " , form)
        setLoading(true)
        // console.log("PLANTCODE",plantcode[0].plant_code__c)
        let results = await window.Platform.database.addSeriesCategory({...form});

        if (results.status) {
          window.NotificationUtils.showError(results?.message);
          setLoading(false);
          return;
        }
    
    // setProduct(results.data)
    window.NotificationUtils.showSuccess("Series added successfully")
    setLoading(false)
    fetchData()
    onClose()
      }
      catch(err){
        console.log(err);
    setLoading(false)
     
      }
console.log(form)
    };

    console.log("FORMMMMM",form)

  
    return (
      <StyledDialog disableEnforceFocus maxWidth="sm" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{status?"Add Series":"Edit Series"}</Typography>
          <IconButton  onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
           

            
    <Stack direction={"row"} spacing={2} padding={1}>
          
<TextField label="Series Name"   value={form?.seriesName||""} type="text" fullWidth  onChange={(e)=>changeHandler("seriesName",e.target.value)}/>
           </Stack>
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </div>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default SeriesCategory;
  