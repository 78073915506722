import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
  Chip
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const CollectionModal = ({ props,onClose,remarks,dataProp,id,distributorId,fetchData,status}) => {
  
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  // console.log("DATAAUTH",auth.user.plant_master__c );
 
  // const [details, setDetails] = useState(data);

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState([])
  // const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [form,setForm]=useState({



 })
 const [date,setDate]=useState()
 const [date1,setDate1]=useState()


 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState(null)
 const [party,setParty]=useState()
 const [selectParty,setSelectParty]=useState(null)

 const [product1,setProduct1]=useState([])
 

 const [board,setBoard]=useState([])
 const [medium,setMedium]=useState([])
 const [className,setClassName]=useState()
 const [series,setSeries]=useState()
 const [selectedSeries,setSelectedSeries]=useState(null)

 const [selectDataFilter,setSelectDataFilter]=useState({
     board:'',class:'',medium:''
 })

 const [disableSeries,setDisableSeries]=useState(false)
 const [disableOther,setDisableOther]=useState(false)

 const [selectedProduct,setSelectedProduct]=useState(null)
 const [dataGrid,setDataGrid]=useState([])
const [deletedIds,setDeletedIds]=useState([])
const [partyType,setPartyType]=useState()
const [fetchPartyTypes,setfetchedPartyType]=useState(0)
const [collectionIdForPayload, setCollectionIdForPayload] = useState('')


  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
   

 
useEffect(()=>{

fetchEmployees()
getGivenType()
getPaymentType()
getRecieveCollectionName()
getCollectiontype()
fetchPartyType()
},[])
 
useEffect(()=>{
if(!status){
setCollectionIdForPayload(dataProp.collectionId)
  fetchOrderLineItem(dataProp.orderId)
}
},[])


const fetchPartyType=async(details)=>{
  try{
    setLoading(true)
    let results = await window.Platform.database.fetchPartyType(details);
setPartyType(results.data)
  setLoading(false)

// setDetails(results.data[0]);
// setProduct(results.data)
}
catch(err){
  console.log(err);
setLoading(false)
 
  }
}

const fetchOrderLineItem=async(val)=>{
  try{
  
  let sentData={id:val}
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

  setDataGrid(jsonArrayWithId)
      setLoading(false)
  
  }
  catch(error){
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)
  
  }
  }
  
  const partyTypeHandler=(data)=>{
        if(data==="cQpLw8vwZf"){
          setfetchedPartyType(1)
          getSchool({ownerId:form?.ownerId})
    
        }else if(data==="P6E9TGXewd"){
          setfetchedPartyType(2)
    
    
          getDistributor({ownerId:form?.ownerId})
        }else{
          setfetchedPartyType(3)
    
        }
        changeHandler("partyType",data)
      }
  const getDistributor=async(data) => {
    setLoading(true)
    setParty([])
   const results = await window.Platform.database.getDistributor(data);
   setParty(results.data)
   setSelectParty(null)
  
   setLoading(false)
  
  }

  const formatDate=(data)=>{
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate(data)


changeHandler("collectionDate",unixTimestamp)
}
const formatDate1=(data)=>{
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate1(data)


changeHandler("depositDate",unixTimestamp)
}
const getPaymentType=async()=>{
  let results = await window.Platform.database.getPaymentType();
  setSeries(results.data)

}

const getCollectiontype=async()=>{
  let results = await window.Platform.database.getCollectiontype();
  setClassName(results.data)

}
const getGivenType=async()=>{
  let results = await window.Platform.database.getGivenType();
  setBoard(results.data)

}
const getRecieveCollectionName=async()=>{
  let results = await window.Platform.database.getRecieveCollectionName();
  setMedium(results.data)

}

const updateDataGrid = (selectedContainer) => {
  if (!selectDataFilter.board&&!selectDataFilter.medium&&!selectDataFilter.class) {
    // If no container is selected, display all data
    setProduct(product1);
  } else {
    // Filter the data based on the selected container
  //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
  const filteredData = product1.filter(item => {
      const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
      const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
      const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;

      // Return true only if the filter conditions are met based on filter presence
      return (
        (!selectDataFilter.board || boardMatch) &&
        (!selectDataFilter.medium || mediumMatch) &&
        (!selectDataFilter.class || classMatch)
      );
    });
  setProduct(filteredData);
  }
};

const changeHandler = (fieldName, value) => {
  if(fieldName==="phoneNo" ||fieldName==="amount"){
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  }
  else{
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  }
  };

  const getSchool=async(details)=>{
    try{
      setLoading(true)
      const results = await window.Platform.database.getSchool(details);
setParty(results.data)
setSelectParty(null)
  if(!status){
    const filteredEmp=results?.data?.filter((data)=>data.schoolId===dataProp.partyId)
    setSelectParty(filteredEmp[0])
    
   }
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }


const renderContent1=()=>{
  return (
    <>
    <div style={{ overflowY:"auto" }}>
   
        
          <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value)
                if(form.partyType&&fetchPartyTypes===1){

                   getSchool({ownerId:value?.id})
                }else if(form.partyType&&fetchPartyTypes===2){
                  getDistributor({ownerId:value?.id})
                }
 
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                required
                variant="outlined"
              />
            )}/>

<FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel   style={{ backgroundColor: 'white', padding: '2px'  }} >Recieve Payment</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.recievedCollectionType}
            onChange={(e) => changeHandler("recievedCollectionType",e.target.value)}
          >
           {medium?.map((option, optionIndex) => (
    <MenuItem key={option.recieveCollectionId} value={option.recieveCollectionId}>
      {option.recieveCollectionName}
    </MenuItem>
  ))}
       

          </Select>
        </FormControl> 
        <FormControl fullWidth>
      <InputLabel id="select-label">Party Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.partyType}
        onChange={(e)=>partyTypeHandler(e.target.value)}
        label="Select Option"
      >
        {partyType?.map((option) => (
        
            <MenuItem key={option.partyTypeId} value={option.partyTypeId}>
              {option.type}
            </MenuItem>
   ))} 
      </Select>
    </FormControl>
            



          </Stack>
          
        <Stack direction={"row"} spacing={2} padding={2}>
        {fetchPartyTypes===1?
    <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.schoolName} (${option?.schoolId})`}
            getOptionLabel={(option) => `${option?.schoolName}`}
              getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            disabled={fetchPartyTypes!=1||!form.ownerId}
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                changeHandler("partyId", value.schoolId)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                required
                variant="outlined"
              />
            )}/>
            :fetchPartyTypes===1?
            <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.DistributorName} (${option?.distributorID})`}
            getOptionLabel={(option) => `${option?.DistributorName}`}
              getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            disabled={fetchPartyTypes!=2}

            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                changeHandler("partyId", value.distributorID)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                required
                variant="outlined"
              />
            )}/>: fetchPartyTypes===3&&
<TextField label="Party Name" value={form?.otherPartyName} type="text" fullWidth onChange={(e)=>changeHandler("otherPartyName",e.target.value)}/>
            
            }
<TextField label="Invoice No" value={form?.invoiceId} type="text" fullWidth onChange={(e)=>changeHandler("invoiceId",e.target.value)}/>
            
<TextField label="Phone Number" value={form?.phoneNo} type="text" fullWidth onChange={(e)=>changeHandler("phoneNo",e.target.value)}/>
            
            
            </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
<TextField label="Amount" value={form?.amount} type="text" fullWidth onChange={(e)=>changeHandler("amount",e.target.value)}/>
<TextField label="Bank Name" value={form?.bankName} type="text" fullWidth onChange={(e)=>changeHandler("bankName",e.target.value)}/>
<TextField label="Ref No" value={form?.refNo} type="text" fullWidth onChange={(e)=>changeHandler("refNo",e.target.value)}/>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
        <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel   style={{ backgroundColor: 'white', padding: '2px'  }} >Payment Type</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.recievedVia}
            onChange={(e) => changeHandler("recievedVia",e.target.value)}
          >
           {series?.map((option, optionIndex) => (
    <MenuItem key={option.paymentTypeId} value={option.paymentTypeId}>
      {option.paymentName}
    </MenuItem>
  ))}
       

          </Select>
        </FormControl> <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel   style={{ backgroundColor: 'white', padding: '2px' }}>Type Of Collection</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.collectionType}
            onChange={(e) => changeHandler("collectionType",e.target.value)}
          >
         
         {className?.map((option, optionIndex) => (
    <MenuItem key={option.collectionTypeId} value={option.collectionTypeId}>
      {option.collectionTypeName}
    </MenuItem>
  ))}
    

          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel >Given Type</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.giventype}
            onChange={(e) => changeHandler("giventype",e.target.value)}
          >
         
         {board?.map((option, optionIndex) => (
    <MenuItem key={option.givenTypeId} value={option.givenTypeId}>
      {option.givenTypeName}
    </MenuItem>
  ))}
  

          </Select>
        </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DatePicker label="Collection Date" 
        value={date} sx={{width:"100%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Deposit Date" 
        value={date1} sx={{width:"100%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate1(data)} />
            </LocalizationProvider>
        <TextField label="Deposit Details" value={form?.depositDetails} type="text" fullWidth onChange={(e)=>changeHandler("depositDetails",e.target.value)}/>

           
</Stack>
        <Stack direction={"row"} spacing={2} padding={2}>


           
        </Stack>
    
      
         
     
      
</div>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>

<Button variant="contained" onClick={()=>onsubmit(form)} >
   Create
</Button>
</div>
</>
)}



const onEdit=async()=>{
  try{
    setLoading(true)
  const filteredArray = dataGrid.filter(element => Object.keys(element).every(key => key !== "orderLineItemId"));
const finalData={
  ...form,deletedRows:deletedIds,newRows:filteredArray,orderId:dataProp.orderId,totalAmount:calculateTotal(dataGrid)
}
const results = await window.Platform.database.updateOrderDetails(finalData);
setLoading(false)
onClose()
fetchData()
 
  }
  catch(err){
setLoading(false)

    console.log(err)
  }
}

const additem=()=>{
  const length=dataGrid.length+1
  let pushedItem={"qty":selectedProduct.qty,"price":selectedProduct.unitPrice,"total":selectedProduct.totalPrice,"itemId":selectedProduct.skuId,id:length,"itemName":selectedProduct.nameSku}
  setSelectedProduct(null)
  setDataGrid([...dataGrid,pushedItem])

}



const boardHandler=(data)=>{
  setDisableSeries(true)
    changeHandler("board",data)
    setSelectDataFilter((prevForm) =>({...prevForm,board:data}))
}
const mediumHandler=async(data)=>{
  setDisableSeries(true)
    changeHandler("medium",data)
    setSelectDataFilter((prevForm) =>({...prevForm,medium:data}))
}
const classHandler=(data)=>{
  setDisableSeries(true)
    changeHandler("classId",data)
    setSelectDataFilter((prevForm) =>({...prevForm,class:data}))
}





  const fetchEmployees=async(data)=>{
    setLoading(true)
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
    setAssignedEmp(null)
    if(!status){
      const filteredEmp=results?.data?.filter((data)=>data.id===dataProp.ownerId)
      setAssignedEmp(filteredEmp[0])
     }
    setLoading(false)

   }
   const calculateTotal= (selectedRows) => {
    if (!Array.isArray(selectedRows)) {
      return 0; // Default value if selectedRows is not an array
    }
    const totalWeight = selectedRows.reduce(
      (sum, row) => sum + (row.total? row.total:row.Total),
      0
    );
    return Math.ceil(totalWeight);
  };
 
  const onsubmit=async(data)=>{
    if(!form.ownerId||!form.recievedCollectionType||!form.partyType||!form.invoiceId
      ||!form.phoneNo||!form.amount||!form.bankName||!form.refNo||!form.recievedVia
      ||!form.collectionType||!form.giventype||!form.depositDetails||!form.collectionDate||!form.depositDate){
    window.NotificationUtils.showWarning("Please provide all required details!")
return;
      }
    try{
    setLoading(true)
    // const totalAmount=calculateTotal(dataGrid)
    
   const results = await window.Platform.database.addCollection({...form});
    
    window.NotificationUtils.showSuccess("Collection created Successfully!")
    setLoading(false)
    onClose()
    fetchData()

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }


      const handleDeleteEmploye = async (id) => {
    if(!status){
      let res1 = dataGrid.filter((el) => {
        return el.id === id;
      });
      if(res1[0]?.orderLineItemId){
      setDeletedIds([...deletedIds,res1[0].orderLineItemId])
      }
    }
        let res = dataGrid.filter((el) => {
          return el.id != id;
        });
    
        setDataGrid(res);
      };
    const clearData=()=>{
      console.log('clearData')
      setSelectedSeries(null)
      setDisableOther(false)
      setDisableSeries(false)
    setForm((prevForm) => ({ ...prevForm, board:null, medium: null, classId:null }));
    }
  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
        Add Collection
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Collection Details" />
           
            </StyledTabs>
            {tabValue==0&&renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default CollectionModal;