import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
// import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import GivenTypeModal from "../../components/modals/PicklistModals/givenTypeModal";
import NextStepModal from "../../components/modals/PicklistModals/nextStepModal";
import FeedbackModal from "../../components/modals/PicklistModals/feedbackModal";
import ContactPersonRoleModal from "../../components/modals/PicklistModals/contactPersonRoleModal";
import DaTypeModal from "../../components/modals/PicklistModals/datypeModal";
import MittplusProductGroupsModal from "../../components/modals/PicklistModals/mittplusProductGroupsModal";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
const isMobile = window.innerWidth < 900;



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: calc(100% );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function MittplusProductGroup() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [visitModal,setVisitModal]=useState(false);
const [editModal,setEditModal]=useState(false);
const [dataModal,setDataModal]=useState();
const [bulk,setBulk]=useState(false);


  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    // fetchTest()
    getSpecimen()
  }, []);
  useEffect(() => {
    console.log("checking...", accessChanges);
  }, [accessChanges]);

  const fetchTest = async() =>{
      let results1 = await window.Platform.database.testApi();

  }

  const getSpecimen= async () => {
    try {
      setLoading(true);
      // let results1 = await window.Platform.database.testApi();

      let results = await window.Platform.database.getMittplusProductGroups();
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK",error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const handleDelete = async (data) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this picklist item?");
    
    if (!isConfirmed) {
      return;
    }
  
    try {
      setLoading(true);
      let results = await window.Platform.database.deleteMittplusProductGroups({ id: data });
      setLoading(false);
      
      window.NotificationUtils.showSuccess("Mittplus product group deleted successfully!");
      getSpecimen();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };
  const getEmployeeColumns = () => {
  let result = [
    // {
    //   field: "nameSku",
    //   headerName: "ID",
    //   sortable: false,
    //   width: 150,
      
    //   editable: false,
    //   renderCell: (params) => {

    //     let name = params.row?.daTypeId

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    {
      field: "unitPrice",
      headerName: "Name",
      width: 200,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      valueGetter: (params) => {
        return (params.row?.name || "N/A");
      },
      renderCell: (params) => {

        let name =params.row.name

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
   
       
      
  //   {
  //     field: "Actions",
  //     headerName: "Actions",
  //     editable: false,
  //     hide: true,
  //     minWidth: 150,
  //     renderCell: (params) => {
  //         return (
  //             <>
  //               <Tooltip title={"Update"}>
  //                   <IconButton onClick={() => handleEdit(params.row)}>
  //                     <DriveFileRenameOutlineIcon/>
  //                   </IconButton>
  //               </Tooltip>
  //               <Tooltip title={"Delete"}>
  //                   <IconButton onClick={()=> handleDelete(params.row.id) }>
  //                     <DeleteIcon />
  //                   </IconButton>
  //               </Tooltip>
  //             </>
  //         )
  //     },
  // }
 
  ];


  return result
}

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridContainer>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={getEmployeeColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSizeOptions={[10]}
        />
        </DataGridContainer>
      </Box>
    );
  };



const addVisit=()=>{
  setVisitModal(true)
}
const handleEdit=(data)=>{
setEditModal(true)
setDataModal(data)


}

  return (
    <>
      {isMobile && (
        <Drawer
        // setShowNotifications={props.setShowNotifications}
        // onAuthUserChanged={props.onAuthUserChanged}
        // user={props.user}
        />
      )}
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading/>}
        <HeaderContainer>
        <div>
          
            {/* <FabStyle> */}
            {/* <FabStyle variant="extended" color="primary" 
            onClick={()=> setShowWarehouseFormModal(' ')}
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add New
</FabStyle>  */}

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
          </div>
          {/* <Typography variant="h5">Visits Management</Typography> */}
 <Stack direction={"row"} spacing={2} >
          
          {/* <FabStyle variant="extended" color="primary" size='small'

onClick={()=>setBulk(true)}

>

<Add sx={{ mr: 1 }} />

Bulk Add

</FabStyle> */}
          {/* <FabStyle variant="extended" color="primary" size='small'

onClick={()=>addVisit()}

>

<Add sx={{ mr: 1 }} />

Add New

</FabStyle> */}
{/* <Button variant="contained" color="primary" 
              onClick={()=> addVisit()} sx={{ mr: 1, borderRadius: '10px' }}>
              <Add sx={{ mr: 1 }}  />
              Add New
            </Button> */}
</Stack>
         
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
      visitModal &&
      <MittplusProductGroupsModal
     
      onClose={() => setVisitModal(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>getSpecimen()}
      status={true}
    //   onSubmit={updateWarehouseData}
      />
    }
     {
      editModal &&
      <MittplusProductGroupsModal
      onClose={() => setEditModal(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>getSpecimen()}
      status={false}
      propData={dataModal}
   />
    }
   
    </>
  );
}
