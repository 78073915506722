// import * as Parse from "parse";
import axios from "axios";

// Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);

// Parse.serverURL = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  // baseURL:"https://3p02javqgk.execute-api.ap-south-1.amazonaws.com/" ,
  // baseURL:"http://localhost:3001",
  // baseURL: "https://mittsure.qdegrees.com:3001",
  baseURL: "https://mittsureone.mittsureone.com:3001",
  // baseURL:"https://mittsure-one-backend.vercel.app",

  headers: {
    'Content-Type': 'application/json'
    // Add your other headers here
    // 'X-Custom-Header': 'value',parse
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const sessionToken=localStorage.getItem('token')

    // Set the token in the 'cookie' header
    //console.log('Request Headers:', config.headers);
    config.headers['token'] = `token=${sessionToken}`;
    //console.log('Request Headers:', config.headers);
    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);

const axiosInstance1 = axios.create({
  baseURL:"https://mittsure.qdegrees.com:3001`" ,
  // baseURL:"https://3p02javqgk.execute-api.ap-south-1.amazonaws.com/",
  headers: {
    'Content-Type': 'multipart/form-data', // Change content type for file upload
    // Add your other headers here
    // 'X-Custom-Header': 'value',
  },

});
axiosInstance1.interceptors.request.use(
  (config) => {
    const sessionToken=localStorage.getItem('token')

    // Set the token in the 'cookie' header
    //console.log('Request Headers:', config.headers);
    config.headers['token'] = `token=${sessionToken}`;
    //console.log('Request Headers:', config.headers);
    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);

  const login = async (data) => {
    try {
      //console.log("hhelo")

      const response = await axiosInstance.post('/user/signin', {
        username: data.email,
        password: data.password
      });
  
      //console.log(response);
      // Assuming your server returns the user data upon successful login
      const user = response.data;
      return user;
    } catch (error) {
      //console.error(error);
      throw error;
    }
    
  };

  const verifyUserSession = async (data) => {
    try {
      //console.log("hhelo",data)

     const response = await axiosInstance.post(
  `/user/verifyUser?token=${(data)}`
  
);
      //console.log('tessting',response);
      // Assuming your server returns the user data upon successful login
      const user = response.data;
      return user;
    } catch (error) {
      //console.error(error);
      throw error;
    }
    
  };
  const signout = async (data) => {
    try {
      //console.log("hhelo",data)

     const response = await axiosInstance.post(
  `/user/signout`
  
);
      //console.log('tessting',response);
      // Assuming your server returns the user data upon successful login
      const user = response.data;
      return user;
    } catch (error) {
      //console.error(error);
      throw error;
    }
    
  };
  








  const fetchEmployees = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/getUsers`,{status:data});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getUsersForWork = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/getUsersForWork`,{status:data});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchLeadSource = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/getLeadSource`, { status : data });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const addLeadSource = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/addLeadSource`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateLeadSource = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/updateLeadSource`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteLeadSource = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteLeadSource`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchLeadRating = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/getLeadRating`, { status : data });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const addLeadRating = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/addLeadRating`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

const addDistributorToStockist = async data => {
  try {
    const response = await axiosInstance.post(`/party/addDistributorToStockist`, data);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Request failed with status: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};
  const updateLeadRating = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/updateLeadRating`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteLeadRating = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteLeadRating`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchLeadStatus = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/getLeadStatus`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const addLeadStatus = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/addLeadStatus`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateLeadStatus = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/updateLeadStatus`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteLeadStatus = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteLeadStatus`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  };
  
  const getUsersFieldRole = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/getUsersFieldRole`,{status:data});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchVisits = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/visit/fetchVisit`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  
  const fetchDashboardUsers = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardUsers`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  

    
  const fetchDashboardSchool = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardSchool`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardSchoolErpOnboard = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardSchoolErpOnboard`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardTotalRevenueOrder = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardTotalRevenueOrder`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const getExpenseType = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getExpenseType`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const getExpensePurpose = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getExpensePurpose`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getLanguage = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getLanguage`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getDocumentListSchool = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getDocumentListSchool`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getAuthor = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getAuthor`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getSchoolCategory = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getSchoolCategory`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchDashboardDistributor = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardDistributor`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  

  const fetchDashboardProductRank = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardProductRank`,{startDay:start, endDay:end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardVisitVsOrderUser = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardVisitVsOrderUser`, {startDay:start, endDay:end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardDistributorOnboardingList = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardDistributorOnboardingList`, {startDay:start, endDay:end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchDashboardSchoolOnboardingList = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardSchoolOnboardingList`, {startDay: start, endDay: end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardEmployeeWithRevenue = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardEmployeeWithRevenue`,{startDay: start, endDay: end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchDashboardTotalEarningMonthWise = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardTotalEarningMonthWise`,{startDay: start, endDay: end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchDashboardTotalEarningSchoolMonthWise = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardTotalEarningSchoolMonthWise`,{startDay: start, endDay: end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchDashboardOrderVsSpecimen = async (start, end, school, user) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardOrderVsSpecimen`, {startDay: start, endDay: end, partyId: school, userId: user});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  
  const fetchDashboardTotalEarningDistributorMonthWise = async (start, end) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/dashboard/fetchDashboardTotalEarningDistributorMonthWise`, {startDay: start, endDay: end});

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  
  const fetchAttendance = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/attendance/fetchAttendance`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const exportFetchAttendance = async (data) => {
    try {
      const response = await axiosInstance.post(`/attendance/exportFetchAttendance`,data);

    
    if (response.status === 200) {
     
      return response.data;
    } else {
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      throw error;
    }
  };

  const fetchOrder = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/order/fetchOrder`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchOrderLineItem = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/order/fetchOrderItem`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const approverejectOrder = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/order/updateOrder`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getStockistForDropDown = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/getStockistForDropDown`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getStockist = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/getStockistWeb`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const getSchool = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/getSchool`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  
  const getDistributor = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/getDistributorWeb`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addSchool = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/addPartySchool`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addExpenseType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/addExpenseType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateExpenseType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/updateExpenseType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteExpenseType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/deleteExpenseType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const editPartySchool = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/editPartySchool`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addDistributor = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/addDistributor`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const editDistributor = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/editDistributor`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const editStockist = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/editStockist`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const approveRejectParty = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/approveRejectParty`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getUserById = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/getUsersById`,{"id": data});
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchPinCode = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/address/getAddress`,{pincode: data});
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const createNewUser = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/createNewUser`,data);
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const editUser = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/editUser`,{id:data.id ,data});
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const deleteUser = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/deleteUser`,{id:data.id ,data});
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const activateUser = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/activateUser`,{id:data.id});
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const fetchRoles = async () => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/role/getRole`);
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const getRole = async (data) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/user/getRoleUser`,data);

      return response.data;
    // } else {
    //   // If the request was not successful, handle the error
    //   throw new Error(`Request failed with status: ${response.status}`);
    // }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  
  const addRole = async (data) => {
    try {
      // //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/role/addRole`, data);
//console.log("responsse", response)

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };



    
  const getWorkType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/fetchWorkType`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addWorkType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/addWorkType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateWorkType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/updateWorkType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteWorkType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/deleteWorkType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const startAttendance = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/attendance/startDay`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const endAttendance = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/attendance/endDay`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const fetchPartyType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/fetchPartyType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addPartyType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/addPartyType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updatePartyType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/updatePartyType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deletePartyType = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/deletePartyType`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getSubject = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getSubject`);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addSubject = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/addSubject`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  
  const updateSubject = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/updateSubject`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteSubject = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/deleteSubject`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getClass = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/getClass`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addClass = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/addClass`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateClass = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/updateClass`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteClass = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/picklist/deleteClass`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const fetchProduct = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/product/getProduct`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getMittplusProductGroups = async () => {
    try {
      const response = await axiosInstance.post(`/picklist/getProductTypeMittplus`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }
  
  const addMittplusProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addProductTypeMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateMittplusProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateProductTypeMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteMittplusProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteProductTypeMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getProductGroups = async () => {
    try {
      const response = await axiosInstance.post(`/picklist/getProductGroup`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addProductGroup`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateProductGroup`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteProductGroups = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteProductGroup`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getProducts = async (data) => {
    const payload = {
      "productType": data?.id
    }
    try {
      const response = await axiosInstance.post(`/product/getProductMittplusByProductType`, payload);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getReturnReasons = async () => {
    try {
      const response = await axiosInstance.post(`/picklist/getReturnReason`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addReturnReasons = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addReturnReason`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateReturnReasons = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateReturnReason`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteReturnReasons = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteReturnReason`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addSchoolCategory = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addSchoolCategory`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateSchoolCategory = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateSchoolCategory`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

const deleteDistributor = async (data) => {
  try {
    const response = await axiosInstance.post(`/party/deleteDistributor`, data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Request failed with status: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
}
  const deleteSchool = async (data) => {
    try {
      const response = await axiosInstance.post(`/party/deleteSchool`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteStockist = async (data) => {
    try {
      const response = await axiosInstance.post(`/party/deleteStockist`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteSchoolCategory = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteSchoolCategory`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addDocumentListSchool = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addDocumentListSchool`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateDocumentListSchool = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateDocumentListSchool`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteDocumentListSchool = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteDocumentListSchool`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addDeliveryStatus = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/addDeliveryStatus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateDeliveryStatus = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/updateDeliveryStatus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteDeliveryStatus = async (data) => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteDeliveryStatus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addMittplusProduct = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/addProductMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateProduct = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/updateProduct`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateMittplusProduct = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/updateProductMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteMittplusProduct = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/deleteProductMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteTransporter = async (data) => {
    try {
      const response = await axiosInstance.post(`/party/deleteTransporter`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteIncentive = async (data) => {
    try {
      const response = await axiosInstance.post(`/order/deleteIncentive`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const editIncentive = async (data) => {
    try {
      const response = await axiosInstance.post(`/order/editIncentive`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }


  const deleteSeriesSet = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/deleteSeriesSet`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteProduct = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/deleteProduct`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const fetchProductMittplus = async (data) => {
    try {
      const response = await axiosInstance.post(`/product/getProductMittplus`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getForecastReport = async (endpoint, payload = null) => {
    try {
      let response;
      if (payload) {
        response = await axiosInstance.post(`/dashboard/${endpoint}`, payload);
      } else {
        response = await axiosInstance.post(`/dashboard/${endpoint}`);
      }
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getFinancialReport = async (endpoint, payload = null) => {
    try {
      let response;
      if (payload) {
        response = await axiosInstance.post(`/dashboard/${endpoint}`, payload);
      } else {
        response = await axiosInstance.post(`/dashboard/${endpoint}`);
      }
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getPipelineReport = async (endpoint, payload = null) => {
    try {
      let response;
      if (payload) {
        response = await axiosInstance.post(`/dashboard/${endpoint}`, payload);
      } else {
        response = await axiosInstance.post(`/dashboard/${endpoint}`);
      }
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getOrderReport = async (endpoint, payload = null) => {
    try {
      let response;
      if (payload) {
        response = await axiosInstance.post(`/dashboard/${endpoint}`, payload);
      } else {
        response = await axiosInstance.post(`/dashboard/${endpoint}`);
      }
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getReturnOrderLineItemByReturnReasonId = async (payload) => {
    try {
      const response = await axiosInstance.post(`/dashboard/fetchReturnOrderLineItemByReturnReasonId`, payload);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addVisit = async (data) => {
    try {
      const response = await axiosInstance.post(`/visit/addVisitOfflineandDashboard`, data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateVisit = async (data,data1) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/visit/updateVisit`,data);


    // Check if the request was successful (status code 200)
    if (response.status === 200) {

      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const testApi = async (data) => {
    try {
      // //console.log("hellomamn",data)
      const data={

        "date": "1703818761",
       "oldPartyArray": ["R1dHetFQ8N","4LaHZvAG4O"],
       "newPartyArray": ["new1221661612"]
   }
      const response = await axiosInstance.post(`/routeplan/addrouteplan`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);

      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  
  const getBoard=async()=>{
try{
    const response = await axiosInstance.post(`/picklist/getBoard`);

  // Check if the request was successful (status code 200)
  if (response.status === 200) {
    // Return the data from the response
    //console.log("TESTAPIRUN", response.data);

    return response.data;
  } else {
    // If the request was not successful, handle the error
    throw new Error(`Request failed with status: ${response.status}`);
  }
  } catch (error) {
    //console.error(error);
    throw error;
  }
  }
  const addBoard=async(data)=>{
    try{
        const response = await axiosInstance.post(`/picklist/addBoard`,data);
    
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Return the data from the response
        //console.log("TESTAPIRUN", response.data);
    
        return response.data;
      } else {
        // If the request was not successful, handle the error
        throw new Error(`Request failed with status: ${response.status}`);
      }
      } catch (error) {
        //console.error(error);
        throw error;
      }
      }
  const updateBoard=async(data)=>{
    try{
        const response = await axiosInstance.post(`/picklist/updateBoard`,data);
    
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Return the data from the response
        //console.log("TESTAPIRUN", response.data);
    
        return response.data;
      } else {
        // If the request was not successful, handle the error
        throw new Error(`Request failed with status: ${response.status}`);
      }
      } catch (error) {
        //console.error(error);
        throw error;
      }
      }
  const deleteBoard=async(data)=>{
    try{
        const response = await axiosInstance.post(`/picklist/deleteBoard`,data);
    
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Return the data from the response
        //console.log("TESTAPIRUN", response.data);
    
        return response.data;
      } else {
        // If the request was not successful, handle the error
        throw new Error(`Request failed with status: ${response.status}`);
      }
      } catch (error) {
        //console.error(error);
        throw error;
      }
      }
  const getMedium=async()=>{
    try{
        const response = await axiosInstance.post(`/picklist/getMedium`);
    
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Return the data from the response
        //console.log("TESTAPIRUN", response.data);
    
        return response.data;
      } else {
        // If the request was not successful, handle the error
        throw new Error(`Request failed with status: ${response.status}`);
      }
      } catch (error) {
        //console.error(error);
        throw error;
      }
      }

      const addMedium=async(data)=>{
        try{
            const response = await axiosInstance.post(`/picklist/addMedium`,data);
        
          // Check if the request was successful (status code 200)
          if (response.status === 200) {
            // Return the data from the response
            //console.log("TESTAPIRUN", response.data);
        
            return response.data;
          } else {
            // If the request was not successful, handle the error
            throw new Error(`Request failed with status: ${response.status}`);
          }
          } catch (error) {
            //console.error(error);
            throw error;
          }
          }
      const updateMedium=async(data)=>{
        try{
            const response = await axiosInstance.post(`/picklist/updateMedium`,data);
        
          // Check if the request was successful (status code 200)
          if (response.status === 200) {
            // Return the data from the response
            //console.log("TESTAPIRUN", response.data);
        
            return response.data;
          } else {
            // If the request was not successful, handle the error
            throw new Error(`Request failed with status: ${response.status}`);
          }
          } catch (error) {
            //console.error(error);
            throw error;
          }
          }
      const deleteMedium=async(data)=>{
        try{
            const response = await axiosInstance.post(`/picklist/deleteMedium`,data);
        
          // Check if the request was successful (status code 200)
          if (response.status === 200) {
            // Return the data from the response
            //console.log("TESTAPIRUN", response.data);
        
            return response.data;
          } else {
            // If the request was not successful, handle the error
            throw new Error(`Request failed with status: ${response.status}`);
          }
          } catch (error) {
            //console.error(error);
            throw error;
          }
          }

      const fetchSeries=async()=>{
        try{
            const response = await axiosInstance.post(`/product/fetchSeries`);
        
          // Check if the request was successful (status code 200)
          if (response.status === 200) {
            // Return the data from the response
            //console.log("TESTAPIRUN", response.data);
        
            return response.data;
          } else {
            // If the request was not successful, handle the error
            throw new Error(`Request failed with status: ${response.status}`);
          }
          } catch (error) {
            //console.error(error);
            throw error;
          }
          }

          const fetchProductById=async(data)=>{
            try{
                const response = await axiosInstance.post(`/product/fetchProductById`,data);
            
              // Check if the request was successful (status code 200)
              if (response.status === 200) {
                // Return the data from the response
                //console.log("TESTAPIRUN", response.data);
            
                return response.data;
              } else {
                // If the request was not successful, handle the error
                throw new Error(`Request failed with status: ${response.status}`);
              }
              } catch (error) {
                //console.error(error);
                throw error;
              }
              }

              const addSeries=async(data)=>{
                try{
                    const response = await axiosInstance.post(`/product/addSeries`,data);
                
                  // Check if the request was successful (status code 200)
                  if (response.status === 200) {
                    // Return the data from the response
                    //console.log("TESTAPIRUN", response.data);
                
                    return response.data;
                  } else {
                    // If the request was not successful, handle the error
                    throw new Error(`Request failed with status: ${response.status}`);
                  }
                  } catch (error) {
                    //console.error(error);
                    throw error;
                  }
                  }


              const createOrder=async(data)=>{
                try{
                  const response = await axiosInstance.post(`/order/createOrder`,data);
              
                // Check if the request was successful (status code 200)
                if (response.status === 200) {
                  // Return the data from the response
                  //console.log("TESTAPIRUN", response.data);
              
                  return response.data;
                } else {
                  // If the request was not successful, handle the error
                  throw new Error(`Request failed with status: ${response.status}`);
                }
                } catch (error) {
                  //console.error(error);
                  throw error;
                }
              }    


              const updateRoleAccess=async(data)=>{
                try{
                  const response = await axiosInstance.post(`/role/updateRoleAccess`,data);
              
                // Check if the request was successful (status code 200)
                if (response.status === 200) {
                  // Return the data from the response
                  //console.log("TESTAPIRUN", response.data);
              
                  return response.data;
                } else {
                  // If the request was not successful, handle the error
                  throw new Error(`Request failed with status: ${response.status}`);
                }
                } catch (error) {
                  //console.error(error);
                  throw error;
                }
              }    
              const fetchProduct1=async(data)=>{
                try{
                    const response = await axiosInstance.post(`/product/fetchProduct`, data);
                
                  // Check if the request was successful (status code 200)
                  if (response.status === 200) {
                    // Return the data from the response
                    //console.log("TESTAPIRUN", response.data);
                
                    return response.data;
                  } else {
                    // If the request was not successful, handle the error
                    throw new Error(`Request failed with status: ${response.status}`);
                  }
                  } catch (error) {
                    //console.error(error);
                    throw error;
                  }
                  }
                  const addProduct=async(data)=>{
                    try{
                        const response = await axiosInstance.post(`/product/addProduct`,data);
                    
                      // Check if the request was successful (status code 200)
                      if (response.status === 200) {
                        // Return the data from the response
                        //console.log("TESTAPIRUN", response.data);
                    
                        return response.data;
                      } else {
                        // If the request was not successful, handle the error
                        throw new Error(`Request failed with status: ${response.status}`);
                      }
                      } catch (error) {
                        //console.error(error);
                        throw error;
                      }
                      }

                      const addIncentiveNew=async(data)=>{
                        try{
                            const response = await axiosInstance.post(`/order/addIncentiveNew`,data);
                        
                          // Check if the request was successful (status code 200)
                          if (response.status === 200) {
                            // Return the data from the response
                            //console.log("TESTAPIRUN", response.data);
                        
                            return response.data;
                          } else {
                            // If the request was not successful, handle the error
                            throw new Error(`Request failed with status: ${response.status}`);
                          }
                          } catch (error) {
                            //console.error(error);
                            throw error;
                          }
                          }

                      const addProductBulk=async(data)=>{
                        try{
                            const response = await axiosInstance.post(`/product/addProductBulk`,data);
                        
                          // Check if the request was successful (status code 200)
                          if (response.status === 200) {
                            // Return the data from the response
                            //console.log("TESTAPIRUN", response.data);
                        
                            return response.data;
                          } else {
                            // If the request was not successful, handle the error
                            throw new Error(`Request failed with status: ${response.status}`);
                          }
                          } catch (error) {
                            //console.error(error);
                            throw error;
                          }
                          }   
                          
                      const getSpecimen=async(data)=>{
                        try{
                            const response = await axiosInstance.post(`/product/getSpecimen`,data);
                        
                          // Check if the request was successful (status code 200)
                          if (response.status === 200) {
                            // Return the data from the response
                            //console.log("TESTAPIRUN", response.data);
                        
                            return response.data;
                          } else {
                            // If the request was not successful, handle the error
                            throw new Error(`Request failed with status: ${response.status}`);
                          }
                          } catch (error) {
                            //console.error(error);
                            throw error;
                          }
                          } 
                          
                          const addSpecimen=async(data)=>{
                            try{
                                const response = await axiosInstance.post(`/product/addSpecimen`,data);
                            
                              // Check if the request was successful (status code 200)
                              if (response.status === 200) {
                                // Return the data from the response
                                //console.log("TESTAPIRUN", response.data);
                            
                                return response.data;
                              } else {
                                // If the request was not successful, handle the error
                                throw new Error(`Request failed with status: ${response.status}`);
                              }
                              } catch (error) {
                                //console.error(error);
                                throw error;
                              }
                              } 

                          const updateSpecimen=async(data)=>{
                            try{
                                const response = await axiosInstance.post(`/product/updateSpecimen`,data);
                            
                              // Check if the request was successful (status code 200)
                              if (response.status === 200) {
                                // Return the data from the response
                                //console.log("TESTAPIRUN", response.data);
                            
                                return response.data;
                              } else {
                                // If the request was not successful, handle the error
                                throw new Error(`Request failed with status: ${response.status}`);
                              }
                              } catch (error) {
                                //console.error(error);
                                throw error;
                              }
                              } 

                          const deleteSpecimen=async(data)=>{
                            try{
                                const response = await axiosInstance.post(`/product/deleteSpecimen`,data);
                            
                              // Check if the request was successful (status code 200)
                              if (response.status === 200) {
                                // Return the data from the response
                                //console.log("TESTAPIRUN", response.data);
                            
                                return response.data;
                              } else {
                                // If the request was not successful, handle the error
                                throw new Error(`Request failed with status: ${response.status}`);
                              }
                              } catch (error) {
                                //console.error(error);
                                throw error;
                              }
                              } 


                          const getRoutePlan=async(data)=>{
                            try{
                                const response = await axiosInstance.post(`/routeplan/getRoutePlan`,data);
                            
                              // Check if the request was successful (status code 200)
                              if (response.status === 200) {
                                // Return the data from the response
                                //console.log("TESTAPIRUN", response.data);
                            
                                return response.data;
                              } else {
                                // If the request was not successful, handle the error
                                throw new Error(`Request failed with status: ${response.status}`);
                              }
                              } catch (error) {
                                //console.error(error);
                                throw error;
                              }
                              }  
                              const getRouteplanById=async(data)=>{
                                try{
                                    const response = await axiosInstance.post(`/routeplan/getRouteplanById`,data);
                                
                                  // Check if the request was successful (status code 200)
                                  if (response.status === 200) {
                                    // Return the data from the response
                                    //console.log("TESTAPIRUN", response.data);
                                
                                    return response.data;
                                  } else {
                                    // If the request was not successful, handle the error
                                    throw new Error(`Request failed with status: ${response.status}`);
                                  }
                                  } catch (error) {
                                    //console.error(error);
                                    throw error;
                                  }
                                  } 
                                  
                                  
                                  const approveRoute=async(data)=>{
                                    try{
                                        const response = await axiosInstance.post(`/routeplan/approveRoute`,data);
                                    
                                      // Check if the request was successful (status code 200)
                                      if (response.status === 200) {
                                        // Return the data from the response
                                        //console.log("TESTAPIRUN", response.data);
                                    
                                        return response.data;
                                      } else {
                                        // If the request was not successful, handle the error
                                        throw new Error(`Request failed with status: ${response.status}`);
                                      }
                                      } catch (error) {
                                        //console.error(error);
                                        throw error;
                                      }
                                      }

                                      const getPartyLead=async(data)=>{
                                    try{
                                        const response = await axiosInstance.post(`/routeplan/getPartyLead`,data);
                                    
                                      // Check if the request was successful (status code 200)
                                      if (response.status === 200) {
                                        // Return the data from the response
                                        //console.log("TESTAPIRUN", response.data);
                                    
                                        return response.data;
                                      } else {
                                        // If the request was not successful, handle the error
                                        throw new Error(`Request failed with status: ${response.status}`);
                                      }
                                      } catch (error) {
                                        //console.error(error);
                                        throw error;
                                      }
                                      } 


                                      const addRoutePlan=async(data)=>{
                                        try{
                                            const response = await axiosInstance.post(`/routeplan/addRoutePlan`,data);
                                        
                                          // Check if the request was successful (status code 200)
                                          if (response.status === 200) {
                                            // Return the data from the response
                                            //console.log("TESTAPIRUN", response.data);
                                        
                                            return response.data;
                                          } else {
                                            // If the request was not successful, handle the error
                                            throw new Error(`Request failed with status: ${response.status}`);
                                          }
                                          } catch (error) {
                                            //console.error(error);
                                            throw error;
                                          }
                                          } 

                                          const updateOrderDetails=async(data)=>{
                                            try{
                                                const response = await axiosInstance.post(`/order/updateOrderDetails`,data);
                                            
                                              // Check if the request was successful (status code 200)
                                              if (response.status === 200) {
                                                // Return the data from the response
                                                //console.log("TESTAPIRUN", response.data);
                                            
                                                return response.data;
                                              } else {
                                                // If the request was not successful, handle the error
                                                throw new Error(`Request failed with status: ${response.status}`);
                                              }
                                              } catch (error) {
                                                //console.error(error);
                                                throw error;
                                              }
                                              } 
    
                      

  const fetchReportingManagerRole = async(data) => {
    try{
      const response = await axiosInstance.post(`/role/fetchReportingManagerRole`,{data});
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  

  const getUsersOnRole = async(data) => {
    try{
      const response = await axiosInstance.post(`/role/getUsersOnRole`,{data});
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getCollection = async(data) => {
    try{
      const response = await axiosInstance.post(`/collection/getCollection`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addCollection = async(data) => {
    try{
      const response = await axiosInstance.post(`/collection/addCollection`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const approveRejectCollection = async(data) => {
    try{
      const response = await axiosInstance.post(`/collection/approveRejectCollection`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getGivenType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/getGivenType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const addGivenType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/addGivenType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateGivenType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/updateGivenType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteGivenType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/deleteGivenType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getPaymentType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/getPaymentType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addPaymentType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/addPaymentType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updatePaymentType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/updatePaymentType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deletePaymentType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/deletePaymentType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterSchool = async(data) => {
    try{
      const response = await axiosInstance.post(`/party/filterSchool`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterDistributor = async(data) => {
    try{
      const response = await axiosInstance.post(`/party/filterDistributor`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterVisit = async(data) => {
    try{
      const response = await axiosInstance.post(`/visit/filterVisit`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterAttendance = async(data) => {
    try{
      const response = await axiosInstance.post(`/attendance/filterAttendance`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterOrder = async(data) => {
    try{
      const response = await axiosInstance.post(`/order/filterOrder`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };
  const filterOrderDistributor = async(data) => {
    try{
      const response = await axiosInstance.post(`/order/filterOrderDistributor`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterRoutePlan = async(data) => {
    try{
      const response = await axiosInstance.post(`/routeplan/filterRoutePlan`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const filterCollection = async(data) => {
    try{
      const response = await axiosInstance.post(`/collection/filterCollection`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  const getSeriesCategory = async(data) => {
    try{
      const response = await axiosInstance.post(`/product/getSeriesCategory`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addSeriesCategory = async(data) => {
    try{
      const response = await axiosInstance.post(`/product/addSeriesCategory`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateSeriesCategory = async(data) => {
    try{
      const response = await axiosInstance.post(`/product/updateSeriesCategory`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteSeriesCategory = async(data) => {
    try{
      const response = await axiosInstance.post(`/product/deleteSeriesCategory`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getStatusType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/getStatusType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addStatusType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/addStatusType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateStatusType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/updateStatusType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteStatusType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/deleteStatusType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


const deleteCollection = async(data) => {
  try{
    const response = await axiosInstance.post(`/collection/deleteCollection`,data);

  // Check if the request was successful (status code 200)
  if (response.status === 200) {
    // Return the data from the response
    //console.log("TESTAPIRUN", response.data);

    return response.data;
  } else {
    // If the request was not successful, handle the error
    throw new Error(`Request failed with status: ${response.status}`);
  }
  } catch (error) {
    //console.error(error);
    throw error;
  }
};
  const getVehicleType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/getVehicleType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const addVehicleType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/addVehicleType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const updateVehicleType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/updateVehicleType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const deleteVehicleType = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/deleteVehicleType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };


  // For DropDown
  const getLeadParty=async(data)=>{
    try{
      const response = await axiosInstance.post(`/routeplan/getPartyLead`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


    const getPartyLeadAdmin=async(data)=>{
    try{
      const response = await axiosInstance.post(`/routeplan/getPartyLeadAdmin`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getTransporters = async data => {
    try {
      const response = await axiosInstance.post(`/party/getTransporter`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getincentivelist = async data => {
    try {
      const response = await axiosInstance.post(`/order/getincentivelist`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getSchoolCategories = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/getSchoolCategory`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const getVisitOutcomes = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/getVisitOutcome`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addVisitOutcomes = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/addVisitOutcome`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateVisitOutcomes = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/updateVisitOutcome`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteVisitOutcomes = async data => {
    try {
      const response = await axiosInstance.post(`/picklist/deleteVisitOutcome`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const rejectVisit = async data => {
    try {
      const response = await axiosInstance.post(`/visit/rejectVisit`, data);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const addLeadBulk=async(data)=>{
    try{
      const response = await axiosInstance.post(`/routeplan/addLeadBulk`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addLeadAdmin=async(data)=>{
    try{
      const response = await axiosInstance.post(`/routeplan/addLeadAdmin`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addTransporter = async data => {
    try {
      const response = await axiosInstance.post(`/party/addTransporter`, data);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const updateTransporter = async data => {
    try {
      const response = await axiosInstance.post(`/party/updateTransporter`, data);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  const deleteLead=async(data)=>{
    try{
      const response = await axiosInstance.post(`/routeplan/deleteLead`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const fetchAttendanceForReport=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/fetchAttendanceForReport`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const resetDeviceId=async(data)=>{
    try{
      const response = await axiosInstance.post(`/user/resetDeviceId`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const getSchoolForDropDown=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/getSchoolForDropDown`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const fetchLogs=async(data)=>{
    try{
      const response = await axiosInstance.post(`/logs/fetchLogs`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const getWorkDone=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getWorkDone`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const addWorkDone=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addWorkDone`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateWorkDone=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateWorkDone`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteWorkDone=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteWorkDone`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getNextStep=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getNextStep`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addNextStep=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addNextStep`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateNextStep=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateNextStep`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteNextStep=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteNextStep`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getFeedback=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getFeedback`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const addFeedback=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addFeedback`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const updateFeedback=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateFeedback`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteFeedback=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteFeedback`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getContactPersonRole=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getContactPersonRole`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addContactPersonRole=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addContactPersonRole`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateContactPersonRole=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateContactPersonRole`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const deleteContactPersonRole=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteContactPersonRole`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getGrade=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getGrade`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addGrade=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addGrade`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateGrade=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateGrade`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteGrade=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteGrade`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const getDaType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getDaType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addDaType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addDaType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateDaType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateDaType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteDaType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteDaType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getVisitTypeCheck = async(data)=> {
    try{
      const response = await axiosInstance.post(`/picklist/getVisitTypeCheck`,data);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }

    } catch (error) {
      throw error;
    }
  }

  const addVisitTypeCheck = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/addVisitTimeCheck`,data);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }

    } catch (error) {
      throw error;
    }
  }

  const updateVisitTypeCheck = async(data) => {
    try{
      const response = await axiosInstance.post(`/picklist/updateVisitTimeCheck`,data);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }

    } catch (error) {
      throw error;
    }
  }

  const addVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const getManager=async(data)=>{
    try{
      const response = await axiosInstance.post(`/user/getManager`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const fetchProductByCategory=async(data)=>{
    try{
      const response = await axiosInstance.post(`/product/fetchProductByCategory`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getRouteVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getRouteVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  
  const addRouteVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addRouteVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateRouteVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateRouteVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteRouteVisitType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteRouteVisitType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getRecieveCollectionName=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getRecieveCollectionName`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addrecieveCollectionName=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addrecieveCollectionName`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updaterecieveCollectionName=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updaterecieveCollectionName`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleterecieveCollectionName=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleterecieveCollectionName`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getBookType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getBookType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const addBookType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addBookType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateBookType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateBookType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteBookType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteBookType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getCollectiontype=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getCollectiontype`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addCollectionType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addCollectionType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateCollectionType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateCollectionType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteCollectionType=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteCollectionType`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  
  const addStockist = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/addStockist`,data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const sendOtp=async(data)=>{
    try{
      data['token']=JSON.parse(localStorage.getItem('storedUserData')).user.id
      const response = await axiosInstance.post(`/user/sendOtp`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const verifyOtp=async(data)=>{
    try{
      const response = await axiosInstance.post(`/user/verifyOtp`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const totalTimeWorkedReport=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/totalTimeWorkedReport`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const calculateTimeForSchoolAndDistributor=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/calculateTimeForSchoolAndDistributor`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const resendOTP=async(data)=>{
    try{
      const response = await axiosInstance.post(`/user/resendOtpForSession`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const fetchNotifications=async(data)=>{
    try{
      const response = await axiosInstance.post(`/notification/fetchnotification`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const fetchExpense = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/expense/fetchExpenses`, data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const getallparty = async (data) => {
    try {
      //console.log("hellomamn",data)
      const response = await axiosInstance.post(`/party/getallparty`, data);

    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const uploadImage = async (data) => {
    try {
      //console.log("checking upload", data);
      const response = await axiosInstance1.post('/user/uploadImage', data);
  
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Return the data from the response
        return response.data;
      } else {
        // If the request was not successful, handle the error
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const uploadImageApi = async (data) => {
    
    try {
      
      const res = await axiosInstance.post('/user/uploadImage', data);

      return [{
        link: res.data.link,
        status: res.data.status,
        message: res.data.message
      }];
    } catch (error) {
      return [{
        link: "",
        status: false,
        message: error.message
      }];
    }
  };
  
  const fetchAttendanceById=async(data)=>{
    try{
      const response = await axiosInstance.post(`/attendance/fetchAttendanceById`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const getUpdatePartyMobileSchool=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/getUpdatePartyMobileSchool`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addSchoolBulk=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/addSchoolBulk`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addBulkDistributor=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/addBulkDistributor`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addBulkTransporter=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/addBulkTransporter`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addBulkStockist=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/addBulkStockist`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getSchoolById=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/getSchoolById`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  
  const approveRejectMobileSchoolUpdate=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/approveRejectMobileSchoolUpdate`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getUpdatePartyMobileDistributor=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/getUpdatePartyMobileDistributor`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const approveRejectMobileDistributorUpdate=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/approveRejectMobileDistributorUpdate`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const returnOrderItems=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/returnOrderItems`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getOrderTrackingStatus=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/getOrderTrackingStatus`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const getDeliveryStatus=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getDeliveryStatus`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const addOrderItemToTracking=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/addOrderItemToTracking`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateOrderItemToTracking=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/updateOrderItemToTracking`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

    const fetchDataForDashbord=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/fetchDataForDashbord`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  const createExpense=async(data)=>{
    try{
      const response = await axiosInstance.post(`/expense/createExpense`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const addExpensePurpose=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/addExpensePurpose`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const updateExpensePurpose=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/updateExpensePurpose`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const deleteExpensePurpose=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/deleteExpensePurpose`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getSpecimenToUser=async(data)=>{
    try{
      const response = await axiosInstance.post(`/specimen/getSpecimenToUser`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const getSpecimenLineItemsById=async(data)=>{
    try{
      const response = await axiosInstance.post(`/specimen/getSpecimenLineItemsById`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const filterSpecimenToUser=async(data)=>{
    try{
      const response = await axiosInstance.post(`/specimen/filterSpecimenToUser`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const filterOrderTrackingStatus=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/filterOrderTrackingStatus`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const fetchReturnOrders=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/fetchReturnOrders`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const fetchReturnOrdersFilter=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/fetchReturnOrdersFilter`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const editSeriesSet=async(data)=>{
    try{
      const response = await axiosInstance.post(`/product/editSeriesSet`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const editCollection=async(data)=>{
    try{
      const response = await axiosInstance.post(`/collection/editCollection`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const getDistributorByID=async(data)=>{
    try{
      const response = await axiosInstance.post(`/party/getDistributorByID`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }



  const getStates=async(data)=>{
    try{
      const response = await axiosInstance.post(`/picklist/getStates`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  
  const addSpecimenToUser=async(data)=>{
    try{
      const response = await axiosInstance.post(`/specimen/addSpecimenToUser`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const approveRejectSpecimenToUser=async(data)=>{
    try{
      const response = await axiosInstance.post(`/specimen/approveRejectSpecimenToUser`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const uploadTarget=async(data)=>{
    try{
      const response = await axiosInstance.post(`/target/uploadTarget`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const consentOtpSend=async(data)=>{
    try{
      const response = await axiosInstance.post(`/consent/getConsent`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const consentOtpSubmit=async(data)=>{
    try{
      const response = await axiosInstance.post(`/consent/approveConsent`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const consentOtpResend=async(data)=>{
    try{
      const response = await axiosInstance.post(`/consent/resendOtpForConsent`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }
  const fetchRetunOrderLineItem=async(data)=>{
    try{
      const response = await axiosInstance.post(`/order/fetchRetunOrderLineItem`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const orderReport=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/orderReport`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  const visitDetailReport=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/visitDetailReport`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }

  
  const visitSummarizedReport=async(data)=>{
    try{
      const response = await axiosInstance.post(`/dashboard/visitSummarizedReport`,data);
  
    // Check if the request was successful (status code 200)
    if (response.status === 200) {
      // Return the data from the response
      //console.log("TESTAPIRUN", response.data);
  
      return response.data;
    } else {
      // If the request was not successful, handle the error
      throw new Error(`Request failed with status: ${response.status}`);
    }
    } catch (error) {
      //console.error(error);
      throw error;
    }
  }


  let exportedFunctions = {
    getFeedback,
    getNextStep,getWorkDone,resetDeviceId,
      login,
      verifyUserSession,
      fetchEmployees,
      fetchLeadSource,
      fetchLeadRating,
      fetchLeadStatus,
      addLeadSource,
      updateLeadSource,
      deleteLeadSource,
      addLeadRating,
      updateLeadRating,
      deleteLeadRating,
      addLeadStatus,
      updateLeadStatus,
      deleteLeadStatus,
      addVisitOutcomes,
      updateVisitOutcomes,
      deleteVisitOutcomes,
      fetchVisits,
      fetchAttendance,
      exportFetchAttendance,
      fetchOrder,
      fetchOrderLineItem,
      getOrderReport,
      getReturnOrderLineItemByReturnReasonId,
      approverejectOrder,
      getDistributor,
      getSchool,
      getStockist,
      editPartySchool,
      createNewUser,
      fetchPinCode,
      getUserById,
      addDistributor,
      getWorkType,
      startAttendance,
      endAttendance,
      fetchPartyType,
      fetchProduct,
      testApi,
      addVisit,
      getForecastReport,
      getFinancialReport,
      getPipelineReport,
      fetchRoles,
      addRole,
      getBoard,
      getMedium,
      fetchSeries,
      fetchProductById,
      getClass,
      addSeries,
      createOrder,
      editPartySchool,
      updateRoleAccess,
      getRole,
      editDistributor,
      editDistributor,
      updateVisit,
      addProduct,
      updateProduct,
      fetchProduct1,
      getMittplusProductGroups,
      addMittplusProductGroups,
      updateMittplusProductGroups,
      deleteMittplusProductGroups,
      getProductGroups,
      addProductGroups,
      updateProductGroups,
      deleteProductGroups,
      updateProductGroups,
      deleteProductGroups,
      fetchProductMittplus,
      addMittplusProduct,
      getReturnReasons,
      addReturnReasons,
      updateReturnReasons,
      deleteReturnReasons,
      addSchoolCategory,
      updateSchoolCategory,
      deleteSchoolCategory,
      addDocumentListSchool,
      updateDocumentListSchool,
      deleteDocumentListSchool,
      addDeliveryStatus,
      updateDeliveryStatus,
      deleteDeliveryStatus,
      updateMittplusProduct,
      deleteMittplusProduct,
      deleteTransporter,
      deleteProduct,
      getProducts,
      addProductBulk,
      getSubject,
      addSchool,
      getRoutePlan,
      getRouteplanById,
      approveRoute,
      getPartyLead,
      addRoutePlan,
      updateOrderDetails,
      approveRejectParty,
      fetchReportingManagerRole,
      getUsersOnRole,
      editUser,
      signout,
      getCollection,
      addCollection,
      approveRejectCollection,
      getGivenType,
      getPaymentType,
      getSpecimen,
      addSpecimen,
      updateSpecimen,
      deleteSpecimen,
      deleteUser,
      activateUser,
      fetchDashboardUsers,
      fetchDashboardSchool,
      fetchDashboardSchoolErpOnboard,
      fetchDashboardDistributor,
      fetchDashboardTotalRevenueOrder,
      fetchDashboardProductRank,
      fetchDashboardVisitVsOrderUser,
      fetchDashboardDistributorOnboardingList,
      fetchDashboardSchoolOnboardingList,
      fetchDashboardEmployeeWithRevenue,
      fetchDashboardTotalEarningMonthWise,
      fetchDashboardTotalEarningSchoolMonthWise,
      fetchDashboardOrderVsSpecimen,
      fetchDashboardTotalEarningDistributorMonthWise,
      filterSchool,
      filterDistributor,
      filterVisit,
      filterAttendance,
      filterOrder,
      deleteSchool,
      deleteStockist,
      deleteDistributor,
      filterOrderDistributor,
      filterRoutePlan,
      filterCollection,
      getSeriesCategory,
      addSeriesCategory,
      updateSeriesCategory,
      deleteSeriesCategory,
      getStatusType,
      getVehicleType,
      getLeadParty,
      getPartyLeadAdmin,
      getTransporters,
      getSchoolCategories,
      getVisitOutcomes,
      rejectVisit,
      addLeadBulk,
      addLeadAdmin,
      addTransporter,
      updateTransporter,
      deleteLead,
      fetchAttendanceForReport,
      resetDeviceId,
      getUsersFieldRole,
      getSchoolForDropDown,
      getSchoolForDropDown,
      getContactPersonRole,
      getGrade,
      getDaType,
      getVisitType,
      getVisitTypeCheck,
      addVisitTypeCheck,
      updateVisitTypeCheck,
      getManager,
      fetchProductByCategory,
      getRouteVisitType,
      getRecieveCollectionName,
      getCollectiontype,
      sendOtp,
      verifyOtp,
      totalTimeWorkedReport,
      calculateTimeForSchoolAndDistributor,
      resendOTP,
      calculateTimeForSchoolAndDistributor
      ,fetchLogs,
      fetchNotifications,
      addWorkType,
      updateWorkType,
      deleteWorkType,
      addPartyType,
      updatePartyType,
      deletePartyType,
      addSubject,
      updateSubject,
      deleteSubject,
      addClass,
      updateClass,
      deleteClass,
      addBoard,
      updateBoard,
      deleteBoard,
      addMedium,
      updateMedium,
      deleteMedium,
      addGivenType,
      updateGivenType,
      deleteGivenType,
      addPaymentType,
      updatePaymentType,
      deletePaymentType,
      addVehicleType,
      updateVehicleType,
      deleteVehicleType,
      addStatusType,
      updateStatusType,
      deleteStatusType,
      addWorkDone,
      updateWorkDone,
      deleteWorkDone,
      addNextStep,
      updateNextStep,
      deleteNextStep,
      addFeedback,
      updateFeedback,
      deleteFeedback,
      addGrade,
      updateGrade,
      deleteGrade,
      addContactPersonRole,
      updateContactPersonRole,
      deleteContactPersonRole,
      addDaType,
      updateDaType,
      deleteDaType,
      addVisitType,
      updateVisitType,
      deleteVisitType,
      addCollectionType,
      updateCollectionType,
      deleteCollectionType,
      addrecieveCollectionName,
      updaterecieveCollectionName,
      deleterecieveCollectionName,
      addExpensePurpose,
      updateExpensePurpose,
      deleteExpensePurpose,
      addBookType,getBookType,
      addRouteVisitType,
      updateRouteVisitType,
      deleteRouteVisitType,
      fetchAttendanceById,
      getUpdatePartyMobileSchool,
      addSchoolBulk,
      addBulkDistributor,
      addBulkStockist,
      getStockistForDropDown,
      addStockist,
      addIncentiveNew,
      getSchoolById,
      approveRejectMobileSchoolUpdate,
      getUpdatePartyMobileDistributor,
      approveRejectMobileDistributorUpdate,
      fetchExpense,
      getExpenseType,
      addExpenseType,
      updateExpenseType,
      deleteExpenseType,
      getExpensePurpose,
      getallparty,
      uploadImage,
      uploadImageApi,
      createExpense,
      returnOrderItems,
      getOrderTrackingStatus,
      getDeliveryStatus,
      addOrderItemToTracking,
      updateOrderItemToTracking,
      getSpecimenToUser,
      getSpecimenLineItemsById,
      filterSpecimenToUser,
      fetchDataForDashbord,
      filterOrderTrackingStatus,
      fetchReturnOrders,
      fetchReturnOrdersFilter,
      getDistributorByID,
      addSpecimenToUser,
      approveRejectSpecimenToUser,
      uploadTarget,
      consentOtpResend,
      consentOtpSend,
      fetchRetunOrderLineItem,
      orderReport,
      visitDetailReport,
      visitSummarizedReport,
      consentOtpSubmit,
      // consentOtpSubmitorderReport,
      getincentivelist,
      addDistributorToStockist,
      editSeriesSet,
      editCollection,
      deleteCollection,
      editStockist,
      getStates,
      deleteIncentive,
      getLanguage,
      addBulkTransporter,
      editIncentive,
      getAuthor,
      getSchoolCategory,
      updateSchoolCategory,
      deleteSeriesSet,
      deleteSchoolCategory,
      getDocumentListSchool,
      updateDocumentListSchool,
      deleteDocumentListSchool,
      getUsersForWork,
  }

  export default exportedFunctions;