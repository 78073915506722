import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const ReturnOrderModal = ({ onCancel ,data }) => {
   
    const [form,setForm]=useState({name:"",qty:"",itemId:"",returnQty:""})
    const [dataGrid,setDatagrid]=useState([])
    const [disabledRows, setDisabledRows] = useState([]);
    const [loading,setLoading] = useState(false)
    const [reasons, setReasons] = useState([]);
    const [returnReason, setReturnReason] = useState();
console.log("DATAAUTH",data)
useEffect(() => {
    const disabledRowIds = dataGrid.map((item) => item.orderLineItemId);
    setDisabledRows(disabledRowIds);
  }, [dataGrid]);

  useEffect(() => {
    getReturnReasons();
  }, [])

  const getReturnReasons = async () => {
    let results = await window.Platform.database.getReturnReasons();
    setReasons(results.data);
  }

const changeHandler = (fieldName, value) => {

  if(fieldName === "returnReason") {
    setReturnReason(value)
    return;
  }
    
      const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
      if(sanitizedValue>form.qty){

      setForm((prevForm) => ({ ...prevForm, [fieldName]: form.qty }))
      }
      else{
      setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))

      }
    };



const getColumns = () => {
  let result = [
    {
      field: "itemId",
      headerName: "Item Name",
      sortable: false,
      width: 200,
      
      editable: false,
      renderCell: (params) => {

        let name = params.row?.nameSku || params.row?.product_name || "N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "QTY",
      headerName: "Quantity",
      width: 170,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.QTY||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "Price",
        headerName: "Unit Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.Price||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Total",
        headerName: "Total Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Total||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      ,
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row
          const isDisabled = disabledRows.includes(params.row.orderLineItemId);
  
          return (
  
            <Tooltip title={"Click To Return"}>
  
              <IconButton onClick={()=>editData(name)} disabled={isDisabled}>
                <EditIcon/>
              </IconButton>
  
            </Tooltip>
  
          )
  
        },
      },
     

 
  ];


  return result
}

console.log("DISABLEDROWS",disabledRows)

const getColumns1 = () => {
    let result = [
      {
        field: "itemId",
        headerName: "Item Name",
        sortable: false,
        width: 200,
        
        editable: false,
        renderCell: (params) => {
  
          let name = params.row?.name||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "QTY",
        headerName: "Quantity",
        width: 170,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.qty||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.id
  
          return (
  
            <Tooltip title={"Click To Return"}>
  
              <IconButton onClick={()=>handleDeleteEmploye(name)}>
                <DeleteIcon/>
              </IconButton>
  
            </Tooltip>
  
          )
  
        },
      },]
      return result
    }


const editData=(data)=>{
    console.log("DATTAEAT",data)
setForm({name:data.nameSku || data.product_name,itemId:data.itemId,qty:data.QTY,orderLineItemId:data.orderLineItemId})


}

const handleDeleteEmploye = async (id) => {
   
    let res = dataGrid.filter((el) => {
        return el.id != id;
      });
     
    
      setDatagrid(res);
      };

const renderContent = () => {
  return (
    <>
<Stack direction={"row"} spacing={1} sx={{width:"100%"}}>
<Stack direction={"column"} spacing={1} sx={{width:"49%"}}>
<strong>Order Items</strong>
      <DataGrid
        className="payrollGrid"
      //   checkboxSelection={true}
      sx={{height:isMobile ? "555px":"322px"}}
      density="compact"
        rows={data.product||[]}
        columns={getColumns()}
        rowHeight={60}
        disableSelectionOnClick
        disableRowSelectionOnClick
        autoPageSize
        pageSizeOptions={[10]}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
      </Stack>
      <Stack direction={"column"} spacing={1} sx={{width:"49%"}}>
<strong>Return Items</strong>
<div style={{height:"322px"}}>
      <DataGrid
        className="payrollGrid"
      //   checkboxSelection={true}
      sx={{height:isMobile ? "555px":"322px"}}
      density="compact"
        rows={dataGrid||[]}
        columns={getColumns1()}
        rowHeight={60}
        disableSelectionOnClick
        disableRowSelectionOnClick
        autoPageSize
        pageSizeOptions={[10]}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
      </div>
      </Stack>
      </Stack>
      <Stack padding={5} spacing={3}  direction={"row"} >

      <TextField label="Return Product" value={form?.name||""} disabled />
      <TextField label="Orignal QTY" value={form?.qty||""} disabled />
      <TextField label="Return QTY" value={form?.returnQty||""} disabled={!form.name} onChange={(e)=>changeHandler("returnQty",e.target.value)} />
      <FormControl variant="outlined" style={{ width: "100%" }}>
                    {/* <InputLabel>Product Group</InputLabel> */}
                    <Select
                      labelId={`product-group`}
                      label="Product Group"
                      value={returnReason || ""}
                      onChange={(e) => changeHandler('returnReason', e.target.value)}
                    >
                      {reasons?.map((option, optionIndex) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
      <Button variant="text" onClick={()=>onAdd(form)} disabled={!form?.returnQty}>Add</Button>
      <Button variant="text" onClick={()=>onClear()}>Clear</Button>

      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button variant="contained" onClick={()=>onSubmit()} disabled={!dataGrid?.length}>
        Submit Return
      </Button>
      </div>
      
      </>
  );
};
    
  
const onSubmit=async()=>{
    // console.log("onSubmit")
    try{
        setLoading(true);
        const finalData={orderId:data.order.orderId, partyId:data.order.partyId,item:dataGrid, returnReason: returnReason}
console.log("onSubmit",finalData)
let results = await window.Platform.database.returnOrderItems(finalData);

      if (results.status) {
        window.NotificationUtils.showError(results.message);
      } else {
        window.NotificationUtils.showSuccess("Returned Order Placed Sucessfully.");
      }
setLoading(false);
onCancel()

    }
    catch(error){
setLoading(false);

console.log("onSubmit",error)
    }
}
  
const onAdd=(data)=>{
    console.log(data)
    // let pushedItem={name:data.name,itemId:data.itemId,qty:data.returnQty,id:dataGrid.length+1,orderLineItemId:data.orderLineItemId}
    let pushedItem={name: data.name, itemId:data.itemId,qty:data.returnQty,id:dataGrid.length+1}
    setDatagrid([...dataGrid,pushedItem])
    onClear()

}
const onClear=(data)=>{
    console.log(data)
    setForm({name:null,qty:null,returnQty:null,itemId:null})
}
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&650,padding:"10px",borderRadius:"10px"
      },
    }}>
        {loading&&<OpaqueLoading/>}
    <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">View Line Items {/*- <Tooltip title="Order Id">{"("+ (data).order.orderId+")"}</Tooltip>*/}</Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default ReturnOrderModal;
  