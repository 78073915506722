import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SeriesProduct from "../../components/modals/seriesProduct";
import AddSeriesModal from "../../components/modals/addSeries";
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  // );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: calc(100%);
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function SeriesManagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [addModal, setAddModal] = useState(false)
  const [dataSet, setDataSet] = useState([])
  const [nayaModal, setNayaModal] = useState(false)


  const formatDateTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    // Check if the input date is valid
    if (isNaN(inputDate.getTime())) {
      return 'Invalid Date';
    }

    // Extract date and time components
    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getUTCDate()).padStart(2, '0');
    const hours = String(inputDate.getUTCHours()).padStart(2, '0');
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

    // Format the date string without 'T' and 'Z'
    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateString;

  }




  useEffect(() => {
    // setAccessChanges(rows);
    fetchOrder()
  }, []);
  useEffect(() => {
    console.log("checking...", accessChanges);
  }, [accessChanges]);

  const fetchOrder = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchSeries();
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
      // window.NotificationUtils.showError(results.message)
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally {
      setLoading(false)
    }
  }

  const handleEdit = async (newData) => {
    setDataSet(newData)
    setNayaModal(true)
  }

  // Delete Handler
  const handleDelete = async (seriesId, event) => {
    event.stopPropagation();  // Prevent row selection on click

    const confirmDelete = window.confirm("Are you sure you want to delete this set?");
    if (!confirmDelete) return;

    try {
      setLoading(true);
      // Call the DELETE API for the seriesId
      const response = await window.Platform.database.deleteSeriesSet({ seriesId });


      window.NotificationUtils.showSuccess("Set deleted successfully");
      // Remove the deleted row from the state (accessChanges)
      fetchOrder()
      setAccessChanges((prev) =>
        prev.filter((row) => row.seriesId !== seriesId)
      );

    } catch (error) {
      console.error("Error deleting the set:", error);
      window.NotificationUtils.showError("Error deleting the set");
    } finally {
      setLoading(false);
    }
  };


  const getEmployeeColumns = () => {
    let result = [
      {
        field: "Series Name",
        headerName: "Set Name",
        sortable: false,
        width: 350,

        editable: false,
        valueGetter: (params) => {
          return params.row?.nameSeries;
        },
        renderCell: (params) => {

          let name = params.row?.nameSeries

          return (

            <Tooltip title={name || "N/A"}>

              <Typography variant="inherit">{name}</Typography>

            </Tooltip>

          )

        },
      },
      // {
      //   field: "seriesId",
      //   headerName: "Set Name",
      //   width: 200,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {

      //     let name =params.row?.nameSeries

      //     return (

      //       <Tooltip title={name || "N/A"}>

      //         <Typography variant="inherit">{name}</Typography>

      //       </Tooltip>

      //     )

      //   },
      // },
      {
        field: "Price",
        headerName: "Price",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return params.row?.unitPrice;
        },
        renderCell: (params) => {

          let name = +params.row.unitPrice
          let name1 = name?.toFixed(2)

          return (

            <Tooltip title={name1 || "N/A"}>

              <Typography variant="inherit">{name1}</Typography>

            </Tooltip>

          )

        },
      },

      {
        field: "View Products",
        headerName: "View Products",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {

          let value = params.row?.seriesId

          return (

            <Tooltip title="Click to view product list"><IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              fetchOrderLineItem(value);
            }} >
              <RemoveRedEyeIcon />
            </IconButton></Tooltip>

          )

        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit"}>
                <IconButton
                  onClick={() => handleEdit(params.row)}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="black"
                  onClick={(e) => handleDelete(params.row.seriesId, e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },

    ];


    return result
  }

  const approveRejectOrder = async (val) => {
    try {
      setLoading(true)
      const sentData = { id: val.id, approvalStatus: val.value }
      const results = await window.Platform.database.approverejectOrder(sentData)
      window.NotificationUtils.showSuccess("Order Update Success")
      setLoading(false)

    }
    catch (err) {
      console.log(err)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)

    }
  }


  const fetchOrderLineItem = async (val) => {
    try {
      setLoading(true)
      setModalData()
      let sentData = { id: val }
      let results = await window.Platform.database.fetchProductById(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setModalData({ data: jsonArrayWithId, id: val })
      setModal(true)
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)



    }



  }








  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-set",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
          //   checkboxSelection={true}
          density="compact"
          rows={accessChanges}
          columns={getEmployeeColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          // autoPageSize

          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };





  return (
    <>

      <StaffEditorPageContainer>
        {loading && <OpaqueLoading />}
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          <div>
            {/* <FabStyle> */}
          </div>
          {/* <FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add Series
</FabStyle>  */}

          <Button variant="contained" color="primary"
            onClick={() => setAddModal(true)} sx={{ mr: 1, borderRadius: '10px' }}>
            <Add sx={{ mr: 1 }} />
            Add Set
          </Button>

          {/*  */}
          {/* </FabStyle> */}
          {/* )} */}
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
        modal &&
        <SeriesProduct
          onCancel={() => setModal(null)}
          loading={loading}
          data={modalData}
        //   onSubmit={updateWarehouseData}
        />
      }
      {addModal && <AddSeriesModal
        onClose={() => setAddModal(false)}
        fetchData={() => fetchOrder()}

      //   onSubmit={updateWarehouseData}
      />}

      {nayaModal &&
        <AddSeriesModal
          onClose={() => setNayaModal(false)}
          fetchData={() => fetchOrder()}
          dataNew={dataSet}
        />
      }
    </>
  );
}
