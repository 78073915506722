import react, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack, Tab, Tabs, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AddOrderModal from "../../components/modals/addOrder";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddOrderModalDistributor from "../../components/modals/addOrderDistributor";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import ExpenseAddModal from "../../components/modals/ExpenseAddModal";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
height: calc(
  100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 18px - 43.77px"}
);
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 480px;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;

export default function Incentives() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal,setModal]=useState(false)
  const [modalData,setModalData]=useState()
  const [addModal,setAddModal] = useState(false)
  const [addModal1,setAddModal1] = useState(false)

  const { auth, setAuth } = useContext(AuthContext)

  const [editModal,setEditModal] = useState(false)
  const [editModal1,setEditModal1] = useState(false)

  const [editModalData,setEditModalData] = useState(false)
  const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 10,
});

const [currentPage1, setCurrentPage1] = useState(1);
const [paginationModel1, setPaginationModel1] = useState({
  page: currentPage1 - 1,
  pageSize: 10,
});

const [selectedTab, setSelectedTab] = useState(0);


// console.log(,"aaaaaá")
const handleTabChange = async (value) => {

  

  setSelectedTab(value);


}

  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    if(selectedTab===0){
    fetchOrder()
    }
  }, [paginationModel.page,selectedTab]);
  useEffect(() => {
    if(selectedTab===1){
    fetchOrder1()
    }
  }, [paginationModel1.page,selectedTab]);
  useEffect(() => {
    console.log("checking...", accessChanges);
  }, [accessChanges]);

  const fetchOrder= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.fetchExpense({"expenseType":null,
      // "fromDate":"2024-01-05","toDate":"2024-02-05",
      "employeeId":"8ngwLeRHeK"});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
setRowCount(results.data1)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const fetchOrder1= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.fetchOrder({pageNumber:paginationModel.page,orderStatus:1});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
setRowCount(results.data1)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }


  const getEmployeeColumns = () => {
  let result = [
    {
      field: "expenseId",
      headerName: "Expense ID",
      sortable: false,
      width: 150,
      
      editable: false,
      renderCell: (params) => {

        let name = params.row?.expenseId||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =formatTimeStamp( params.row.createdAt)||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =formatTimeStamp( params.row.updatedAt)||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "expensePurpose",
        headerName: "Expense Purpose",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.expensePurpose||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "TYPE",
        headerName: "Expense Type",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.TYPE||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Amount",
        headerName: "Amount",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.Amount||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
     
      {
        field: "partyId",
        headerName: "Party ID",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.partyId||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Remarks",
        headerName: "Remarks",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Remarks||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "billLink",
        headerName: "Bill Link",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.billLink||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Date",
        headerName: "Expense Date",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Date||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "approvedBy",
        headerName: "Approved By",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.approvedBy ||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
     

 
  ];


  return result
}

const editData=(data)=>{
console.log("EDITDATA",data)

setEditModalData(data)
if(selectedTab===0){
setEditModal(true)
}
else{
  setEditModal1(true)
}
}


const approveRejectOrder=async(val)=>{
    console.log("VALuesss",val)
try{
    setLoading(true)
  const results=await window.Platform.database.approverejectOrder(val)
    window.NotificationUtils.showSuccess("Order Update Success")
    setLoading(false)
    fetchOrder()

}
catch(err){
console.log(err)
window.NotificationUtils.showError("Something Went Wrong!")
setLoading(false)

}
}

const formatTimeStamp = (timestampFromDB) => {
  const utcDate = new Date(timestampFromDB);
  const indianDate = utcDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  return indianDate;
};

const fetchOrderLineItem=async(val)=>{
try{
    setLoading(true)
    setModalData()
let sentData={id:val}
    let results = await window.Platform.database.fetchOrderLineItem(sentData);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

setModalData({data:jsonArrayWithId,id:val})
setModal(true)
    setLoading(false)

}
catch(error){
    console.log(error)
    window.NotificationUtils.showError("Something Went Wrong!")
    setLoading(false)



}



}







  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "fos-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

 

  const renderContent = () => {
  //   if (loading) {

  //     return renderLoading();

  // }
    if(selectedTab===0){
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          pageSizeOptions={[10]}
          onFilterModelChange={(val)=>
              {
          //       console.log("vvvv",val);
            
            
            
          //   if(val?.items[0]?.value?.length>0){
          //     onFilterChange1(val.items[0])
          //     setSearchTerm(val.items[0])
            
            
  
          //   }
          //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     fetchData()
          //     console.log("CHECK1")
          //   }
          //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          //     setSearchTerm(null)
  
          //     onFilterChange1()
          //     console.log("checkterr")
          //   }
          //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
  
  
          //   }
          //   else{
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
          //   }
          }
            
          }
          filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
        }
        else if(selectedTab===1){
          return (
            <DataGridContainer>
              <DataGrid
                className="payrollGrid"
              //   checkboxSelection={true}
              density="compact"
                rows={accessChanges}
                columns={getEmployeeColumns()}
                disableSelectionOnClick
                disableRowSelectionOnClick
                rowHeight={60}
                pageSizeOptions={[10]}
                onFilterModelChange={(val)=>
                    {
                //       console.log("vvvv",val);
                  
                  
                  
                //   if(val?.items[0]?.value?.length>0){
                //     onFilterChange1(val.items[0])
                //     setSearchTerm(val.items[0])
                  
                  
        
                //   }
                //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
                //     setSearchTerm(null)
                //     fetchData()
                //     console.log("CHECK1")
                //   }
                //   else if(!val.items?.value && endDate && startDate && territoryFilter){
                //     setSearchTerm(null)
        
                //     onFilterChange1()
                //     console.log("checkterr")
                //   }
                //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
                //     setSearchTerm(null)
                //     console.log("check2")
                //     onFilterChange1()
        
        
                //   }
                //   else{
                //     setSearchTerm(null)
                //     console.log("check2")
                //     onFilterChange1()
                //   }
                }
                  
                }
                filterMode="server"
      
      rowCount={rowCount}
                
                paginationModel={paginationModel1}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel1}
                
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </DataGridContainer>
          );

        }
  };





  return (
    <>
      
      <StaffEditorPageContainer>
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          {loading && <OpaqueLoading/>}
          <div>
            {/* <FabStyle> */}
            </div>
          { selectedTab===0? <FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add New
</FabStyle>:
<FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal1(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add New1
</FabStyle> }

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
        </HeaderContainer>
        <TableContainer>
        {/* <TabsContainer>

<StyledTabs

    variant="fullWidth"

    value={selectedTab}

    onChange={(event, value) => handleTabChange(value)}

    indicatorColor="primary"

    textColor="primary"

>

    <Tab label="School Orders" />


    <Tab label="Distributor Orders" />

</StyledTabs>


</TabsContainer> */}
{renderContent()}
</TableContainer>
      </StaffEditorPageContainer>
      {
      modal &&
      <OrderLineItemModal
      onCancel={() => setModal(null)}
      loading={loading}
      data={modalData}
    //   onSubmit={updateWarehouseData}
      />
    }
    {
      addModal && <ExpenseAddModal onClose={()=>setAddModal(false)} items={''} />
    }
    {
      editModal && <ExpenseAddModal onClose={()=>setEditModal(false)} items={''}  />
    }

    {/* {
      addModal1 && <AddOrderModalDistributor onClose={()=>setAddModal1(false)} fetchData={()=>fetchOrder1()} status={true}/>
    }

{
      editModal1 && <AddOrderModalDistributor onClose={()=>setEditModal1(false)} fetchData={()=>fetchOrder1()} status={false} dataProp={editModalData}  />
    } */}
    </>
  );
}
